import React, {useContext, useState} from "react";
import {useQuery} from "react-query";
import {EventService} from "../../../../services/event.service";
import {Constants} from "../../../../constans/constants";
import Cookies from "universal-cookie";
import {AxiosError} from "axios";
import ErrorMessage from "../../../ui/error/ErrorMessage";
import EventItem from "./EventItem/EventItem";
import {IEventStudent} from "../../../../models/events";
import ScrollContainer from "../../../ui/scroll-container/ScrollContainer";
import {AuthContext} from "../../../../context/AuthContext";

const Events = () => {

    const cookies = new Cookies

    const [page, setPage] = useState<number>(1)
    const [showLoadMore, setShowLoadMore] = useState<boolean>(true)
    const [eventList, setEventList] = useState<IEventStudent[]>([])
    const {authenticated, setAuthenticated, currentRoleId, currentUserId} = useContext(AuthContext)

    const events = useQuery(
        ["events_student", currentUserId, page],
        () => EventService.getListEvents(Number(currentUserId), page),
        {
            enabled: !!cookies.get("user") && Constants.LIST_ADMIN_ROLE.indexOf(Number(currentRoleId)) !== -1,
            onSuccess: (response) => {
                // console.log(response)
                if(page == 1) {
                    setEventList(response.events)
                } else {
                    response.events.map(event => {
                        setEventList(prev => [...prev, event])
                    })
                }
                if(response.left <= 0) {
                    setShowLoadMore(false)
                }
            }
        }
    )

    if(events.isLoading) return <div className="flex items-center justify-center w-full">Загрузка...</div>

    if (events.isError) {
        const e = events.error as AxiosError
        return <ErrorMessage numberError={e.response ? e.response.status : 400}/>
    }

    if (!events.data) {
        return <ErrorMessage numberError={400}/>
    }

    return (
        <div className="h-full">
            <div className="flex flex-col gap-[34px] h-full">
                <div
                     className="bg-white p-[32px] rounded-[6px] flex flex-col gap-[18px] text-[16px] font-medium max-lg:px-[14px] max-lg:py-[2px] max-lg:gap-0 overflow-hidden h-full">
                    <div
                        className="px-[18px] flex justify-between text-[#828282] text-[16px] font-medium whitespace-nowrap max-lg:hidden">
                        <div className="w-[80%] cursor-pointer flex gap-[4px]">
                            Событие
                        </div>
                        <div className="w-[20%]">Дата</div>
                    </div>
                    {/*<ScrollContainer>*/}
                        <div
                            className="h-full">
                            {eventList.map((event, index) => (
                                <EventItem item={event} key={index}/>
                            ))}
                        </div>
                    {/*</ScrollContainer>*/}
                </div>
                {showLoadMore && (
                    <div
                        onClick={() => setPage(prev => prev + 1)}
                        className="flex text-center w-full px-[16px] py-[8px] border-[1px] border-solid border-[#17AB57] justify-center cursor-pointer rounded-[5px] text-[16px] font-medium text-[#292B2C] leading-[120%]">
                        Показать ещё
                    </div>
                )}
            </div>
        </div>
    )
}

export default Events