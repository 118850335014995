import Log from "../../../assets/images/global/login.png"

interface IDocument {
    pathname: string
    name: string
}

const documents: IDocument[] = [
    {
        pathname: "/6_1_Инструкция_по_установке_и_эксплуатации_СДО.pdf",
        name: "Инструкция по установке и эксплуатации СДО"
    },
    {
        pathname: "/6_Функциональные_характеристики_и_архитектура_СДО.pdf",
        name: "Функциональные характеристики и архитектура СДО"
    }
]

const Document =() => {

    return (
        <div
            className="w-full h-full bg-no-repeat bg-cover"
            style={{backgroundImage: `url(${Log})`}}>
            <div className="w-full h-full bg-white/80 p-[36px] max-lg:p-[24px] text-black">
                <div className="flex flex-col gap-[12px] leading-[120%]">
                    <div className="">
                        СДО "АПОК СТАДИ" представляет собой систему дистанционного образования, которая позволяет:
                        <div className="flex flex-col gap-[5px] ml-[15px] mt-[5px]">
                            <div className="">• просматривать все приобретенные курсы;</div>
                            <div className="">• изучать учебные материалы в различных форматах (видео, текст, картинки,
                                схемы, таблицы и т.д.);
                            </div>
                            <div className="">• проходить тестирования;</div>
                            <div className="">• отслеживать прогресс по обучению;</div>
                            <div className="">• обучать сотрудников компании при участии представителя компании –
                                куратора.
                            </div>
                        </div>
                    </div>
                    <div className="">
                        Обучающийся имеет доступ к обязательному для освоения контенту, содержащемуся в курсах (учебные
                        материалы в текстовом и видео формате, тестовые задания и т.п), а также к иным функциональным
                        возможностям СДО.
                        Обучающийся может видеть все приобретенные им курсы, свободно перемещаться между учебными
                        планами
                        доступных к освоению курсов, переходить в открытые для прохождения курсы. Внутри курсов
                        обучающийся
                        имеет возможность просматривать учебные материалы, выполнять тестирования, получать обратную
                        связь
                        внутри СДО от куратора.
                    </div>
                    <div className="">
                        Обучающийся в любой момент может ознакомиться с прогрессом в освоении образовательных программ.
                        Также по ходу обучения обучающемуся будет предоставлена возможность оставить обратную связь по
                        образовательному процессу в целом, пройденному курсу и т.д.
                    </div>
                </div>
                <div className="mt-[20px]">
                    <div className="font-bold text-[25px] leading-[120%] mb-[10px]">Документы</div>
                    <div className="flex flex-col gap-[5px]">
                        {documents.map((document, index) => (
                            <div key={index}>
                                {index + 1}. <a href={document.pathname} target="_blank">{document.name}</a>
                            </div>
                        ))}
                    </div>
                </div>
                <div className="mt-[20px] flex-col flex gap-[10px]">
                    <div className="font-bold text-[25px] leading-[120%]">Информация о стоимости программного
                        обеспечения
                    </div>
                    <div className="">Стоимость программного обеспечения рассчитывается индивидуально в зависимости
                        от задач каждого конкретного пользователя. Для получения информация о стоимости необходимо
                        связаться
                        по следующему телефону: <a className="text-[#2566e8] hover:underline"
                                                   href="tel:88003500545">8(800)350-05-45</a> или по адресу электронной
                        почты <a className="text-[#2566e8] hover:underline"
                                 href="mailto:zayavki@apokdpo.ru">zayavki@apokdpo.ru</a>.
                    </div>
                    <div className="">
                        СДО "АПОК СТАДИ" распространяется в виде интернет-сервиса, специальные действия по установке СДО
                        на
                        стороне пользователя не требуются.
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Document