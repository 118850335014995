import {useParams} from "react-router-dom";
import React, {useContext, useEffect, useState} from "react";
import {IQuestion, IQuestionDetail, IQuestionOption, ITest} from "../../../../../../../models/tests";
import TestsQuestionsItem from "./tests-questions-item/TestsQuestionsItem";
import {useMutation, useQueryClient} from "react-query";
import {TestsService} from "../../../../../../../services/tests.service";
import Loader from "../../../../../../ui/loader/Loader";
import {LoaderContext} from "../../../../../../../context/LoaderContext";
import ScrollContainer from "../../../../../../ui/scroll-container/ScrollContainer";

interface ITestsQuestions {
    test: ITest
}

const TestsQuestions = (
    {
        test
    }: ITestsQuestions
) => {

    const queryClient = useQueryClient()
    const {id} = useParams()
    const [preview, setPreview] = useState<string>('')
    const [questions, setQuestions] = useState<IQuestionDetail[]>([])
    const [questionsTest, setQuestionsTest] = useState<IQuestion[]>([])
    const [error, setError] = useState<string>("")
    const [save, setSave] = useState<boolean>(false)
    const {show, setShow} = useContext(LoaderContext)

    useEffect(() => {
        const timer = setTimeout(() => {
            setSave(false)
        }, 10000)
        return () => clearTimeout(timer)
    }, [save])

    useEffect(() => {
        if (test.testQuestions && test.testQuestions.length > 0) {
            setQuestionsTest(test.testQuestions)
        }
    }, [test])

    useEffect(() => {
        if (questionsTest.length > 0) {
            let previewQuestionsTest: string = ""
            questionsTest.forEach(element => {
                previewQuestionsTest += "~~" + element.name + "\n"
                element.options.forEach(option => {
                    previewQuestionsTest += (option.isCorrectAnswer ? "++" : "--") + option.name + "\n"
                })
                previewQuestionsTest += "\n\n"
            })
            setPreview(previewQuestionsTest)
        }
    }, [questionsTest])

    const onPreview = () => {
        const arQuestions = preview.split("~~")
        arQuestions.shift()
        let questions: IQuestionDetail[] = []
        arQuestions.forEach((element) => {
            let arOptions = element.split("\n")
            arOptions = arOptions.filter(el => el != "")
            let questionOptions: IQuestionOption[] = []
            arOptions.forEach((option) => {
                option = option.trim()
                if (option.startsWith("++") || option.startsWith("--")) {
                    questionOptions.push({
                        name: option.slice(2),
                        isCorrectAnswer: option.slice(0, 2) === "++"
                    })
                }
            })
            questions.push({name: arOptions[0], options: questionOptions})
        })
        setQuestions(questions);
    }

    const onChange = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
        setPreview(e.target.value)
    }

    const updateQuestions = useMutation(
        (data: IQuestionDetail[]) => TestsService.createQuestions(Number(id), data),
        {
            onSuccess: () => {
                queryClient.invalidateQueries(["test", Number(id)])
                setQuestionsTest([])
                setShow(false)
                setSave(true)
            }
        }
    )

    useEffect(() => {
        if(questions.length > 0) {
            setError("")
        }
    }, [questions])

    const onSubmit = async () => {
        if (questions.length > 0) {
            setShow(true)
            updateQuestions.mutate(questions)
        } else {
            setError("Сначала надо сделать предосмотр")
        }
    }

    return (
        <div className="flex flex-col gap-[18px] text-[#292B2C] h-full">
            {show && <Loader/>}
            <div className="text-[24px] leading-[120%] font-medium">Вопросы</div>
            {error && (<>{error}</>)}
            <div className="flex gap-[18px] h-full min-h-0 max-lg:flex-col">
                <div className="flex flex-col gap-[18px] w-[50%] max-lg:w-full max-lg:h-[50%]">
                    <div className="">Исходный текст</div>
                    <textarea
                        value={preview}
                        onChange={onChange}
                        placeholder="Введите вопросы"
                        className="h-[390px] border-solid border-[1px] border-[#BDBDBD] rounded-[5px] focus:outline-none py-[8px] pl-[10px] pr-[12px]"></textarea>
                    <div className="flex justify-end">
                        <button
                            className="py-[8px] px-[16px] bg-[#17AB57] rounded-[5px] text-white text-[16px] font-medium"
                            onClick={onPreview}>Предосмотр
                        </button>
                    </div>
                </div>
                <div className="flex flex-col gap-[18px] w-[50%] max-lg:w-full max-lg:h-[50%]">
                    <div className="">Вопросы</div>
                    <div
                        className="border-solid border-[1px] border-[#BDBDBD] rounded-[5px] py-[8px] pl-[10px] pr-[12px] h-[390px]">
                        <ScrollContainer chat={false}>
                            {questions && questions.map((question, index) =>
                                <TestsQuestionsItem question={question} key={index}/>
                            )}
                        </ScrollContainer>
                    </div>
                    <div className="flex justify-end gap-[18px]">
                        <div className={`py-[8px] px-[16px] bg-green-500 rounded-[5px] text-white text-[16px] font-medium` + (save ? " block" : " hidden")}>Сохранено</div>
                        <button onClick={onSubmit}
                                className="py-[8px] px-[16px] bg-[#17AB57] rounded-[5px] text-white text-[16px] font-medium">Сохранить
                        </button>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default TestsQuestions

