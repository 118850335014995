import '@react-pdf-viewer/core/lib/styles/index.css';
import '@react-pdf-viewer/default-layout/lib/styles/index.css';
import {IFile} from "../../../models/file";
import React, {useEffect, useState} from "react";
import DocViewer from "@cyntler/react-doc-viewer";
import {Viewer, Worker, LocalizationMap} from "@react-pdf-viewer/core";
import ru_RU from "@react-pdf-viewer/locales/lib/ru_RU.json"
import {defaultLayoutPlugin} from "@react-pdf-viewer/default-layout";

interface Files {
    file: IFile
}

const PreviewLessonDocument = (
    {
        file
    }: Files
) => {

    const defaultLayoutPluginInstance = defaultLayoutPlugin();
    const[fileView, setFileView] = useState<any>()

    function switchExtension(file: IFile) {
        switch (file.extention) {
            case '.pdf':
                setFileView(<Worker workerUrl="https://unpkg.com/pdfjs-dist@3.4.120/build/pdf.worker.min.js">
                    <Viewer
                        fileUrl={file.pathName}
                        localization={ru_RU as unknown as LocalizationMap}
                        plugins={[
                            defaultLayoutPluginInstance,
                        ]}
                    />
                </Worker>)
                return;
            case '.youtu':
                const embed: string[] = file.pathName.split("/")
                setFileView(<iframe src={`https://www.youtube.com/embed/${embed[embed.length - 1]}`}/>)
                return;
            case '.mp4':
                setFileView(<video>
                    <source src={file.pathName}/>
                </video>)
                return;
            default:
                let docs = []
                docs.push({uri: file.pathName})
                setFileView(<DocViewer documents={docs} />)
                return ;
        }
    }

    useEffect(() => {
        if(file) {
            let domain = "https://storage.yandexcloud.net/apokdpo-sdo/"
            let location: string = window.location.origin
            if(location.indexOf("localhost:3000") !== -1) {
                domain = "https://storage.yandexcloud.net/for-testing-sdo/"
            }
            if(location.indexOf("dpo-plus.ru") !== -1) {
                domain = "https://storage.yandexcloud.net/for-testing-sdo/"
            }
            file.pathName = domain + file.pathName
            switchExtension(file)
        }
    }, [file])

    return (
        <div className="h-[610px] max-lg:h-[400px]">
            {fileView}
        </div>
    )
}

export default PreviewLessonDocument