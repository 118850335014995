import {IUser} from "../../../../../../models/users";
import React from "react";
import UserMainInformation from "./user-main-information/UserMainInformation";
import UserCourses from "./user-courses/UserCourses";
import UserGroups from "./user-groups/UserGroups";
import UserStatistics from "./user-statistics/UserStatistics";

interface IUsersTabs {
    user?: IUser
    activeTab: number
}

const UsersTabs = (
    {
        user,
        activeTab
    }: IUsersTabs
) => {
    return (
        <>
            <UserMainInformation activeTab={activeTab} user={user}/>
            {user && (
                <>
                    <div
                        className={`bg-white p-[32px] rounded-[6px] flex flex-col text-[16px] font-medium max-lg:p-[14px] max-lg:gap-0 overflow-hidden h-full` + (activeTab == 2 ? " block" : " hidden")}>
                        <UserGroups user={user}/>
                    </div>
                    <div
                        className={`bg-white p-[32px] rounded-[6px] flex flex-col text-[16px] font-medium max-lg:p-[14px] max-lg:gap-0 overflow-hidden h-full` + (activeTab == 3 ? " block" : " hidden")}>
                        <UserCourses user={user}/>
                    </div>
                    <div
                        className={`bg-white p-[32px] rounded-[6px] flex flex-col text-[16px] font-medium max-lg:p-[14px] max-lg:gap-0 overflow-hidden h-full` + (activeTab == 4 ? " block" : " hidden")}>
                        <UserStatistics user={user}/>
                    </div>
                </>
            )}
        </>
    )
}

export default UsersTabs