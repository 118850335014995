import React from "react";
import {ReactComponent as LessonIcon} from "../../../../../../assets/images/folders/lesson-icon.svg";
import {ReactComponent as TestIcon} from "../../../../../../assets/images/folders/test-icon.svg";
import {ILessonsHoursForAttach} from "../../../../../../models/lessons";
import {Link} from "react-router-dom";

interface IUsersAttachLessonsItemInactive {
    lesson: ILessonsHoursForAttach
    toggleLessonActive: (lesson: number, e: React.ChangeEvent<HTMLInputElement>) => void
}

const UsersAttachLessonsItemInactive = (
    {
        lesson,
        toggleLessonActive,
    }: IUsersAttachLessonsItemInactive
) => {

    return (
        <div
            className="flex justify-between items-center px-[18px] py-[20px] border-solid border-b-[2px] border-[#BDBDBD] last:border-none max-lg:py-[12px] max-lg:px-0">
            <div className="w-[50%] flex gap-[16px] items-center max-xl:w-full">
                {lesson.testId ?
                    <TestIcon fill={"#BDBDBD"} className="flex-shrink-0"/> :
                    <LessonIcon fill={"#BDBDBD"} className="flex-shrink-0"/>
                }
                <div
                    className="flex flex-col gap-[6px] text-[16px] font-medium leading-[120%] text-[#BDBDBD]">
                    <Link target="_blank" className="hover:underline" to={lesson.testId ?
                        `/folders/preview-test/${lesson.lessonId}/${lesson.testId}?attach-lesson=true` : `/folders/preview-lesson/${lesson.lessonId}?attach-lesson=true` }>
                        {lesson.name}
                    </Link>
                    <div className="">{lesson.testId ? "Тест" : "Лекция"}</div>
                </div>
            </div>
            <div className="w-[50%] max-xl:w-auto flex justify-end">
                <label className="inline-flex items-center cursor-pointer gap-[12px]">
                    <input
                        id={`checkbox-${lesson.lessonId}`}
                        name={`checkbox-${lesson.lessonId}`}
                        type="checkbox"
                        value=""
                        className="sr-only peer"
                        checked={false}
                        onChange={(event) => toggleLessonActive(lesson.lessonId, event)}/>
                    <span
                        className="text-[16px] font-medium leading-[120%] text-[#292B2C] peer-checked:text-[#BDBDBD] max-xl:hidden">Неактивно</span>
                    <div
                        className="relative w-[44px] h-[26px] bg-[#F2F2F2] rounded-[50px] peer
                                            p-[4px]
                                            peer-focus:outline-none
                                            peer-checked:after:translate-x-full
                                            rtl:peer-checked:after:-translate-x-full
                                            after:content-['']
                                            after:absolute
                                            after:top-[4px]
                                            after:start-[4px]
                                            after:bg-[#BDBDBD]
                                            peer-checked:after:bg-[#17AB57]
                                            after:rounded-full
                                            after:h-[18px]
                                            after:w-[18px]
                                            after:transition-all"></div>
                    <span
                        className="text-[16px] font-medium leading-[120%] text-[#BDBDBD] peer-checked:text-[#292B2C] max-xl:hidden">Активно</span>
                </label>
            </div>
        </div>
    )
}

export default UsersAttachLessonsItemInactive