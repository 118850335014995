import {Link, useNavigate, useParams} from "react-router-dom";
import Cookies from "universal-cookie";
import {useMutation, useQuery, useQueryClient} from "react-query";
import {UsersService} from "../../../../services/users.service";
import {AxiosError} from "axios";
import ErrorMessage from "../../../ui/error/ErrorMessage";
import React, {useContext, useEffect, useRef, useState} from "react";
import {ReactComponent as ArrowBackIcon} from "../../../../assets/images/global/arrow-back-icon.svg";
import PreviewLessonDocument from "../../../ui/preview-lesson-document/PreviewLessonDocument";
import PreviewLessonAdditionalFiles
    from "../../admin/preview/lesson/lesson-additional-files/PreviewLessonAdditionalFiles";
import {IProgressCourseLessonsUser,IUpdateLessonPassMark} from "../../../../models/users";
import {LessonsService} from "../../../../services/lessons.service";
import {IFile} from "../../../../models/file";
import {LoaderContext} from "../../../../context/LoaderContext";
import Loader from "../../../ui/loader/Loader";
import ListLessonInLesson from "../../../ui/list-lesson-in-lesson/ListLessonInLesson";
import {AuthContext} from "../../../../context/AuthContext";

const StudentLessonView = () => {

    const cookies = new Cookies()
    const navigate = useNavigate()
    const queryClient = useQueryClient()

    const {id, lessonId} = useParams()
    const [mainFile, setMainFile] = useState<IFile>({} as IFile)
    const [additionalFiles, setAdditionalFiles] = useState<IFile[]>([])
    const [showLessons, setShowLessons] = useState<boolean>(true)
    const [error, setError] = useState<number | null>(null)
    const refDownloadFile = useRef<HTMLAnchorElement>(null)

    const {authenticated, setAuthenticated, currentRoleId, currentUserId} = useContext(AuthContext)

    const {show, setShow} = useContext(LoaderContext)

    const lesson = useQuery(
        ["lesson_view", lessonId],
        () => LessonsService.getById(Number(lessonId)),
        {
            enabled: !!lessonId,
            onSuccess: (response) => {
                const arrAdditionalFiles: IFile[] = []
                response.files.map(lesson => {
                    if (lesson.isMain) {
                        setMainFile(lesson)
                    } else {
                        arrAdditionalFiles.push(lesson)
                    }
                })
                setAdditionalFiles(arrAdditionalFiles)
            }
        }
    )

    const downloadFile = useMutation(
        (id: number) => LessonsService.downloadFile(id),
        {
            onSuccess: (response) => {
                if(refDownloadFile.current) {
                    refDownloadFile.current.href = response
                    refDownloadFile.current.click()
                }
            }
        }
    )

    const handleDownloadFile = () => {
        downloadFile.mutate(mainFile.id)
    }

    const progress = useQuery(
        ["progress_lesson_view", lessonId],
        () => UsersService.getProgressCourseForLesson(Number(currentUserId), Number(id)),
        {
            enabled: !!id && !!cookies.get("user"),
            onSuccess: (response) => {
                if(response.isBlocked) navigate("/student-courses")
            }
        }
    )

    const updateLessonMarkPass = useMutation(
        (data: IUpdateLessonPassMark) => UsersService.updateLessonPassMark(Number(currentUserId), data),
        {
            onSuccess: () => {
                return queryClient.invalidateQueries(["progress_lesson_view", lessonId])
            },
            onError: (errorUpdate) => {
                setShow(false)
                const e = errorUpdate as AxiosError
                setError(e.response ? e.response.status : 400)
            }
        }
    )

    useEffect(() => {
        if(lesson.data && progress.data) {
            let prevLesson: IProgressCourseLessonsUser = {} as IProgressCourseLessonsUser
            progress.data.lessons.map((element, index) => {
                if(index !== 0 && element.id === lesson.data.id) {
                    prevLesson = progress.data.lessons[index - 1]
                }
            })
            if(Object.keys(prevLesson).length > 0 && !prevLesson.passMark) {
                navigate(`/student-courses/${progress.data.id}`)
            }
        }
    }, [lesson, progress])

    if (lesson.isLoading || progress.isLoading){
        return <Loader/>
    }

    if (lesson.isError) {
        const e = lesson.error as AxiosError
        return <ErrorMessage numberError={e.response ? e.response.status : 400}/>
    }

    if (progress.isError) {
        const e = progress.error as AxiosError
        return <ErrorMessage numberError={e.response ? e.response.status : 400}/>
    }

    if (!lesson.data || !progress.data) return <div className="text-center">Ошибка данных</div>

    const handleNextLesson = () => {
        progress.data.lessons.map(async (item, index) => {
            if (item.id == lesson.data.id) {
                const data: IUpdateLessonPassMark = {
                    "courseId": progress.data.id,
                    "lessonId": lesson.data.id
                }
                await updateLessonMarkPass.mutateAsync(data)
                const nextLesson = progress.data.lessons[index + 1]
                if (nextLesson) {
                    if (nextLesson.testId) {
                        if (nextLesson.passMark) {
                            navigate(`/student-courses/${progress.data.id}/lesson/${nextLesson.id}/test-view-result/${nextLesson.testId}`)
                        } else {
                            navigate(`/student-courses/${progress.data.id}/lesson/${nextLesson.id}/test-passing/${nextLesson.testId}`)
                        }
                    } else {
                        navigate(`/student-courses/${progress.data.id}/lesson/${nextLesson.id}`)
                    }
                } else {
                    navigate(`/student-courses/${progress.data.id}`)
                }
            }
        })
    }

    return (
        <>
            {error && (<ErrorMessage numberError={error}/>)}
            {show && <Loader/>}
            <div className="flex flex-col gap-[24px]">
                <div className="flex w-full justify-between items-center">
                    <div className="text-[36px] text-[#292B2C] font-bold leading-[120%]">{progress.data.name}</div>
                    <div
                        onClick={() => navigate(`/student-courses/${progress.data.id}`)}
                        className="flex gap-[6px] py-[8px] px-[12px] border-solid border-[1px] border-[#17AB57] rounded-[5px] cursor-pointer items-center">
                        <ArrowBackIcon/>
                        Назад
                    </div>
                </div>
                <div className="flex gap-[12px] max-2xl:flex-col">
                    <div className="flex gap-[12px] flex-col w-full">
                        <div className="p-[32px] bg-white rounded-[6px] flex gap-[12px] max-lg:flex-col items-center">
                            <div className="text-[24px] text-[#292B2C] font-bold w-full leading-[120%]">
                                {lesson.data.name}
                            </div>
                            {(mainFile.extention === ".youtu" || mainFile.extention === ".link") ? (
                                <div className="flex">
                                    <Link
                                        to={mainFile.pathName}
                                        target="_blank"
                                        rel="noopener noreferrer"
                                        className="px-[16px] py-[8px] text-white text-[16px] font-medium bg-[#17AB57] rounded-[5px]"
                                    >
                                        Перейти
                                    </Link>
                                </div>
                            ) : (
                                <>
                                    <div
                                        onClick={handleDownloadFile}
                                        className="cursor-pointer max-lg:w-full max-lg:text-center py-[8px] px-[16px] bg-[#17AB57] rounded-[5px] text-[16px] text-[#FFFFFF] font-bold">
                                        Скачать
                                    </div>
                                    <a ref={refDownloadFile} href="#" className="hidden"></a>
                                </>
                            )}
                        </div>
                        <div className="bg-white rounded-[6px] p-[32px]">
                            <PreviewLessonDocument file={mainFile}/>
                        </div>
                    </div>
                    <div className="flex gap-[12px] flex-col flex-shrink-0 w-[470px] max-2xl:w-full">
                        {additionalFiles && additionalFiles.length > 0 && (
                            <PreviewLessonAdditionalFiles files={additionalFiles}/>
                        )}
                        <ListLessonInLesson
                            preview={false}
                            lesson={lesson.data}
                            progress={progress.data}
                        />
                        {/*<div className="p-[32px] flex flex-col gap-[24px] rounded-[6px] bg-white">*/}
                        {/*    <div className="flex justify-between items-center">*/}
                        {/*        <div className="text-[24px] text-[#292B2C] font-bold">Уроки</div>*/}
                        {/*        <div*/}
                        {/*            onClick={() => setShowLessons(!showLessons)}*/}
                        {/*            className="text-[16px] text-[#BDBDBD] font-medium flex gap-[6px] items-center cursor-pointer">*/}
                        {/*            <Arrow className={`transition-all ` + (showLessons ? " rotate-180" : " rotate-0")}*/}
                        {/*                   width="18px" height="18px"/>*/}
                        {/*            {showLessons ? "Скрыть" : "Раскрыть"}*/}
                        {/*        </div>*/}
                        {/*    </div>*/}
                        {/*    <div*/}
                        {/*        className={`transition-all ` + (showLessons ? " h-[190px]" : " h-0")}>*/}
                        {/*        <ScrollContainer>*/}
                        {/*            {progress.data.lessons.map((item, index) => (*/}
                        {/*                <div*/}
                        {/*                    key={index}*/}
                        {/*                    className="py-[12px] border-b-[2px] border-solid border-[#BDBDBD] last:border-none flex gap-[12px] items-center mr-[15px]">*/}
                        {/*                    {item.passMark ? (*/}
                        {/*                        <>*/}
                        {/*                            <MarkPassLessonIcon className="flex-shrink-0"/>*/}
                        {/*                            <Link to={`/student-courses/${progress.data.id}/lesson/${item.id}`}>{item.name}</Link>*/}
                        {/*                        </>*/}
                        {/*                    ) : (*/}
                        {/*                        <>*/}
                        {/*                            {item.testId ? (*/}
                        {/*                                <TestIcon*/}
                        {/*                                    className={`flex-shrink-0 ` + ((progress.data.lessons[index].id == lesson.data.id) ? "fill-[#F21450]" : "fill-[#BDBDBD]")}/>*/}
                        {/*                            ) : (*/}
                        {/*                                <LessonIcon*/}
                        {/*                                    className={`flex-shrink-0 ` + ((progress.data.lessons[index].id == lesson.data.id) ? "fill-[#F21450]" : "fill-[#BDBDBD]")}/>*/}
                        {/*                            )}*/}
                        {/*                            {item.name}*/}
                        {/*                        </>*/}
                        {/*                    )}*/}
                        {/*                </div>*/}
                        {/*            ))}*/}
                        {/*        </ScrollContainer>*/}
                        {/*    </div>*/}
                        {/*    <div*/}
                        {/*        onClick={handleNextLesson}*/}
                        {/*        className="w-full px-[16px] py-[8px] text-white text-[16px] font-medium bg-[#17AB57] rounded-[5px] text-center cursor-pointer">*/}
                        {/*        {progress.data.lessons[progress.data.lessons.length - 1].id == lesson.data.id ? ("Завершить курс") : ("Следующий урок")}*/}
                        {/*    </div>*/}
                        {/*</div>*/}
                    </div>
                </div>
            </div>
        </>
    )
}

export default StudentLessonView