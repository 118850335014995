import {Link, useParams} from "react-router-dom";
import React, {useState} from "react";
import {CoursesService} from "../../../../../services/courses.service";
import 'react-tabs/style/react-tabs.css';
import {ReactComponent as CloseIcon} from "../../../../../assets/images/global/close-modal-icon.svg";
import CoursesTabs from "./course-tabs/CoursesTabs";
import {useMutation, useQuery} from "react-query";
import {AxiosError} from "axios";
import ErrorMessage from "../../../../ui/error/ErrorMessage";
import {FolderService} from "../../../../../services/folder.service";
import {IFolder} from "../../../../../models/folders";
import useToggle from "../../../../../hooks/useToggle";
import ChangeHours from "../../../../ui/modal/change-hours/ChangeHours";
import Loader from "../../../../ui/loader/Loader";

interface ITab {
    id: number
    label: string
}

const CourseDetail = () =>   {

    const {courseId} = useParams()
    // const [parentFolder, setParentFolder] = useState<IFolder>({} as IFolder)
    const [isModalChangeHours, setOnModalOpenChangeHours, setOnModalCloseChangeHours] = useToggle()

    // const folder = useMutation(
    //     (id: string) => FolderService.getParentFolder(id),
    //     {
    //         onSuccess: (response) => {
    //             console.log(response)
    //             setParentFolder(response)
    //         }
    //     }
    // )

    const courseDetail = useQuery(
        ["course_detail", courseId],
        () => CoursesService.getById(courseId ? courseId : ""),
        {
            keepPreviousData: false,
            enabled: !!courseId,
            onSuccess: async (response) => {
                // if(response.folderId) {
                //     await folder.mutateAsync(String(response.folderId))
                // }
            }
        }
    )

    let tabs: ITab[] = []
    tabs.push({id: 1, label: "Основное"})
    tabs.push({id: 2, label: "Уроки"})
    tabs.push({id: 3, label: "Пользователи"})

    const classTab = "px-[36px] py-[12px] bg-[#F2F2F2] border-b-[4px] border-solid border-[#BDBDBD] cursor-pointer text-[20px] text-[#292B2C] font-medium leading-[120%] flex items-center " +
        "max-lg:rounded-[43px] max-lg:px-[12px] max-lg:py-[4px] max-lg:bg-[#E0E0E0] max-lg:text-[14px] max-lg:text-[14px] max-lg:border-none whitespace-nowrap"

    const [activeTab, setActiveTab] = useState<number>(1)

    const handleChangeHour = () => {
        setOnModalOpenChangeHours()
    }

    if(courseDetail.isLoading) return <Loader/>

    if (courseDetail.isError) {
        const e = courseDetail.error as AxiosError
        return <ErrorMessage numberError={e.response ? e.response.status : 400}/>
    }

    if(!courseDetail.data) {
        return <div className="text-center">Данные отсутствуют</div>
    }

    return (
        <>
            <div className="h-full">
                <div className="flex flex-col gap-[16px] max-lg:gap-[8px] h-full">
                    <div className="flex justify-between items-center max-lg:flex-col max-lg:gap-[12px] max-lg:items-start">
                        <h2 className="font-bold text-[36px] leading-[120%] max-lg:text-[24px]">{courseDetail.data.name} ({courseDetail.data.hours})</h2>
                        <div className="flex gap-[12px] max-lg:text-[14px]">
                            <div
                                onClick={handleChangeHour}
                                className="flex gap-[6px] py-[8px] px-[12px] max-lg:py-[4px] max-lg:px-[8px] border-solid border-[1px] border-[#17AB57] rounded-[5px] cursor-pointer items-center">
                                Поменять массово часы в уроках
                            </div>
                            <Link
                                to={`/folders` + (courseDetail.data.folderId ? `/${courseDetail.data.folderId}` : "")}
                                className="flex gap-[6px] py-[8px] px-[12px] max-lg:py-[4px] max-lg:px-[8px] border-solid border-[1px] border-[#17AB57] rounded-[5px] cursor-pointer items-center">
                                <CloseIcon width="24px" height="24px"/>
                                Выйти из курса
                            </Link>
                        </div>
                    </div>
                    <div className="flex max-lg:gap-[8px] max-lg:overflow-x-auto mb-[8px]">
                        {tabs && tabs.map((tab, index) => (
                            <div onClick={() => setActiveTab(tab.id)} key={index} className={classTab + ((activeTab == tab.id) ? " !border-[#17AB57] focus:outline-none max-lg:text-white max-lg:text-[14px] max-lg:!border-none max-lg:!bg-[#17AB57] " : "")}>
                                {tab.label}
                            </div>
                        ))}
                    </div>
                    <CoursesTabs course={courseDetail.data} activeTab={activeTab}/>
                </div>
            </div>
            {isModalChangeHours && <ChangeHours
                courseId={courseDetail.data.id}
                onClose={setOnModalCloseChangeHours}/>}
        </>
    )
}

export default CourseDetail