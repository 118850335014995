import {createPortal} from "react-dom";
import React from "react";

interface Portal{
    children: React.ReactNode
}

const Portal = ({children}: Portal) => {
    return createPortal(children, document.body)
}

export default Portal