import {Link, useParams} from "react-router-dom";
import React, {useEffect, useRef, useState} from "react";
import {FolderService} from "../../../../services/folder.service";
import {IFolder} from "../../../../models/folders";
import useToggle from "../../../../hooks/useToggle";
import ModalAddFolder from "../../../ui/modal/modal-add-folder/ModalAddFolder";
import ModalAddCourse from "../../../ui/modal/modal-add-course/ModalAddCourse";
import {ICourses} from "../../../../models/courses";
import CoursesList from "../courses/courses-list/CoursesList";
import {AxiosError} from "axios";
import ErrorMessage from "../../../ui/error/ErrorMessage";
import {ReactComponent as CreateFolderIcon} from "../../../../assets/images/folders/create-folder-icon.svg";
import {ReactComponent as CreateCoursesIcon} from "../../../../assets/images/folders/create-courses-icon.svg";
import {ReactComponent as ArrowBackIcon} from "../../../../assets/images/global/arrow-back-icon.svg";
import FoldersItem from "./folders-item/FoldersItem";
import {ReactComponent as ArrowSortIcon} from "../../../../assets/images/global/arrow-sort-icon.svg";
import {useMutation, useQuery} from "react-query";
import SearchInput from "../../../ui/search-input/SearchInput";
import SearchFolders from "../../../ui/search-input/search-folders/SearchFolders";
import ScrollContainer from "../../../ui/scroll-container/ScrollContainer";
import {CoursesService} from "../../../../services/courses.service";
import Loader from "../../../ui/loader/Loader";


const Folders = () => {

    const {folderId} = useParams()
    const [filteredFolders, setFilteredFolders] = useState<IFolder[]>([])
    const [parentFolder, setParentFolder] = useState<IFolder>({} as IFolder)
    const [isModalAddFolder, setOnModalOpenAddFolder, setOnModalCloseAddFolder] = useToggle()
    const [modalFolder, setModalFolder] = useState<IFolder>({} as IFolder)
    const [isModalAddCourse, setOnModalOpenAddCourse, setOnModalCloseAddCourse] = useToggle()
    const [modalCourse, setModalCourse] = useState<ICourses>({} as ICourses)
    const refListFolders = useRef<HTMLDivElement>(null)
    const [rect, setRect] = useState<DOMRect>({} as DOMRect)
    const [activeSort, setActiveSort] = useState<boolean>(false)
    const [error, setError] = useState<number | null>(null)
    const [showLoader, setShowLoader] = useState<boolean>(false)

    const folders = useQuery(
        ["folders", folderId],
        () => FolderService.getById(folderId ? folderId : ""),
        {
            onSuccess: (response) => {
                setFilteredFolders(response)
            }
        }
    )

    const foldersParent = useQuery(
        ["folder_parent", folderId],
        () => FolderService.getParentFolder(folderId ? folderId : ""),
        {
            enabled: !!folderId,
            keepPreviousData: false,
            onSuccess: (response) => {
                setParentFolder(response)
            }
        }
    )

    useEffect(() => {
        if (filteredFolders.length > 0 && refListFolders.current) {
            setRect(refListFolders.current.getBoundingClientRect())
        }
    }, [filteredFolders])

    useEffect(() => {
        setFilteredFolders(prev => [modalFolder, ...prev])
    }, [modalFolder])

    function removeFolder(id: number) {
        setFilteredFolders(filteredFolders.filter(f => f.id !== id))
    }

    const handleSort = () => {
        const arrSort = [
            {method: (a: IFolder, b: IFolder) => a.name > b.name ? 1 : -1},
            {method: (a: IFolder, b: IFolder) => a.name < b.name ? 1 : -1}
        ]
        const sorted = filteredFolders.sort(!activeSort ? arrSort[0].method : arrSort[1].method)
        setFilteredFolders([...sorted])
        setActiveSort(!activeSort)
    }

    const [searchTerm, setSearchTerm] = useState<string>("")
    const [showSearchResult, setShowSearchResult] = useState<boolean>(false)
    const refSearch = useRef<HTMLInputElement>(null)
    const onClearInput = () => {
        if (refSearch.current)
            refSearch.current.value = ""
    }

    const unloadingCourseForFedkurs = useMutation(
        () => CoursesService.unloadingCourseFromFedkurs(),
        {
            onSuccess: (response) => {
                setShowLoader(false)
            },
            onError: (error) => {
                setShowLoader(false)
            }
        }
    )

    const handleUnloadingCourseForFedkurs = () => {
        setShowLoader(true)
        unloadingCourseForFedkurs.mutate()
    }

    if (folders.isLoading) return <div className="text-center">Загрузка...</div>
    if (foldersParent.isLoading) return <div className="text-center">Загрузка...</div>

    if (folders.isError) {
        const e = folders.error as AxiosError
        return <ErrorMessage numberError={e.response ? e.response.status : 400}/>
    }
    if (foldersParent.isError) {
        const e = foldersParent.error as AxiosError
        return <ErrorMessage numberError={e.response ? e.response.status : 400}/>
    }

    return (
        <>
            {showLoader && (<Loader/>)}
            <div className="h-full">
                <div className="flex flex-col gap-[34px] max-lg:gap-[16px] h-full">
                    <div className="flex justify-between items-center max-lg:flex-col max-lg:gap-[10px] max-lg:items-start">
                        <h2 className="font-bold text-[36px] leading-[120%]">Курсы</h2>
                        <div className="flex gap-[10px] items-center">
                            <Link
                                to={`/folders/${foldersParent.data ? foldersParent.data.id : ""}`}
                                className={`flex gap-[6px] py-[8px] px-[12px] border-solid border-[1px] border-[#17AB57] rounded-[5px] cursor-pointer` + (folderId ? " block" : " hidden")}>
                                <ArrowBackIcon/>
                                Назад
                            </Link>
                            {window.location.host.indexOf("fedkurs") !== -1 && (
                                <div
                                    onClick={handleUnloadingCourseForFedkurs}
                                    className="py-[8px] px-[12px] border-solid border-[1px] border-[#17AB57] rounded-[5px] cursor-pointer">Выгрузить
                                    курсы на Федкурс
                                </div>
                            )}
                        </div>
                    </div>
                    <div className="w-full flex gap-[12px] max-lg:flex-col items-center text-[16px] max-lg:gap-[16px]">
                        <SearchInput
                            refSearch={refSearch}
                            setShowSearchResult={setShowSearchResult}
                            showSearchResult={showSearchResult}
                            searchTerm={searchTerm}
                            setSearchTerm={setSearchTerm}
                        >
                            <SearchFolders
                                setSearchTerm={setSearchTerm}
                                onClearInput={onClearInput}
                                setShowSearchResult={setShowSearchResult}
                                searchTerm={searchTerm}/>
                        </SearchInput>
                        <div className="flex gap-[12px] max-lg:w-full">
                            <div
                                onClick={() => setOnModalOpenAddFolder()}
                                className="border-[1px] border-[#17AB57] border-solid rounded-[5px] items-center flex gap-[6px] py-[8px] pl-[12px] pr-[16px] font-medium whitespace-nowrap cursor-pointer max-lg:w-[50%] justify-center">
                                <CreateFolderIcon className="flex-shrink-0"/>
                                Создать папку
                            </div>
                            <div
                                onClick={() => setOnModalOpenAddCourse()}
                                className="bg-[#17AB57] text-white rounded-[5px] items-center flex gap-[6px] py-[8px] pl-[12px] pr-[16px] font-medium whitespace-nowrap cursor-pointer max-lg:w-[50%] justify-center">
                                <CreateCoursesIcon className="flex-shrink-0"/>
                                Создать курс
                            </div>
                        </div>
                    </div>
                    <div ref={refListFolders}
                         className="bg-white p-[32px] rounded-[6px] flex flex-col text-[16px] font-medium max-lg:px-[14px] max-lg:py-[2px] max-lg:gap-0 h-full">
                        {error && <ErrorMessage numberError={error}/>}
                        <div
                            className="px-[18px] flex justify-between text-[#828282] text-[16px] font-medium whitespace-nowrap max-lg:hidden mb-[18px]">
                            <div className="w-[30%] cursor-pointer flex gap-[4px]" onClick={handleSort}>
                                Название
                                <ArrowSortIcon
                                    className={!activeSort ? "rotate-180 transition-all" : "rotate-0 transition-all"}/>
                            </div>
                            <div className="w-[10%]">Дата создания</div>
                            <div className="w-[10%]">Дата изменения</div>
                            <div className="w-[6%] flex justify-end">Действия</div>
                        </div>
                        {/*<ScrollContainer>*/}
                            {filteredFolders && filteredFolders.map((folder, index) => (
                                <FoldersItem
                                    setSearchTerm={setSearchTerm}
                                    onClearInput={onClearInput}
                                    setShowSearchResult={setShowSearchResult}
                                    setError={setError}
                                    parentParameter={rect}
                                    removeFolder={removeFolder}
                                    folder={folder}
                                    key={index}/>))}
                            <CoursesList activeSort={activeSort} parentParameter={rect} modalCourse={modalCourse}
                                         folderId={folderId}/>
                        {/*</ScrollContainer>*/}
                    </div>
                    {isModalAddFolder && <ModalAddFolder
                        setModalFolder={setModalFolder}
                        folderId={folderId}
                        onClose={setOnModalCloseAddFolder}/>}
                    {isModalAddCourse && <ModalAddCourse
                        parentFolder={parentFolder}
                        setModalCourse={setModalCourse}
                        folderId={folderId}
                        onClose={setOnModalCloseAddCourse}/>}
                </div>
            </div>
        </>
    )
}

export default Folders