import {IFile} from "../../../models/file";
import React, {useContext, useEffect, useRef, useState} from "react";
import {ReactComponent as LinkIcon} from "../../../assets/images/folders/lesson-link-file.svg";
import {ReactComponent as FileIcon} from "../../../assets/images/folders/lesson-file-icon.svg";
import {ReactComponent as CloseIcon} from "../../../assets/images/global/close-modal-icon.svg";
import {ReactComponent as AttachFileIcon} from "../../../assets/images/folders/attach-file-icon.svg";
import {ReactComponent as AttachLinkIcon} from "../../../assets/images/folders/attach-link-icon.svg";
import {useFormContext} from "react-hook-form";
import {FileService} from "../../../services/file.service";
import {ITest} from "../../../models/tests";
import {LoaderContext} from "../../../context/LoaderContext";
import Loader from "../loader/Loader";
import {useMutation} from "react-query";
import {LessonsService} from "../../../services/lessons.service";

interface FilesLesson {
    files: IFile[] | undefined
    fileModal: IFile
    setOnModalOpenAddFile: () => void
    setIsMainFile: (flag: boolean) => void
    classNameFieldForm: string
    lessonId: number | undefined
    test?: ITest
    setFilesLesson: (file: number[]) => void
}

const FilesLesson = (
    {
        files,
        fileModal,
        setOnModalOpenAddFile,
        setIsMainFile,
        classNameFieldForm,
        setFilesLesson
    }: FilesLesson) => {

    const {show, setShow} = useContext(LoaderContext)
    const [mainFile, setMainFile] = useState<IFile>({} as IFile)
    const [additionalFiles, setAdditionalFiles] = useState<IFile[]>([])
    const [isMain, setIsMain] = useState<boolean>(true)
    const mainUploaderRef = useRef<HTMLInputElement>(null)
    const additionalUploaderRef = useRef<HTMLInputElement>(null)
    const [allFiles, setAllFiles] = useState<number[]>([])
    const refDownloadFile = useRef<HTMLAnchorElement>(null)

    const {
        register,
        setValue,
        resetField,
        getValues,
        formState: {errors}
    } = useFormContext()

    useEffect(() => {
        const arAdditionalFiles: IFile[] = []
        let arrAdditionalId: number[] = []
        files?.map(file => {
            if (file.isMain) {
                setMainFile(file)
                setValue("mainFile", file.id)
            } else {
                arAdditionalFiles.push(file)
                arrAdditionalId.push(file.id)
            }
        })
        setAdditionalFiles(arAdditionalFiles)
        setValue("additionalFiles", JSON.stringify(arrAdditionalId))
    }, [files])

    useEffect(() => {
        if (Object.keys(fileModal).length > 0) {
            setShow(true)
            if (fileModal.isMain) {
                setMainFile(fileModal)
            } else {
                setAdditionalFiles(prev => [...prev, fileModal])
                let arrAdditionalId = []
                if (getValues("additionalFiles"))
                    arrAdditionalId = JSON.parse(getValues("additionalFiles"))
                arrAdditionalId.push(fileModal.id)
                setValue("additionalFiles", JSON.stringify(arrAdditionalId))
            }
            setShow(false)
        }
    }, [fileModal])

    const onChangeFile = async (e: React.ChangeEvent<HTMLInputElement>) => {
        if (e.target.files) {
            setShow(true)
            if (isMain) {
                let obj = {
                    "file": e.target.files[0]
                }
                const response = await FileService.create(obj)
                setValue("mainFile", response.id)
                setMainFile(response)
            } else {
                await Promise.all(Array.from(e.target.files).map(async (file) => {
                    let obj = {
                        "file": file
                    }
                    const response = await FileService.create(obj)
                    setAdditionalFiles(prev => [...prev, response])
                    let arrAdditionalId = []
                    if (getValues("additionalFiles"))
                        arrAdditionalId = JSON.parse(getValues("additionalFiles"))
                    arrAdditionalId.push(response.id)
                    setValue("additionalFiles", JSON.stringify(arrAdditionalId))
                }))
            }
            setShow(false)
        }
    }

    const uploadFile = (isMain: boolean) => {
        setIsMain(isMain)
        if (isMain) {
            if (mainUploaderRef.current) {
                mainUploaderRef.current.click()
            }
        } else {
            if (additionalUploaderRef.current) {
                additionalUploaderRef.current.click()
            }
        }
    }
    const uploadLink = (isMain: boolean) => {
        setIsMainFile(isMain)
        setOnModalOpenAddFile()
    }

    const onDelete = async (id: number, isMain: boolean) => {
        if (isMain) {
            setMainFile({} as IFile)
            resetField("mainFile")
        } else {
            setAdditionalFiles(additionalFiles.filter(f => f.id !== id))
            let arrAdditionalId: number[] = []
            additionalFiles.filter(f => f.id !== id).map(file => {
                arrAdditionalId.push(file.id)
            })
            setValue("additionalFiles", JSON.stringify(arrAdditionalId))
        }
    }

    useEffect(() => {
        setAllFiles([])
        if (Object.keys(mainFile).length > 0) {
            setAllFiles(prev => [...prev, mainFile.id])
        }
        additionalFiles.map((file) => {
            setAllFiles(prev => [...prev, file.id])
        })
    }, [mainFile, additionalFiles])

    useEffect(() => {
        setFilesLesson(allFiles)
    }, [allFiles])

    const handleDownload = (id: number) => {
        downloadFile.mutate(id)
    }

    const downloadFile = useMutation(
        (id: number) => LessonsService.downloadFile(id),
        {
            onSuccess: (response) => {
                if(refDownloadFile.current) {
                    refDownloadFile.current.href = response
                    refDownloadFile.current.click()
                }
            }
        }
    )

    return (
        <>
            {show && <Loader/>}
            <a ref={refDownloadFile} href="#" className="hidden"></a>
            <div className={classNameFieldForm}>
                <div className="w-[170px] flex-shrink-0">Основной файл</div>
                {Object.keys(mainFile).length > 0 ? (
                    <div className="flex gap-[6px] items-center">
                        {mainFile.extention === ".youtu" || mainFile.extention === ".link" ?
                            <LinkIcon className="flex-shrink-0"/> : <FileIcon className="flex-shrink-0"/>
                        }
                        <div className="cursor-pointer break-all" onClick={() => handleDownload(mainFile.id)}>{mainFile.originalName + mainFile.extention}</div>
                        <CloseIcon className="cursor-pointer flex-shrink-0" width="18px" height="18px"
                                   onClick={() => onDelete(mainFile.id, true)}/>
                        <input type="hidden" {...register("mainFile", {required: true})}/>
                    </div>
                ) : (
                    <div className="flex flex-col gap-[8px]">
                        <div className="flex items-center cursor-pointer gap-[6px]" onClick={() => uploadFile(true)}>
                            <AttachFileIcon className="flex-shrink-0"/>
                            Прикрепить файл
                            <input
                                {...register("mainFile", {required: true})}
                                className="hidden"
                                type="file"
                                data-main={true}
                                onChange={onChangeFile}
                                ref={mainUploaderRef}/>
                        </div>
                        <div className="flex items-center cursor-pointer gap-[6px]" onClick={() => uploadLink(true)}>
                            <AttachLinkIcon className="flex-shrink-0"/>
                            Прикрепить ссылку
                        </div>
                        {errors.mainFile && errors.mainFile.type === "required" && (
                            <p className="text-red-600">Файл обязателен</p>
                        )}
                    </div>
                )}
            </div>

            <div className={classNameFieldForm}>
                <div className="w-full">
                    <div className="flex gap-[18px] max-lg:flex-col">
                        <div className="w-[170px] flex-shrink-0">Дополнительные
                            файлы
                        </div>
                        <div
                            className={`flex flex-col gap-[8px] w-full` + (additionalFiles.length > 0 ? " border-b-[2px] border-solid border-[#BDBDBD] pb-[12px] max-lg:border-none" : "")}>
                            <div className="flex items-center cursor-pointer gap-[6px]"
                                 onClick={() => uploadFile(false)}>
                                <AttachFileIcon className="flex-shrink-0"/>
                                Прикрепить файл
                                <input
                                    type="file"
                                    ref={additionalUploaderRef}
                                    className="hidden"
                                    onChange={onChangeFile}
                                    data-main={false}
                                    multiple/>
                            </div>
                            <div className="flex items-center cursor-pointer gap-[6px]"
                                 onClick={() => uploadLink(false)}>
                                <AttachLinkIcon className="flex-shrink-0"/>
                                Прикрепить ссылку
                            </div>
                        </div>
                    </div>
                    {additionalFiles.length > 0 && (
                        <div className="flex gap-[18px] pt-[12px] max-lg:flex-col">
                            <div className="w-[170px] flex-shrink-0">Загружено</div>
                            <div className="flex flex-col gap-[8px] w-full">
                                {additionalFiles.map((file, index) => (
                                    <div key={index} className="flex gap-[6px] items-center">
                                        {file.extention === ".youtu" || file.extention === ".link" ?
                                            <LinkIcon className="flex-shrink-0"/> :
                                            <FileIcon className="flex-shrink-0"/>
                                        }
                                        <div className="cursor-pointer break-all" onClick={() => handleDownload(file.id)}>{file.originalName + file.extention}</div>
                                        <CloseIcon className="cursor-pointer flex-shrink-0" width="18px" height="18px"
                                                   onClick={() => onDelete(file.id, false)}/>
                                        <input type="hidden" {...register("mainFile", {required: true})}/>
                                    </div>
                                ))}
                            </div>
                        </div>
                    )}
                    <input type="hidden" {...register("additionalFiles")}/>
                </div>
            </div>
        </>
    )
}

export default FilesLesson