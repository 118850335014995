import Portal from "../portal/Portal";
import Modal from "../Modal";
import React, {useState} from "react";
import ErrorMessage from "../../error/ErrorMessage";
import {ReactComponent as CloseIcon} from "../../../../assets/images/global/close-modal-icon.svg";
import {IRecoveryPassword} from "../../../../models/users";
import {SubmitHandler, useForm} from "react-hook-form";
import {ICourses} from "../../../../models/courses";
import {useMutation} from "react-query";
import {TestsService} from "../../../../services/tests.service";
import {UsersService} from "../../../../services/users.service";
import {AxiosError} from "axios";
import Loader from "../../loader/Loader";

interface IModalRecoveryPassword {
    onClose: () => void
}

const ModalRecoveryPassword = (
    {
        onClose
    }: IModalRecoveryPassword
) => {

    const [error, setError] = useState<number | null>(null)
    const [showLoading, setShowLoading] = useState<boolean>(false)
    const [mail, setMail] = useState<string>("")

    const {
        register,
        handleSubmit,
        formState: {errors}
    } = useForm<IRecoveryPassword>()

    const onSubmit: SubmitHandler<IRecoveryPassword> = async (data) => {
        setShowLoading(true)
        recoveryPassword.mutate(data)
    }

    const recoveryPassword = useMutation(
        (data: IRecoveryPassword) => UsersService.recoveryPassword(data),
        {
            onSuccess: (response) => {
                setShowLoading(false)
                setError(null)
                setMail(response.email)
            },
            onError: (error) => {
                const e = error as AxiosError
                setError(e.response ? e.response.status : 400)
                setShowLoading(false)
            }
        }
    )

    return (
        <Portal>
            <Modal>
                {error && <ErrorMessage numberError={error}/>}
                {showLoading && <Loader/>}
                {mail ? (
                    <div className="flex flex-col gap-[24px] text-[16px] p-[18px] pt-[24px] w-full">
                        <div className="flex flex-col text-[16px] max-lg:text-[14px] gap-[14px]">
                            <div className="flex justify-between">
                                <span className="text-[28px] font-bold max-lg:text-[20px] leading-[120%]">Пароль успешно отправлен</span>
                                <CloseIcon className="cursor-pointer max-lg:hidden" onClick={onClose}/>
                            </div>
                            <div className="">Проверьте почту {mail}</div>
                        </div>
                        <button
                            className="block font-medium py-2 px-4 rounded-[5px] border-[1px] border-solid border-[#17AB57]"
                            onClick={onClose}>Закрыть
                        </button>
                    </div>
                ) : (
                    <form className="flex flex-col gap-[24px] text-[16px] p-[18px] pt-[24px]"
                          onSubmit={handleSubmit(onSubmit)}>
                        <div className="flex flex-col text-[16px] max-lg:text-[14px]">
                            <div className="flex justify-between">
                                <span className="text-[28px] font-bold max-lg:text-[20px] leading-[120%]">Восстановление пароля</span>
                                <CloseIcon className="cursor-pointer max-lg:hidden" onClick={onClose}/>
                            </div>
                            <div className="">Отправим на почту новый пароль</div>
                        </div>
                        <div className="w-full flex flex-col gap-[6px]">
                            <label htmlFor="email"
                                   className="text-[14px]">
                                Электронная почта
                            </label>
                            <div className="">
                                <input
                                    type="email"
                                    autoComplete="email"
                                    {...register("email", {required: true})}
                                    className="block w-full rounded-[5px] border-[1px] border-solid border-[#BDBDBD] py-[8px] pl-[10px] pr-[12px] h-[40px]"
                                />
                                {errors.email && errors.email.type === "required" && (
                                    <p className="text-red-600">Заполните поле</p>
                                )}
                            </div>
                        </div>
                        <div className="flex flex-col gap-[12px]">
                            <button
                                type="submit"
                                className="text-white font-medium py-[8px] px-[16px] rounded-[5px] bg-[#17AB57]"
                            >
                                Отправить
                            </button>
                            <button
                                className="hidden max-lg:block font-medium py-2 px-4 rounded-[5px] border-[1px] border-solid border-[#17AB57]"
                                onClick={onClose}>Назад
                            </button>
                        </div>
                    </form>
                )}
            </Modal>
        </Portal>
    )
}

export default ModalRecoveryPassword