import {Constants} from "../../../../../constans/constants";
import React from "react";
import {ReactComponent as Files} from "../../../../../assets/images/chat/files.svg";
import {ReactComponent as BackArrow} from "../../../../../assets/images/global/arrow-back-icon.svg";
import {ICompanion} from "../../../../../models/chat";

interface ICompanionProps {
    companion: ICompanion;
    handleCloseChat: () => void
    setShowFiles: (flag: boolean) => void
    showFiles: boolean
}

const Companion = (
    {
        companion,
        handleCloseChat,
        setShowFiles,
        showFiles
    }: ICompanionProps
) => {

    return (
        <div
            className="bg-white px-[16px] py-[12px] border-b-[2px] border-[#BDBDBD] flex w-full justify-between items-center">
            <div className="flex gap-[12px] items-center">
                <BackArrow onClick={handleCloseChat}
                           className="w-[24px] h-[24px] fill-[#BDBDBD] hidden max-lg:block"/>
                <div className="flex items-center gap-[10px]">
                    {companion.avatar ? (
                        <img className="w-[48px] h-[48px] flex-shrink-0 rounded-[49px]"
                             src={companion.avatar}
                             alt=""/>
                    ) : (
                        <div
                            className={`w-[48px] h-[48px] flex-shrink-0 flex justify-center items-center rounded-[49px] ${Constants.LIST_COLORS[Number(companion.color)]}`}>
                                        <span
                                            className={`text-[20px] font-[500] text-white`}>
                                            {companion.lastName.trim()[0]}{companion.firstName.trim()[0]}
                                        </span>
                        </div>
                    )}
                    <div className="flex flex-col gap-[4px]">
                                    <span
                                        className="text-[#292B2C] font-bold">{companion.firstName} {companion.fatherName} {companion.lastName}</span>
                        {companion.isOnline && (
                            <div className="text-[#BDBDBD] text-[16px] flex items-center gap-[4px]">
                                <div className="w-[8px] h-[8px] rounded-[45px] bg-[#17AB57]"></div>
                                Онлайн
                            </div>
                        )}
                    </div>
                </div>
            </div>
            <Files onClick={() => setShowFiles(!showFiles)}
                   className={`cursor-pointer hover:fill-[#2F80ED] ${!showFiles ? "block" : "hidden"}`}/>
        </div>
    )
}

export default Companion