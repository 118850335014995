import ScrollContainer from "../scroll-container/ScrollContainer";
import {ReactComponent as Arrow} from "../../../assets/images/global/arrow-avatar.svg";
import {ReactComponent as MarkPassLessonIcon} from "../../../assets/images/global/mark-pass-lesson-icon.svg";
import {ReactComponent as TestIcon} from "../../../assets/images/folders/test-icon.svg";
import {ReactComponent as LessonIcon} from "../../../assets/images/folders/lesson-icon.svg";
import {Link, useNavigate} from "react-router-dom";
import React, {useContext, useEffect, useState} from "react";
import {IProgressCourseUser, IUpdateLessonPassMark} from "../../../models/users";
import {ILessonsEdit, IListLessonsByCourse} from "../../../models/lessons";
import {useMutation, useQueryClient} from "react-query";
import {UsersService} from "../../../services/users.service";
import {AxiosError} from "axios";
import Cookies from "universal-cookie";
import {AuthContext} from "../../../context/AuthContext";

interface IListLessonInLesson {
    progress?: IProgressCourseUser
    lessons?: IListLessonsByCourse,
    preview: boolean,
    lesson: ILessonsEdit
}

const ListLessonInLesson = (
    {
        preview,
        progress,
        lessons,
        lesson
    }: IListLessonInLesson
) => {

    const cookies = new Cookies()
    const navigate = useNavigate()
    const queryClient = useQueryClient()

    const [showLessons, setShowLessons] = useState<boolean>(true)
    const [error, setError] = useState<number | null>(null)
    const [show, setShow] = useState<boolean>(false)
    const [urlNextLesson, setUrlNextLesson] = useState<string>("")

    const {authenticated, setAuthenticated, currentRoleId, currentUserId} = useContext(AuthContext)

    useEffect(() => {
        if(preview && lessons) {
            lessons.lessons.map((item, index) => {
                if (item.id == lesson.id) {
                    const nextLesson = lessons.lessons[index + 1]
                    if (nextLesson) {
                        if (nextLesson.testId) {
                            setUrlNextLesson(`/folders/preview-test/${nextLesson.id}/${nextLesson.testId}`)
                        } else {
                            setUrlNextLesson(`/folders/preview-lesson/${nextLesson.id}`)
                        }
                    } else {
                        setUrlNextLesson(`/folders/courses/${lesson.courseId}`)
                    }
                }
            })
        }
    }, [lesson]);

    const updateLessonMarkPass = useMutation(
        (data: IUpdateLessonPassMark) => UsersService.updateLessonPassMark(Number(currentUserId), data),
        {
            onSuccess: () => {
                return queryClient.invalidateQueries(["progress_lesson_view", lesson.id])
            },
            onError: (errorUpdate) => {
                setShow(false)
                const e = errorUpdate as AxiosError
                setError(e.response ? e.response.status : 400)
            }
        }
    )

    const handleNextLesson = () => {
        if(progress) {
            progress.lessons.map(async (item, index) => {
                if (item.id == lesson.id) {
                    const data: IUpdateLessonPassMark = {
                        "courseId": progress.id,
                        "lessonId": lesson.id
                    }
                    await updateLessonMarkPass.mutateAsync(data)
                    const nextLesson = progress.lessons[index + 1]
                    if (nextLesson) {
                        if (nextLesson.testId) {
                            if (nextLesson.passMark) {
                                navigate(`/student-courses/${progress.id}/lesson/${nextLesson.id}/test-view-result/${nextLesson.testId}`)
                            } else {
                                navigate(`/student-courses/${progress.id}/lesson/${nextLesson.id}/test-passing/${nextLesson.testId}`)
                            }
                        } else {
                            navigate(`/student-courses/${progress.id}/lesson/${nextLesson.id}`)
                        }
                    } else {
                        navigate(`/student-courses/${progress.id}`)
                    }
                }
            })
        }
    }

    return (
        <div className="p-[32px] flex flex-col gap-[24px] rounded-[6px] bg-white">
            <div className="flex justify-between items-center">
                <div className="text-[24px] text-[#292B2C] font-bold">Уроки</div>
                <div
                    onClick={() => setShowLessons(!showLessons)}
                    className="text-[16px] text-[#BDBDBD] font-medium flex gap-[6px] items-center cursor-pointer">
                    <Arrow className={`transition-all ` + (showLessons ? " rotate-180" : " rotate-0")}
                           width="18px" height="18px"/>
                    {showLessons ? "Скрыть" : "Раскрыть"}
                </div>
            </div>
            <div
                className={`transition-all ` + (showLessons ? " h-[190px]" : " h-0")}>
                <ScrollContainer chat={false}>
                    {preview && lessons && (
                        <>
                            {lessons.lessons.map((item, index) => (
                                <div
                                    key={index}
                                    className="py-[12px] border-b-[2px] border-solid border-[#BDBDBD] last:border-none flex gap-[12px] items-center mr-[15px]">
                                    {lesson.order > item.order && (
                                        <MarkPassLessonIcon className="flex-shrink-0"/>
                                    )}
                                    {lesson.order === item.order && (
                                        <>
                                            {lesson.testId ? (
                                                <TestIcon className="flex-shrink-0 fill-[#F21450]"/>
                                            ) : (
                                                <LessonIcon className="flex-shrink-0 fill-[#F21450]"/>
                                            )}
                                        </>
                                    )}
                                    {lesson.order < item.order && (
                                        <>
                                            {item.testId ? (
                                                <TestIcon className="flex-shrink-0 fill-[#BDBDBD]"/>
                                            ) : (
                                                <LessonIcon className="flex-shrink-0 fill-[#BDBDBD]"/>
                                            )}
                                        </>
                                    )}
                                    <Link
                                        className="hover:underline"
                                        to={item.testId ? `/folders/preview-test/${item.id}/${item.testId}` : `/folders/preview-lesson/${item.id}`}>
                                        {item.name}
                                    </Link>
                                </div>
                            ))}
                        </>
                    )}
                    {!preview && progress && (
                        <>
                            {progress.lessons.map((item, index) => (
                                <div
                                    key={index}
                                    className="py-[12px] border-b-[2px] border-solid border-[#BDBDBD] last:border-none flex gap-[12px] items-center mr-[15px]">
                                    {item.passMark ? (
                                        <>
                                            <MarkPassLessonIcon className="flex-shrink-0"/>
                                            <Link
                                                to={`/student-courses/${progress.id}/lesson/${item.id}`}>{item.name}</Link>
                                        </>
                                    ) : (
                                        <>
                                            {item.testId ? (
                                                <TestIcon
                                                    className={`flex-shrink-0 ` + ((progress.lessons[index].id == lesson.id) ? "fill-[#F21450]" : "fill-[#BDBDBD]")}/>
                                            ) : (
                                                <LessonIcon
                                                    className={`flex-shrink-0 ` + ((progress.lessons[index].id == lesson.id) ? "fill-[#F21450]" : "fill-[#BDBDBD]")}/>
                                            )}
                                            {item.name}
                                        </>
                                    )}
                                </div>
                            ))}
                        </>
                    )}
                </ScrollContainer>
            </div>
            {preview && lessons && (
                <Link
                    to={urlNextLesson}
                    className="w-full px-[16px] py-[8px] text-white text-[16px] font-medium bg-[#17AB57] rounded-[5px] text-center">
                    Следующий урок
                </Link>
            )}
            {!preview && progress && (
                <div
                    onClick={handleNextLesson}
                    className="w-full px-[16px] py-[8px] text-white text-[16px] font-medium bg-[#17AB57] rounded-[5px] text-center cursor-pointer">
                    {progress.lessons[progress.lessons.length - 1].id == lesson.id ? ("Завершить курс") : ("Следующий урок")}
                </div>
            )}
        </div>
    )
}

export default ListLessonInLesson