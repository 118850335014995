import {IGetVisitsUser, IUser} from "../../../../../../../models/users";
import {
    Chart as ChartJS,
    CategoryScale,
    LinearScale,
    PointElement,
    LineElement,
    Title,
    Tooltip,
    Legend,
} from 'chart.js';
import {Line} from "react-chartjs-2";
import {useMutation} from "react-query";
import {UsersService} from "../../../../../../../services/users.service";
import DatePiker from 'react-datepicker'
import 'react-datepicker/dist/react-datepicker.css'
import {useState} from "react";
import dayjs from "dayjs";
import Loader from "../../../../../../ui/loader/Loader";

ChartJS.register(
    CategoryScale,
    LinearScale,
    PointElement,
    LineElement,
    Title,
    Tooltip,
    Legend
);

interface IUserStatistics {
    user: IUser
}

const UserStatistics = (
    {
        user
    }: IUserStatistics
) => {

    const [show, setShow] = useState<boolean>(false)
    const [labels, setLabels] = useState<string[]>([])
    const [datasets, setDatasets] = useState<number[]>([])

    let dataChart = {
        labels,
        datasets: [
            {
                label: 'Время на сайте',
                data: datasets,
                borderColor: 'rgb(23, 171, 87)',
                backgroundColor: 'rgba(23, 171, 87, 0.5)',
                fill: true,
                cubicInterpolationMode: 'monotone' as const
            }
        ],
    };


    const visits = useMutation(
        (data: IGetVisitsUser) => UsersService.getVisits(data),
        {
            onSuccess: (response) => {
                response.map((item) => {
                    setLabels(prev => [...prev, dayjs(item.date).format("DD.MM.YYYY")])
                    setDatasets(prev => [...prev, item.minutes])
                })
                dataChart = {
                    labels,
                    datasets: [
                        {
                            label: 'Время на сайте',
                            data: datasets,
                            borderColor: 'rgb(23, 171, 87)',
                            backgroundColor: 'rgba(23, 171, 87, 0.5)',
                            fill: true,
                            cubicInterpolationMode: 'monotone' as const
                        }
                    ],
                };
                setShow(false)
            }
        }
    )

    const options = {
        responsive: true,
    };

    const [startDate, setStartDate] = useState<Date | null>(new Date())
    const [finishDate, setFinishDate] = useState<Date | null>(new Date())

    const handleChangeDatepicker = (date: [(Date | null), (Date | null)]) => {
        const [start, finish] = date

        setStartDate(start)
        setFinishDate(finish)
        if (start && finish) {
            setShow(true)
            setLabels([])
            setDatasets([])
            const startCopy = dayjs(start).hour(5).minute(0).second(0).toDate();
            const finishCopy = dayjs(finish).hour(5).minute(0).second(0).add(1, 'day').toDate();

            const dataSubmit: IGetVisitsUser = {
                userId: user.id,
                start: startCopy,
                finish: finishCopy
            }
            visits.mutate(dataSubmit)
        }
    }

    return (
        <>
            {show && <Loader/>}
            <div className="w-full h-full flex gap-[12px]">
                <DatePiker
                    onChange={handleChangeDatepicker}
                    selected={startDate}
                    startDate={startDate}
                    endDate={finishDate}
                    selectsRange
                    className="border-[#BDBDBD] border-[1px] border-solid px-[8px] py-[12px] rounded-[6px]"
                />
                {labels.length > 0 && datasets.length > 0 && (
                    <Line data={dataChart} options={options}/>
                )}
            </div>
        </>
    )
}

export default UserStatistics