import {ReactComponent as SearchIcon} from "../../../assets/images/global/searcg-icon.svg";
import React, {ReactNode, Ref, useEffect, useRef, useState} from "react";

interface ISearchInput {
    children: ReactNode
    searchTerm: string
    setSearchTerm: (term: string) => void
    showSearchResult: boolean
    setShowSearchResult: (show: boolean) => void
    refSearch: Ref<HTMLInputElement>
}

const SearchInput = (
    {
        children,
        searchTerm,
        setSearchTerm,
        showSearchResult,
        setShowSearchResult,
        refSearch
    }: ISearchInput
) => {

    const [blurSearch, setBlurSearch] = useState<boolean>(false)
    const ref = useRef<HTMLDivElement>(null)

    const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        setSearchTerm(e.target.value)
    }

    const handleFocus = (e: React.FocusEvent<HTMLInputElement>) => {
        if (e.target.value) {
            setShowSearchResult(true)
        }
        setBlurSearch(true)
    }

    useEffect(() => {
        if (searchTerm) {
            setShowSearchResult(true)
        } else {
            setShowSearchResult(false)
        }
    }, [searchTerm])

    useEffect(() => {
        if (!showSearchResult) return
        handleClickRef(ref)
    }, [showSearchResult])

    const handleClickRef = (ref: React.RefObject<HTMLDivElement>) => {
        const handleClick = (e: MouseEvent) => {
            let element = e.target as HTMLDivElement
            if (!ref.current) return
            if (!ref.current.contains(element)) {
                setShowSearchResult(false)
            }
        }

        document.addEventListener('click', handleClick)
        return () => {
            document.removeEventListener('click', handleClick)
        }
    }

    return (
        <div ref={ref} className="relative w-full">
            <SearchIcon className={`absolute top-[8px] left-[10px] ` + (blurSearch ? "fill-[#292B2C]" : "")}/>
            <input
                ref={refSearch}
                onChange={handleChange}
                onFocus={handleFocus}
                onBlur={() => {
                    setBlurSearch(false)
                }}
                type="text"
                placeholder="Поиск"
                className="py-[8px] pl-[36px] pr-[12px] border-solid border-[1px] border-[#BDBDBD] rounded-[5px] bg-[#F2F2F2] w-full focus:border-[#17AB57] focus:outline-none"/>
            <div
                className={`absolute top-[45px] flex flex-col max-h-[220px] bg-white rounded-[6px] w-full shadow-[0_5px_10px_0_rgba(0,0,0,0.37)] z-[100]` + (showSearchResult ? " block" : " hidden")}>
                {children}
            </div>
        </div>
    )
}

export default SearchInput