import {Link, useNavigate, useParams} from "react-router-dom";
import ScrollContainer from "../../../../ui/scroll-container/ScrollContainer";
import React, {useRef, useState} from "react";
import {ReactComponent as ArrowBack} from "../../../../../assets/images/global/arrow-back-icon.svg";
import {ReactComponent as SearchIcon} from "../../../../../assets/images/global/searcg-icon.svg";
import {ReactComponent as RefreshIcon} from "../../../../../assets/images/global/refresh.svg";
import {ReactComponent as CheckboxUnchecked} from "../../../../../assets/images/tests/checkbox-unchecked.svg";
import {ReactComponent as CheckboxChecked} from "../../../../../assets/images/tests/checkbox-checked.svg";
import Loader from "../../../../ui/loader/Loader";
import {useMutation, useQuery} from "react-query";
import {LessonsService} from "../../../../../services/lessons.service";
import {AxiosError} from "axios";
import ErrorMessage from "../../../../ui/error/ErrorMessage";
import {
    ILessonsAttachWithoutUser,
    ILessonsAttachInUser, ILessonsHoursForAttach,
} from "../../../../../models/lessons";
import UsersAttachLessonsItemInactive from "./users-attach-lessons-item-inactive/UsersAttachLessonsItemInactive";
import UserAttachLessonsItemActive from "./users-attach-lessons-item-active/UsersAttachLessonsItemActive";
import {FieldArrayWithId, FormProvider, useFieldArray, useForm} from "react-hook-form";
import {DragDropContext, Draggable, Droppable} from "react-beautiful-dnd";
import {UsersService} from "../../../../../services/users.service";
import {CoursesService} from "../../../../../services/courses.service";
import {GroupsService} from "../../../../../services/groups.service";
import {IGroupAttachCourse} from "../../../../../models/groups";

const UsersAttachLessons = () => {

    const methods = useForm<ILessonsAttachInUser>()
    const {
        control,
        setValue,
        handleSubmit,
        register,
        formState: {errors}
    } = methods

    const {
        fields, append, remove
    } = useFieldArray({
        name: "lessons",
        control
    })

    const {id, courseId, groupId} = useParams()
    const navigate = useNavigate()

    const refSubmitButton = useRef<HTMLButtonElement>(null)
    const [blurSearch, setBlurSearch] = useState<boolean>(false)
    const [searchTerm, setSearchTerm] = useState<string>("")
    const [inactiveListLesson, setInactiveListLesson] = useState<ILessonsHoursForAttach[]>([])
    const [showInactiveLessons, setShowInactiveLessons] = useState<boolean>(true)
    const [drag, setDrag] = useState<boolean>(false)
    const [showLoader, setShowLoader] = useState<boolean>(false)
    const [error, setError] = useState<number | null>(null)

    const lessons = useQuery(
        ["list_lessons", courseId],
        () =>(id && window.location.host.indexOf("fedkurs") === -1) ?
            LessonsService.getListByCourseForAttach(Number(courseId), Number(id)) : LessonsService.getListByCourseForAddInFedkurs(Number(courseId)),
        {
            enabled: !!courseId,
            onSuccess: (response) => {
                if(window.location.host.indexOf("fedkurs") === -1) {
                    setValue("userId", Number(id))
                }
                setValue("courseId", Number(courseId))
                response.lessons.map((lesson) => {
                    if (lesson.isUser) {
                        append({
                            id: lesson.id,
                            lessonId: lesson.lessonId,
                            order: lesson.order,
                            hours: lesson.hours,
                            name: lesson.name,
                            testId: lesson.testId,
                            isUser: lesson.isUser,
                            isBase: lesson.isBase
                        })
                    } else {
                        setInactiveListLesson(prev => [...prev, lesson]);
                    }
                })
            }
        }
    )

    const handleSearch = (e: React.ChangeEvent<HTMLInputElement>) => {
        setSearchTerm(e.target.value)
    }

    const handleActiveAll = () => {
        inactiveListLesson.map((lesson, index) => {
            append({
                id: lesson.id,
                lessonId: lesson.lessonId,
                name: lesson.name,
                testId: lesson.testId,
                isUser: lesson.isUser,
                order: index + 1,
                isBase: lesson.isBase
            })
        })
        setInactiveListLesson([])
    }

    const handleBaseCourse = () => {
        if(lessons.data) {
            setInactiveListLesson([])
            remove()
            lessons.data.lessons
                .filter(lesson => lesson.isBase)
                .sort((a, b) => (a.order && b.order) ? (a.order < b.order ? -1 : 1) : 1)
                .map((lesson, index) => {
                    append({
                        id: lesson.id,
                        lessonId: lesson.lessonId,
                        name: lesson.name,
                        testId: lesson.testId,
                        isUser: lesson.isUser,
                        order: index + 1,
                        isBase: lesson.isBase
                    })
                })
            setInactiveListLesson(lessons.data.lessons.filter(lesson => !lesson.isBase))
        }
    }

    const handleRefresh = () => {
        setInactiveListLesson([])
        remove()
        lessons.data?.lessons.map((lesson) => {
            setInactiveListLesson(prev => [...prev, lesson]);
        })
    }

    const toggleLessonActive = (lessonId: number, event: React.ChangeEvent<HTMLInputElement>, index?: number) => {
        if (lessons.data) {
            let lesson = lessons.data.lessons.filter(lesson => lesson.lessonId === lessonId)[0]
            if (lesson) {
                if (event.target.checked) {
                    append({
                        id: lesson.id,
                        lessonId: lesson.lessonId,
                        name: lesson.name,
                        testId: lesson.testId,
                        isUser: lesson.isUser,
                        order: fields.length + 1,
                        isBase: lesson.isBase
                    })
                    setInactiveListLesson(inactiveListLesson.filter(lessonInactive => lessonInactive !== lesson))
                } else {
                    setInactiveListLesson(prev => [lesson, ...prev])
                    remove(index)
                }
            }
        }
    }

    const attachUser = useMutation(
        (data: ILessonsAttachInUser) => UsersService.attachUserInCourseWithLessons(data),
        {
            onSuccess: (response) => {
                setShowLoader(false)
                navigate(`/users/${id}?event=true`)
            },
            onError: (error) => {
                const e = error as AxiosError
                setError(e.response ? e.response.status : 400)
                setShowLoader(false)
            }
        }
    )

    const addCourseInFedkurs = useMutation(
        (data: ILessonsAttachWithoutUser) => CoursesService.addCourseInFedkurs(data),
        {
            onSuccess: (response) => {
                setShowLoader(false)
                navigate(`/folders/courses/${response.id}`)
            },
            onError: (error) => {
                const e = error as AxiosError
                setError(e.response ? e.response.status : 400)
                setShowLoader(false)
            }
        }
    )

    const attachGroupToCourse = useMutation(
        (data: IGroupAttachCourse) => GroupsService.updateUserCourse(data),
        {
            onSuccess: (response) => {
                setShowLoader(false)
                navigate(`/groups/${response.id}`)
            },
            onError: (error) => {
                const e = error as AxiosError
                setError(e.response ? e.response.status : 400)
                setShowLoader(false)
            }
        }
    )

    const onSubmit = (data: ILessonsAttachInUser) => {
        setShowLoader(true)
        if(groupId) {
            const dataSubmit: IGroupAttachCourse = {
                courseId: data.courseId,
                groupId: Number(groupId),
                lessons: data.lessons
            }
            attachGroupToCourse.mutate(dataSubmit)
        } else if(window.location.host.indexOf("fedkurs") === -1) {
            attachUser.mutate(data)
        } else {
            const dataSubmit: ILessonsAttachWithoutUser = {
                courseId: data.courseId,
                lessons: data.lessons
            }
            addCourseInFedkurs.mutate(dataSubmit)
        }
    };

    const handeSubmitRef = () => {
        if (refSubmitButton.current) {
            refSubmitButton.current.click()
        }
    }

    function onDragStart(result: any) {
        setDrag(true)
    }

    function onDragEnd(result: any) {
        if (!result.destination) {
            return;
        }

        const items = reorder(
            fields,
            result.source.index,
            result.destination.index
        );

        remove()

        items.forEach((item, index) => {
            append({
                id: item.id,
                lessonId: item.lessonId,
                order: index + 1,
                hours: item.hours,
                name: item.name,
                testId: item.testId,
                isUser: item.isUser,
                isBase: item.isBase
            })
        })

        setDrag(false)
    }

    const reorder = (list: FieldArrayWithId<ILessonsAttachInUser, "lessons">[], startIndex: number, endIndex: number) => {
        const [removed] = list.splice(startIndex, 1);
        list.splice(endIndex, 0, removed);

        return list;
    };

    if (lessons.isLoading) return <Loader/>

    if (lessons.isError) {
        const e = lessons.error as AxiosError
        return <ErrorMessage numberError={e.response ? e.response.status : 400}/>
    }

    if (!lessons.data) {
        return <ErrorMessage numberError={400}/>
    }

    return (
        <div className="flex flex-col gap-[24px] h-full">
            <div className="flex justify-between items-center">
                <div className="text-[36px] leading-[120%] text-[#292B2C] font-bold">
                    {lessons.data.course}
                </div>
                <div className="flex items-center gap-[12px]">
                    <Link
                        to={groupId ? `/groups/${groupId}` : window.location.host.indexOf("fedkurs") === -1 ? `/users/${id}?event=true` : `/folders`}
                        className="px-[16px] py-[12px] flex gap-[8px] items-center border-[1px] border-[#17AB57] border-solid rounded-[5px]">
                        <ArrowBack className="flex-shrink-0"/>
                        Назад
                    </Link>
                </div>
            </div>
            <div className="w-full flex gap-[12px] items-center text-[16px] max-lg:gap-[12px]">
                <div className="relative w-full">
                    <SearchIcon className={`absolute top-[8px] left-[10px] ` + (blurSearch ? "fill-[#292B2C]" : "")}/>
                    <input
                        onFocus={() => {
                            setBlurSearch(true)
                        }}
                        onBlur={() => {
                            setBlurSearch(false)
                        }}
                        onChange={handleSearch}
                        type="text"
                        placeholder="Поиск"
                        className="py-[8px] pl-[36px] pr-[12px] border-solid border-[1px] border-[#BDBDBD] rounded-[5px] bg-[#F2F2F2] w-full focus:border-[#17AB57] focus:outline-none"/>
                </div>
            </div>
            <div
                className="bg-white p-[32px] rounded-[6px] flex flex-col gap-[15px] text-[16px] font-medium max-lg:px-[14px] max-lg:py-[14px] max-lg:gap-0 overflow-hidden h-full">
                {error && (<ErrorMessage numberError={error}/>)}
                {showLoader && (<Loader/>)}
                <div
                    className="flex items-center justify-between max-xl:flex-col max-xl:gap-[12px] max-xl:items-start">
                    <div className="font-bold text-[#292B2C] text-[24px] leading-[120%]">
                        Активно уроков: {fields.length} (доступно {lessons.data?.lessons.length})
                    </div>
                    <div className="flex items-center gap-[16px] max-xl:gap-[10px]">
                        <div className="">
                            <label className="flex items-center gap-[6px] cursor-pointer w-full">
                                <input onChange={() => setShowInactiveLessons(!showInactiveLessons)}
                                       className="hidden peer" type="checkbox"/>
                                <CheckboxUnchecked className="block peer-checked:hidden flex-shrink-0"/>
                                <CheckboxChecked className="hidden peer-checked:block flex-shrink-0"/>
                                Скрыть неактивные
                            </label>
                        </div>
                        <div
                            onClick={handleActiveAll}
                            className="border-solid border-[1px] border-[#17AB57] cursor-pointer gap-[8px] py-[8px] px-[16px] rounded-[5px]">
                            Активировать все
                        </div>
                        <div
                            onClick={handleBaseCourse}
                            className="border-solid border-[1px] border-[#17AB57] cursor-pointer gap-[8px] py-[8px] px-[16px] rounded-[5px]">
                            Базовый курс
                        </div>
                        <div
                            onClick={handleRefresh}
                            className="flex items-center justify-center w-[35px] h-[35px] rounded-[5px] bg-[#17AB57] cursor-pointer flex-shrink-0">
                            <RefreshIcon/>
                        </div>
                        <button
                            onClick={handeSubmitRef}
                            className="py-[8px] px-[16px] bg-[#17AB57] rounded-[5px] text-white text-[16px] font-medium">
                            Сохранить
                        </button>
                    </div>
                </div>
                <ScrollContainer>
                    <div className="h-full flex flex-col gap-[24px]">
                        {searchTerm !== "" && fields.filter(field => field.name.toLowerCase().includes(searchTerm.toLowerCase())).length === 0 &&
                        inactiveListLesson.filter(lesson => lesson.name.toLowerCase().includes(searchTerm.toLowerCase())).length === 0 ? (
                                <div className="flex justify-center">
                                    По вашему запросу ничего не найдено
                                </div>
                        ) : (
                            <>
                                <FormProvider {...methods}>
                                    <form onSubmit={handleSubmit(onSubmit)}>
                                        {window.location.host.indexOf("fedkurs") === -1 && (
                                            <input type="hidden" {...register("userId", {required: true})}/>
                                        )}
                                        <input type="hidden" {...register("courseId", {required: true})}/>
                                        <div className={`h-full ` + (drag ? " mb-[90px]" : "")}>
                                            <DragDropContext onDragStart={onDragStart} onDragEnd={onDragEnd}>
                                                <Droppable droppableId="droppable">
                                                    {(provided) => (
                                                        <div ref={provided.innerRef} {...provided.droppableProps}>
                                                            {fields
                                                                .filter(field => field.name.toLowerCase().includes(searchTerm.toLowerCase()))
                                                                .map((item, index) => (
                                                                    <>
                                                                        <Draggable draggableId={String(index)} key={item.id}
                                                                                   index={index}>
                                                                            {(provided) => (
                                                                                <div
                                                                                    className="border-solid border-b-[2px] border-[#BDBDBD] last:border-none"
                                                                                    ref={provided.innerRef}
                                                                                    {...provided.draggableProps}
                                                                                    {...provided.dragHandleProps}
                                                                                >
                                                                                    <UserAttachLessonsItemActive
                                                                                        toggleLessonActive={toggleLessonActive}
                                                                                        item={item}
                                                                                        index={index}/>
                                                                                </div>
                                                                            )}
                                                                        </Draggable>
                                                                    </>
                                                                ))}
                                                        </div>
                                                    )}
                                                </Droppable>
                                            </DragDropContext>
                                        </div>
                                        <button ref={refSubmitButton} type="submit" className="hidden"></button>
                                    </form>
                                </FormProvider>
                                {showInactiveLessons && (
                                    <div className="flex flex-col gap-[8px]">
                                        <div className="text-[#BDBDBD] font-bold text-[16px] leading-[120%]">Неактивные
                                            уроки
                                        </div>
                                        <div className="">
                                            {inactiveListLesson
                                                .filter(lesson => lesson.name.toLowerCase().includes(searchTerm.toLowerCase()))
                                                .map((lesson, index) => (
                                                    <UsersAttachLessonsItemInactive
                                                        key={index}
                                                        lesson={lesson}
                                                        toggleLessonActive={toggleLessonActive}
                                                    />
                                                ))}
                                        </div>
                                    </div>
                                )}
                            </>
                        )}
                    </div>
                </ScrollContainer>
            </div>
        </div>
    )
}

export default UsersAttachLessons