import axios from "axios";
import {ICourses, ICoursesDetail, ICoursesDetailId, ICourseSearch, ICoursesFolder} from "../models/courses";
import AuthHeader from "./auth-header";
import {IUser, IUserCourse} from "../models/users";
import {IFolderSearchResult} from "../models/folders";
import {ILessonsAttachWithoutUser} from "../models/lessons";
import {Constants} from "../constans/constants";

export const CoursesService = {
    async getAll(id: string) {
        const response = await axios.get<ICourses[]>(Constants.LOCATION + `/api/Course/folder/${id}`, {headers: AuthHeader()})
        return response.data
    },

    async getAllForFedkurs(id: number) {
        const response = await axios.get<ICourses[]>(Constants.LOCATION + `/api/Course/folderForFedkurs/${id}`, {headers: AuthHeader()})
        return response.data
    },

    async getById(id: string) {
        const response = await axios.get<ICourses>(Constants.LOCATION + `/api/Course/${id}`, {headers: AuthHeader()})
        return response.data
    },

    async create(data: ICoursesDetail) {
        return await axios.post<ICourses>(Constants.LOCATION + '/api/Course/add', data, {headers: AuthHeader()})
    },

    async update(id: number, data: ICoursesDetail) {
        return await axios.post<ICourses>(Constants.LOCATION + `/api/Course/update/${id}`, data, {headers: AuthHeader()})
    },

    async delete(data: ICoursesDetailId) {
        return await axios.post<boolean>(Constants.LOCATION + '/api/Course/delete/', data, {headers: AuthHeader()})
    },

    async copy(data: ICoursesDetailId) {
        return await axios.post<ICourses>(Constants.LOCATION + '/api/Course/copy/', data, {headers: AuthHeader()})
    },

    async move(data: ICoursesFolder){
        return await axios.post<ICourses>(Constants.LOCATION + '/api/Course/move/', data, {headers: AuthHeader()})
    },

    async attachUser(data: IUserCourse) {
        return await axios.post(Constants.LOCATION + '/api/Course/attach', data, {headers: AuthHeader()})
    },

    async getUsers(id: number) {
        const { data } = await axios.get<IUser[]>(Constants.LOCATION + `/api/Course/users/${id}`, {headers: AuthHeader()})
        return data
    },

    async deleteUser(dataDeleteUser: IUserCourse) {
        const { data } = await axios.post<boolean>(Constants.LOCATION + '/api/Course/deleteUser', dataDeleteUser, {headers: AuthHeader()})
        return data
    },

    async deleteAllUser(data: ICoursesDetailId) {
        return await axios.post<boolean>(Constants.LOCATION + '/api/Course/deleteAllUser', data, {headers: AuthHeader()})
    },

    async search(dataSearch: ICourseSearch) {
        const {data} = await axios.post<IFolderSearchResult[]>(Constants.LOCATION + `/api/Course/search`, dataSearch, {headers: AuthHeader()})
        return data
    },

    async unloadingCourseFromFedkurs() {
        const {data} = await axios.get(Constants.LOCATION + `/api/Course/unloadingCourse`, {headers: AuthHeader()})
        return data
    },

    async addCourseInFedkurs(dataSubmit: ILessonsAttachWithoutUser) {
        const {data} = await axios.post<ICourses>(Constants.LOCATION + `/api/Course/createCourseInFedkurs/`, dataSubmit, {headers: AuthHeader()})
        return data
    }
}