import {IUser, IUserCourse} from "../../../../../../../../models/users";
import {ReactComponent as UsersIcon} from "../../../../../../../../assets/images/menu/users-icon.svg";
import {ReactComponent as DeleteUser} from "../../../../../../../../assets/images/global/close-modal-icon.svg";
import React from "react";
import {useMutation, useQueryClient} from "react-query";
import {CoursesService} from "../../../../../../../../services/courses.service";
import {Link} from "react-router-dom";

interface ICourseUserListItem {
    user: IUser
    courseId: number
}

const CourseUserListItem = (
    {
        user,
        courseId
    }: ICourseUserListItem
) => {

    const queryClient = useQueryClient()

    const deleteUser = useMutation(
        (data: IUserCourse) => CoursesService.deleteUser(data),
        {
            onSuccess: () =>{
                queryClient.invalidateQueries(["users_course"])
                queryClient.invalidateQueries(['list_lessons_course', Number(courseId)])
            }
        }
    )

    const handleDelete = () => {
        const data: IUserCourse = {
            "userId": user.id,
            "courseId": courseId
        }
        deleteUser.mutate(data)
    }

    return (
        <div
            className="flex justify-between border-solid border-b-[2px] border-[#BDBDBD] last:border-none py-[16px] last:pb-0 first:pt-0 items-center">
            <div className="flex items-center gap-[8px] leading-[120%] text-[16px] text-[#292B2C] font-medium">
                <UsersIcon fill="#17AB57"/>
                <Link to={`/users/${user.id}`} className="max-lg:flex max-lg:flex-col max-lg:justify-start">{user.lastName} {user.firstName} {user.fatherName}<span className="text-[#BDBDBD]">({user.email})</span></Link>
            </div>
            <div
                onClick={handleDelete}
                className="cursor-pointer rounded-[5px] flex gap-[6px] py-[8px] pl-[12px] pr-[16px] border-solid border-[1px] border-[#17AB57] items-center text-[16px] text-[#292B2C] max-lg:pr-[12px]">
                <DeleteUser className="flex-shrink-0"/>
                <div className="max-lg:hidden">Удалить</div>
            </div>
        </div>
    )
}

export default CourseUserListItem