import axios from "axios";
import {IFolder, IFolderDetail, IFolderDetailId, IFolderSearch, IFolderSearchResult} from "../models/folders";
import AuthHeader from "./auth-header";
import {Constants} from "../constans/constants";

export const FolderService = {
    async getById(id: string | undefined) {
        const response = await axios.get<IFolder[]>(Constants.LOCATION + `/api/Folder/${id}`, {headers: AuthHeader()})
        return response.data
    },

    async getParentFolder(id: string) {
        const response = await axios.get<IFolder>(Constants.LOCATION + `/api/Folder/parent/${id}`, {headers: AuthHeader()})
        return response.data
    },

    async create(data: IFolder) {
        return await axios.post<IFolder>(Constants.LOCATION + `/api/Folder/add`, data, {headers: AuthHeader()})
    },

    async getList() {
        const {data} = await axios.get<[IFolder[]]>(Constants.LOCATION + `/api/Folder/move`, {headers: AuthHeader()})
        return data
    },

    async delete(data: IFolderDetailId) {
        return await axios.post<boolean>(Constants.LOCATION + `/api/Folder/delete/`, data, {headers: AuthHeader()})
    },

    async update(id: string, data: IFolderDetail) {
        return await axios.post<IFolder>(Constants.LOCATION + `/api/Folder/update/${id}`, data, {headers: AuthHeader()})
    },

    async search(dataSearch: IFolderSearch) {
        const {data} = await axios.post<IFolderSearchResult[]>(Constants.LOCATION + `/api/Folder/search`, dataSearch, {headers: AuthHeader()})
        return data
    }
}