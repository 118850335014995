import {IUserCourses} from "../../../../../models/users";
import {Link} from "react-router-dom";
import {buildStyles, CircularProgressbarWithChildren} from "react-circular-progressbar";
import React from "react";
import {ReactComponent as ContinueCourse} from "../../../../../assets/images/users/continue.svg";
import {ReactComponent as StartCourse} from "../../../../../assets/images/users/start.svg";
import {ReactComponent as ReloadCourse} from "../../../../../assets/images/users/reload.svg";

interface IStudentCoursesItem {
    course: IUserCourses
}

const StudentCoursesItem = (
    {
        course
    }: IStudentCoursesItem
) => {
    return (
        <div className="py-[32px] px-[48px] max-lg:p-[14px] max-lg:gap-[10px] flex justify-between border-[1px] rounded-[6px] border-[#BDBDBD] border-solid items-center">
            <div className="flex flex-col gap-[16px] max-lg:gap-[36px]">
                <div
                    className="text-[#292B2C] text-[32px] font-bold leading-[120%] max-lg:text-[14px]">{course.course.name}</div>
                {course.isBlocked ? (
                    <div className="flex text-[#fff]">
                        {course.countLessons == course.countLessonsCompleted ? (
                            <div
                                className="bg-[#c1bcbc] border-[#c1bcbc] border-[1px] border-solid rounded-[5px] pl-[12px] pr-[16px] py-[8px] max-lg:pl-[10px] max-lg:pr-[14px] max-lg:py-[4px] text-[16px] gap-[6px] flex items-center">
                                <ReloadCourse className="flex-shrink-0" fill="#fff"/>
                                Просмотреть <span className="max-lg:hidden">материал</span>
                            </div>
                        ) : (
                            <>
                                {course.countLessonsCompleted == 0 ? (
                                    <div
                                        className="bg-[#c1bcbc] rounded-[5px] pl-[12px] pr-[16px] py-[8px] max-lg:pl-[10px] max-lg:pr-[14px] max-lg:py-[4px] text-white text-[16px] gap-[6px] flex items-center">
                                        <StartCourse className="flex-shrink-0"/>
                                        Начать <span className="max-lg:hidden">изучение</span>
                                    </div>
                                ) : (
                                    <div
                                        className="bg-[#c1bcbc] rounded-[5px] pl-[12px] pr-[16px] py-[8px] max-lg:pl-[10px] max-lg:pr-[14px] max-lg:py-[4px] text-white text-[16px] gap-[6px] flex items-center">
                                        <ContinueCourse className="flex-shrink-0"/>
                                        Продолжить <span className="max-lg:hidden">изучение</span>
                                    </div>
                                )}
                            </>
                        )}
                    </div>
                ) : (
                    <Link to={`/student-courses/${course.course.id}`} className="flex">
                        {course.countLessons == course.countLessonsCompleted ? (
                            <div
                                className="border-[#17AB57] border-[1px] border-solid rounded-[5px] pl-[12px] pr-[16px] py-[8px] max-lg:pl-[10px] max-lg:pr-[14px] max-lg:py-[4px] text-[16px] gap-[6px] flex items-center">
                                <ReloadCourse className="flex-shrink-0"/>
                                Просмотреть <span className="max-lg:hidden">материал</span>
                            </div>
                        ) : (
                            <>
                                {course.countLessonsCompleted == 0 ? (
                                    <div
                                        className="bg-[#17AB57] rounded-[5px] pl-[12px] pr-[16px] py-[8px] max-lg:pl-[10px] max-lg:pr-[14px] max-lg:py-[4px] text-white text-[16px] gap-[6px] flex items-center">
                                        <StartCourse className="flex-shrink-0"/>
                                        Начать <span className="max-lg:hidden">изучение</span>
                                    </div>
                                ) : (
                                    <div
                                        className="bg-[#17AB57] rounded-[5px] pl-[12px] pr-[16px] py-[8px] max-lg:pl-[10px] max-lg:pr-[14px] max-lg:py-[4px] text-white text-[16px] gap-[6px] flex items-center">
                                        <ContinueCourse className="flex-shrink-0"/>
                                        Продолжить <span className="max-lg:hidden">изучение</span>
                                    </div>
                                )}
                            </>
                        )}
                    </Link>
                )}
            </div>
            <div className="w-[130px] h-[130px] max-lg:w-[81px] max-lg:h-[81px] flex-shrink-0">
                <CircularProgressbarWithChildren
                    value={Math.round((course.countLessonsCompleted / course.countLessons) * 100)}
                    counterClockwise={true}
                    styles={buildStyles({
                        pathColor: `#17AB57`,
                        trailColor: '#BDBDBD',
                    })}
                >
                    <div className="flex flex-col justify-center text-[#292B2C] text-center">
                        {course.countLessonsCompleted == 0 ? (
                            <div className="text-[16px] font-medium max-lg:text-[9.969px]">Не
                                начат</div>
                        ) : (
                            <>
                                <div
                                    className="text-[24px] font-bold max-lg:text-[14.954px]">{course.countLessonsCompleted}/{course.countLessons}</div>
                                <div
                                    className="text-[16px] font-medium max-lg:text-[9.969px]">выполнено
                                </div>
                            </>
                        )}
                    </div>
                </CircularProgressbarWithChildren>
            </div>
        </div>
    )
}

export default StudentCoursesItem