import {useQuery} from "react-query";
import {FolderService} from "../../../../services/folder.service";
import React from "react";
import SearchFoldersItem from "./search-folders-item/SearchFoldersItem";
import {CoursesService} from "../../../../services/courses.service";
import SearchCoursesItem from "./search-courses-item/SearchCoursesItem";
import ScrollContainer from "../../scroll-container/ScrollContainer";

interface ISearchFolders {
    searchTerm: string
    setShowSearchResult: (show: boolean) => void
    onClearInput: () => void
    setSearchTerm: (name: string) => void
}

const SearchFolders = (
    {
        searchTerm,
        setShowSearchResult,
        onClearInput,
        setSearchTerm
    }: ISearchFolders
) => {

    const folders = useQuery(
        ["search_folders", searchTerm],
        () => FolderService.search({"name": searchTerm}),
        {
            enabled: !!searchTerm
        }
    )

    const courses = useQuery(
        ["search_courses", searchTerm],
        () => CoursesService.search({"name": searchTerm}),
        {
            enabled: !!searchTerm
        }
    )

    return (
        <>
            {folders.isLoading && courses.isLoading && (<div className="text-center">Загрузка</div>)}
            {(folders.data || courses.data) ? (
                <>
                    <div className="flex pt-[16px] px-[18px] mb-[10px] w-full text-[16px] text-[#828282] font-medium">
                        <div className="w-[50%] max-lg:w-full">Найдено
                            результатов: {(folders.data ? folders.data.length : 0) + (courses.data ? courses.data.length : 0)}</div>
                        <div className="w-[50%] max-lg:hidden">Путь</div>
                    </div>
                    <ScrollContainer chat={false} heightModal={"220px"}>
                        {folders.data && (
                            <>
                                {folders.data.map((folder, index) => (
                                    <SearchFoldersItem
                                        setSearchTerm={setSearchTerm}
                                        onClearInput={onClearInput}
                                        setShowSearchResult={setShowSearchResult}
                                        folder={folder}
                                        key={index}/>
                                ))}
                            </>
                        )}
                        {courses.data && (
                            <>
                                {courses.data.map((course, index) => (
                                    <SearchCoursesItem
                                        setSearchTerm={setSearchTerm}
                                        onClearInput={onClearInput}
                                        setShowSearchResult={setShowSearchResult}
                                        course={course}
                                        key={index}/>
                                ))}
                            </>
                        )}
                    </ScrollContainer>
                </>
            ) : (
                <div className="text-center p-[16px]">Ничего не найдено по запросу "{searchTerm}"</div>
            )}
        </>
    )
}

export default SearchFolders