import {IUser} from "../../../../../models/users";
import React from "react";
import {ReactComponent as UsersIcon} from "../../../../../assets/images/menu/users-icon.svg";
import {Link, useLocation} from "react-router-dom";

interface IUsersItem {
    user: IUser
    group?: boolean
}

const UsersItem = (
    {
        user,
        group
    }: IUsersItem
) => {

    const {pathname} = useLocation()

    const switchRole = (id: number) => {
        switch (id) {
            case 1: return "А";
            case 2: return "М";
            case 3: return "Р";
            default: return "У";
        }
    }

    return (
        <div className="text-[#292B2C] items-center flex max-lg:flex-col max-lg:gap-[10px] text-[16px] font-medium leading-[120%] border-solid border-b-[2px] border-[#BDBDBD] last:border-none py-[16px] first:pt-0 last:pb-0">
            <div className="flex w-[70%] max-lg:w-full justify-between">
                <div className="flex items-center gap-[8px]">
                    <UsersIcon className="flex-shrink-0" fill="#17AB57"/>
                    <div className="max-lg:flex-col max-lg:flex">
                        {user.lastName} {user.firstName} {user.fatherName} <span className="text-[#BDBDBD]">({user.email})</span>
                    </div>
                </div>
                <div className="flex justify-start">
                    <div className="w-[35px] h-[35px] bg-[#17AB57] flex items-center justify-center text-white">
                        {switchRole(user.role.id)}
                    </div>
                </div>
            </div>
            <div className="w-[30%] max-lg:w-full flex justify-end">
                <Link to={`/users/${user.id}`} state={{ group: !!group, pathname: pathname }} className="inline-block max-lg:w-full rounded-[5px] border-solid border-[1px] border-[#17AB57] py-[8px] px-[16px]">Открыть</Link>
            </div>
        </div>
    )
}

export default UsersItem