import Modal from "../Modal";
import ErrorMessage from "../../error/ErrorMessage";
import {ReactComponent as CloseIcon} from "../../../../assets/images/global/close-modal-icon.svg";
import {ReactComponent as SearchIcon} from "../../../../assets/images/global/searcg-icon.svg";
import {ReactComponent as UserIcon} from "../../../../assets/images/menu/users-icon.svg";
import Portal from "../portal/Portal";
import React, {useContext, useState} from "react";
import {IUser} from "../../../../models/users";
import {IGroup, IGroupUser} from "../../../../models/groups";
import {useMutation, useQuery, useQueryClient} from "react-query";
import {UsersService} from "../../../../services/users.service";
import {GroupsService} from "../../../../services/groups.service";
import {LoaderContext} from "../../../../context/LoaderContext";
import Loader from "../../loader/Loader";
import ScrollContainer from "../../scroll-container/ScrollContainer";
import {AxiosError} from "axios";

interface IModalAttachUserGroup {
    onClose: () => void
    group: IGroup
}

const ModalAttachUserGroup = (
    {
        onClose,
        group
    }: IModalAttachUserGroup
) => {

    const {show, setShow} = useContext(LoaderContext)
    const queryClient = useQueryClient()
    const [error, setError] = useState<number | null>(null)
    const [errorAttach, setErrorAttach] = useState<string>("")
    const [search, setSearch] = useState<string>("")
    const [activeUser, setActiveUser] = useState<IUser>({} as IUser)

    const listUsers = useQuery(
        ["users_list_group"],
        () => UsersService.getAllForAttachGroup(group.id),
        {
        }
    )

    const handleSearch = (e: React.ChangeEvent<HTMLInputElement>) => {
        setSearch(e.target.value)
    }

    const handleClick = (user: IUser) => {
        setActiveUser(user)
        setErrorAttach("")
    }

    const attachUser = useMutation(
        (data: IGroupUser) => GroupsService.attachUser(data),
        {
            onSuccess: () => {
                queryClient.invalidateQueries(["group", group.id])
                setShow(false)
                onClose()
            },
            onError: (error) => {
                const e = error as AxiosError
                setError(e.response ? e.response.status : 400)
            }
        }
    )

    const handleAttach = () => {
        if (Object.keys(activeUser).length > 0) {
            setShow(true)
            const data: IGroupUser = {
                "groupId": group.id,
                "userId": activeUser.id
            }
            attachUser.mutate(data)
        } else {
            setErrorAttach("Выберите курс")
        }
    }

    return (
        <Portal>
            <Modal>
                {show && <Loader/>}
                {listUsers.isLoading && (<div>Загрузка...</div>)}
                {error && <ErrorMessage numberError={error}/>}
                <div className="flex flex-col gap-[18px] text-[16px]">
                    <div className="flex justify-between items-center font-bold text-[16px]">
                        Добавить пользователя
                        <CloseIcon className="cursor-pointer" onClick={onClose}/>
                    </div>
                    <div className="relative">
                        <SearchIcon className="absolute top-[12px] left-[10px]"/>
                        <input
                            type="text"
                            onChange={handleSearch}
                            placeholder="Введите имя пользователя"
                            className="border-solid border-[1px] border-[#BDBDBD] rounded-[5px] py-[8px] pr-[12px] pl-[34px] w-full focus:outline-none focus:border-[#17AB57]"/>
                    </div>
                    <div className="">
                        <div className="rounded-[6px] border-solid border-[1px] border-[#BDBDBD] py-[8px] h-[210px]">
                            <ScrollContainer chat={false}>
                                <div className="flex flex-col gap-[4px]">
                                    {listUsers.data && listUsers.data
                                        .filter((user) => user.firstName.toLowerCase().includes(search.toLowerCase()) ||
                                            user.fatherName?.toLowerCase().includes(search.toLowerCase()) ||
                                            user.lastName.toLowerCase().includes(search.toLowerCase()) ||
                                            user.email.toLowerCase().includes(search.toLowerCase()))
                                        .map((user, index) => (
                                            <div
                                                onClick={() => handleClick(user)}
                                                key={index}
                                                className={`cursor-pointer flex w-full items-center py-[5px] px-[10px] text-[16px] hover:underline font-medium` + (activeUser.id === user.id ? " underline bg-[#17AB571a]" : "")}>
                                                <div className="flex gap-[6px] items-center">
                                                    <UserIcon className="flex-shrink-0" fill="#17AB57"/>
                                                    {user.lastName} {user.firstName} {user.fatherName}
                                                </div>
                                            </div>
                                        ))}
                                </div>
                            </ScrollContainer>
                        </div>
                        {errorAttach && (<p className="text-red-600">{errorAttach}</p>)}
                    </div>
                    <div className="flex gap-[12px] justify-end">
                        <button onClick={handleAttach}
                                className="text-white font-medium py-[8px] px-[16px] rounded-[5px] bg-[#17AB57]">
                            Добавить
                        </button>
                        <button
                            className="font-medium py-2 px-4 rounded-[5px] border-[1px] border-solid border-[#17AB57]"
                            onClick={onClose}>
                            Отмена
                        </button>
                    </div>
                </div>
            </Modal>
        </Portal>
    )
}

export default ModalAttachUserGroup