import {ICompany} from "../../../../../models/company";
import Loader from "../../../../ui/loader/Loader";
import {ReactComponent as TestIcon} from "../../../../../assets/images/folders/test-icon.svg";
import {Link} from "react-router-dom";
import {ReactComponent as ActionIcon} from "../../../../../assets/images/global/action-icon-horizontal.svg";
import {ReactComponent as EditIcon} from "../../../../../assets/images/global/edit-icon.svg";
import {ReactComponent as DeleteIcon} from "../../../../../assets/images/folders/delete-folder-icon.svg";
import React, {useEffect, useRef, useState} from "react";
import {useMutation} from "react-query";
import {IQuestionDetailId} from "../../../../../models/tests";
import {AxiosError} from "axios";
import {CompanyService} from "../../../../../services/company.service";

interface ICompanyItem {
    company: ICompany
    parentParameter: DOMRect
    onDelete: (id: number) => void
    setError: (error: number) => void
}

const CompanyItem = (
    {
        company,
        parentParameter,
        onDelete,
        setError
    }: ICompanyItem
) => {

    const [show, setShow] = useState<boolean>(false)
    const [showMenu, setShowMenu] = useState<boolean>(false)
    const [bottomMenu, setBottomMenu] = useState<boolean>(true)
    const ref = useRef<HTMLDivElement>(null)

    useEffect(() => {
        if (ref.current) {
            const hParent = parentParameter.height + parentParameter.y - 32
            const hMenu = ref.current.getBoundingClientRect().y + 15 + 80
            if (hMenu > hParent)
                setBottomMenu(false)
        }
    }, [parentParameter])

    const handleClick = () => {
        setShowMenu(!showMenu)
    }

    useEffect(() => {
        if (!showMenu) return
        handleClickRef(ref, handleClick)
    }, [showMenu])

    const handleClickRef = (ref: React.RefObject<HTMLDivElement>, handleSet: () => void) => {
        const handleClick = (e: MouseEvent) => {
            let element = e.target as HTMLDivElement
            if (!ref.current) return
            if (!ref.current.contains(element)) {
                handleSet()
            }
        }

        document.addEventListener('click', handleClick)
        return () => {
            document.removeEventListener('click', handleClick)
        }
    }

    const deleteTest = useMutation(
        (data: IQuestionDetailId) => CompanyService.delete(data),
        {
            onSuccess: () => {
                setShow(false)
                onDelete(company.id)
            },
            onError: (error) => {
                setShow(false)
                const e = error as AxiosError
                setError(e.response ? e.response.status : 400)
            }
        }
    )

    const handleDelete = () => {
        let deleteSuccess = window.confirm("Вы действительно хотите удалить компанию? Это действие необратимо")
        if (deleteSuccess) {
            setShow(true)
            deleteTest.mutate({"id": company.id})
            handleClick()
        } else {
            handleClick()
        }
    }

    return (
        <div
            className="px-[18px] py-[20px] hover:bg-[#17AB571a] flex justify-between items-center max-lg:p-0 border-b-[2px] border-solid border-[#BDBDBD] last:border-0 max-lg:py-[12px]">
            {show && <Loader/>}
            <div className="w-[30%] flex gap-[12px] items-center max-lg:gap-[6px] max-lg:w-[100%]">
                <TestIcon className="flex-shrink-0"/>
                <Link to={`/company/${company.id}`}>{company.name}</Link>
            </div>
            <div ref={ref} className="w-[6%] flex justify-end relative">
                <ActionIcon className="flex-shrink-0 cursor-pointer" onClick={handleClick}/>
                <div
                    className={`absolute right-[10px] bg-white rounded-[6px] overflow-hidden shadow-[0_5px_10px_0_rgba(0,0,0,0.37)] z-[90]` + (showMenu ? " block" : " hidden") + (bottomMenu ? " top-[15px]" : " bottom-[20px]")}>
                    <Link
                        to={`/company/${company.id}`}
                        className="px-[12px] py-[10px] flex gap-[12px] items-center hover:bg-[#17AB571a] cursor-pointer">
                        <EditIcon/>
                        Редактировать
                    </Link>
                    <div
                        onClick={handleDelete}
                        className="px-[12px] py-[10px] flex gap-[12px] items-center hover:bg-[#17AB571a] cursor-pointer">
                        <DeleteIcon/>
                        Удалить
                    </div>
                </div>
            </div>
        </div>
    )
}

export default CompanyItem