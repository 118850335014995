import {createContext, ReactNode, useState} from "react";

interface Props {
    children?: ReactNode
}

interface ILoaderContext {
    show: boolean
    setShow: (state: boolean) => void
}

const initialValue = {
    show: false,
    setShow: () => {}
}

const LoaderContext = createContext<ILoaderContext>(initialValue)

const LoaderProvider = ({children}: Props) => {
    const [show, setShow] = useState<boolean>(initialValue.show)

    return (
        <LoaderContext.Provider value={{show, setShow}}>
            {children}
        </LoaderContext.Provider>
    )
}

export {LoaderContext, LoaderProvider}