import Portal from "../modal/portal/Portal";

const Loader = () => {
    return (
        <Portal>
            <div
                className="fixed top-0 left-0 flex items-center justify-center bg-[#0000008c] w-screen h-screen z-[100]">
                <div className="relative mx-auto h-28 w-28 animate-[spin_1.5s_linear_infinite] rounded-full">
                    <span className="absolute right-5 top-2 flex h-3 w-3">
                      <span className="relative inline-flex h-3 w-3 rounded-full bg-white"> </span>
                    </span>
                    <span className="absolute left-5 top-2 flex h-3 w-3">
                      <span className="relative inline-flex h-3 w-3 rounded-full bg-white"> </span>
                    </span>
                    <span className="absolute right-5 bottom-2 flex h-3 w-3">
                      <span className="relative inline-flex h-3 w-3 rounded-full bg-white"> </span>
                    </span>
                    <span className="absolute left-5 bottom-2 flex h-3 w-3">
                      <span className="relative inline-flex h-3 w-3 rounded-full bg-white"> </span>
                    </span>
                    <span className="absolute left-0 top-12 flex h-3 w-3">
                      <span className="relative inline-flex h-3 w-3 rounded-full bg-white"> </span>
                    </span>
                    <span className="absolute right-0 top-12 flex h-3 w-3">
                      <span className="relative inline-flex h-3 w-3 rounded-full bg-white"> </span>
                    </span>
                </div>
            </div>
        </Portal>
    )
}

export default Loader