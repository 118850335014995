import React, {useState} from "react";
import Loader from "../../../../ui/loader/Loader";
import {Link, useParams} from "react-router-dom";
import {TestsService} from "../../../../../services/tests.service";
import {useQuery} from "react-query";
import {ReactComponent as CloseIcon} from "../../../../../assets/images/global/close-modal-icon.svg";
import TestsTabs from "./tests-tabs/TestsTabs";

interface ITab {
    id: number
    label: string
}

const TestsDetail = () => {

    const {id} = useParams()

    const test = useQuery(
        ["test", Number(id)],
        () => TestsService.getById(Number(id)),
        {
            enabled: !!id
        }
    )

    let tabs: ITab[] = []
    tabs.push({id: 1, label: "Настройки"})
    tabs.push({id: 2, label: "Вопросы"})

    const classTab = "px-[36px] py-[12px] bg-[#F2F2F2] border-b-[4px] border-solid border-[#BDBDBD] cursor-pointer text-[20px] text-[#292B2C] font-medium leading-[120%] flex items-center " +
        "max-lg:rounded-[43px] max-lg:px-[12px] max-lg:py-[4px] max-lg:bg-[#E0E0E0] max-lg:text-[14px] max-lg:text-[14px] max-lg:border-none whitespace-nowrap"

    const [activeTab, setActiveTab] = useState<number>(1)

    return (
        <div className="h-full">
            {test.isLoading && <Loader/>}
            {test.data && (
                <div className="flex flex-col gap-[16px] max-lg:gap-[8px] h-full">
                    <div className="flex justify-between items-center">
                        <h2 className="font-bold text-[36px] leading-[120%]">{test.data.name}</h2>
                        <Link
                            to={`/tests`}
                            className="flex gap-[6px] py-[8px] px-[12px] max-lg:hidden border-solid border-[1px] border-[#17AB57] rounded-[5px] cursor-pointer items-center">
                            <CloseIcon width="24px" height="24px"/>
                            Выйти из редактора
                        </Link>
                    </div>
                    <div className="flex max-lg:gap-[8px] max-lg:overflow-x-auto mb-[8px]">
                        {tabs && tabs.map((tab, index) => (
                            <div onClick={() => setActiveTab(tab.id)} key={index} className={classTab + ((activeTab == tab.id) ? " !border-[#17AB57] focus:outline-none max-lg:text-white max-lg:text-[14px] max-lg:!border-none max-lg:!bg-[#17AB57] " : "")}>
                                {tab.label}
                            </div>
                        ))}
                    </div>
                    <TestsTabs test={test.data} activeTab={activeTab}/>
                </div>
            )}
        </div>
    )
}

export default TestsDetail