import RSC from "react-scrollbars-custom";
import React, {ReactNode, useEffect, useState} from "react";

interface IScrollContainer {
    children: ReactNode
    onScroll?: () => void
    heightModal?: string
    heightBlock?: string
    chat?: boolean
}

const ScrollContainer = (
    {
        children,
        onScroll,
        heightModal,
        heightBlock,
        chat
    }: IScrollContainer
) => {

    const [height, setHeight] = useState<string>("100%")
    const [chatScroll, setChatScroll] = useState<boolean>(false)

    useEffect(() => {
        if(heightModal) {
            setHeight(heightModal)
        }
        if(heightBlock) {
            setHeight(heightBlock)
        }
    }, [heightModal, heightBlock])

    const handleScroll = (el: any) => {
        if(el) {
            if(chat) return el.scrollToBottom()
        }
    }

    return (
        <RSC
            ref={(el: any) => handleScroll(el)}
            style={{height: height}}
            trackYProps={{
                renderer: (props) => {
                    const {elementRef, ...restProps} = props;
                    return <div {...restProps} ref={elementRef}
                                className="!w-[5px]"/>;
                },
            }}
            thumbYProps={{
                renderer: (props) => {
                    const {elementRef, ...restProps} = props;
                    return <div {...restProps} ref={elementRef}
                                className="!bg-[#BDBDBD]"/>;
                },
            }}
            onScroll={onScroll}
        >
            {children}
        </RSC>
    )
}

export default ScrollContainer