import Modal from "../Modal";
import ErrorMessage from "../../error/ErrorMessage";
import {ReactComponent as CloseIcon} from "../../../../assets/images/global/close-modal-icon.svg";
import Portal from "../portal/Portal";
import React, {useContext, useEffect, useState} from "react";
import {useMutation} from "react-query";
import {SubmitHandler, useForm} from "react-hook-form";
import {AxiosError} from "axios";
import {IGroup, IGroupDetail} from "../../../../models/groups";
import {GroupsService} from "../../../../services/groups.service";
import {LoaderContext} from "../../../../context/LoaderContext";
import Loader from "../../loader/Loader";

interface IModalAddGroup {
    onClose: () => void
    group: IGroup
    setGroup: (group: IGroup) => void
    detail: boolean
}

const ModalRenameGroup = (
    {
        onClose,
        group,
        setGroup,
        detail
    }: IModalAddGroup
) => {

    const {show, setShow} = useContext(LoaderContext)
    const [error, setError] = useState<number | null>(null)

    const {
        setValue,
        register,
        handleSubmit,
        formState: {errors}
    } = useForm<IGroupDetail>()

    useEffect(() => {
        setValue("name", group.name)
    }, [])

    const updateGroup = useMutation(
        (data: IGroupDetail) => detail ? GroupsService.update(group.id, data) : GroupsService.rename(group.id, data),
        {
            onSuccess: (response) => {
                setGroup(response)
                setShow(false)
                onClose()
            },
            onError: (error) => {
                setShow(false)
                const e = error as AxiosError
                setError(e.response ? e.response.status : 400)
            }
        }
    )

    const onSubmit: SubmitHandler<IGroupDetail> = async (data) => {
        setShow(true)
        updateGroup.mutate(data)
    }

    return (
        <Portal>
            <Modal>
                {show && <Loader/>}
                {error && <ErrorMessage numberError={error}/>}
                <form className="flex flex-col gap-[24px] text-[16px]" onSubmit={handleSubmit(onSubmit)}>
                    <div className="flex justify-between items-center font-bold text-[16px]">
                        Переименование группы
                        <CloseIcon className="cursor-pointer" onClick={onClose}/>
                    </div>
                    <div>
                        <input
                            className="border-[1px] border-solid border-[#BDBDBD] font-medium py-[8px] pl-[10px] pr-[12px] focus:outline-none focus:border-[#17AB57] w-full rounded-[5px]"
                            type="text"
                            placeholder="Введите название группы"
                            {...register("name", {required: true})}/>
                        {errors.name && errors.name.type === "required" && (
                            <p className="text-red-600">Название обязательно</p>
                        )}
                    </div>
                    <div className="flex gap-[12px] justify-end">
                        <button
                            type="submit"
                            className="text-white font-medium py-[8px] px-[16px] rounded-[5px] bg-[#17AB57]"
                        >
                            Сохранить
                        </button>
                        <button
                            className="font-medium py-2 px-4 rounded-[5px] border-[1px] border-solid border-[#17AB57]"
                            onClick={onClose}>Отмена
                        </button>
                    </div>
                </form>
            </Modal>
        </Portal>
    )
}

export default ModalRenameGroup