import React, {useContext, useState} from "react";
import Cookies from "universal-cookie";
import {useQuery} from "react-query";
import {UsersService} from "../../../../services/users.service";
import {AxiosError} from "axios";
import ErrorMessage from "../../../ui/error/ErrorMessage";
import StudentCoursesItem from "./student-courses-item/StudentCoursesItem";
import Loader from "../../../ui/loader/Loader";
import Welcome from "../../../../assets/images/users/welcome.png"
import {ReactComponent as SearchIcon} from "../../../../assets/images/global/searcg-icon.svg";
import {AuthContext} from "../../../../context/AuthContext";

const StudentCourses = () => {

    const cookies = new Cookies()
    const [blurSearch, setBlurSearch] = useState<boolean>(false)
    const [searchTerm, setSearchTerm] = useState<string>("")
    const [allCourses, setAllCourses] = useState<boolean>(true)

    const {authenticated, setAuthenticated, currentRoleId, currentUserId} = useContext(AuthContext)

    const handleSearch = (e: React.ChangeEvent<HTMLInputElement>) => {
        setSearchTerm(e.target.value)
    }

    const user = useQuery(
        ["user_course", Number(currentUserId)],
        () => UsersService.getById(currentUserId),
        {
            enabled: !!cookies.get("user"),
            onSuccess: (response) => {}
        }
    )

    const courses = useQuery(
        ["user_courses", currentUserId],
        () => UsersService.getCourses(Number(currentUserId)),
        {}
    )

    if (courses.isLoading) return <Loader/>
    if (user.isLoading) return <Loader/>

    if (courses.isError) {
        const e = courses.error as AxiosError
        return <ErrorMessage numberError={e.response ? e.response.status : 400}/>
    }
    if (user.isError) {
        const e = user.error as AxiosError
        return <ErrorMessage numberError={e.response ? e.response.status : 400}/>
    }

    if (!courses.data) return <div className="text-center">Ошибка данных</div>
    if (!user.data) return <div className="text-center">Ошибка данных</div>

    return (
        <div className="flex flex-col gap-[34px] h-full max-lg:gap-[16px]">
            <div className="px-[46px] flex justify-between w-full bg-white rounded-[6px] items-center max-lg:hidden">
                <div className="flex flex-col gap-[12px] text-[16px] text-[#292B2C]">
                    <div className="leading-[95%] text-[36px] font-bold">Здравствуйте, {user.data.firstName} {user.data.fatherName}!</div>
                    <div className="leading-[115%]">Самое время для профессионального роста и получения новых знаний</div>
                </div>
                <img src={Welcome} alt=""/>
            </div>
            {courses.data.filter(course => course.isLastActivity).length > 0 && (
                <div className="flex flex-col gap-[12px]">
                    <div className="text-[#292B2C] text-[24px] font-bold leading-[120%]">Последний курс</div>
                    <div
                        className="bg-white p-[32px] rounded-[6px] flex flex-col gap-[24px] text-[16px] font-medium max-lg:p-[14px]">
                        <StudentCoursesItem course={courses.data.filter(course => course.isLastActivity)[0]}/>
                    </div>
                </div>
            )}
            <div className="flex flex-col gap-[12px]">
                <div className="text-[#292B2C] text-[24px] font-bold leading-[120%]">Мои курсы</div>
                <div
                    className="bg-white p-[32px] rounded-[6px] text-[16px] flex flex-col gap-[18px] font-medium max-lg:px-[14px] max-lg:py-[14px] max-lg:gap-[12px]">
                    <div className="flex items-center justify-between">
                        <div className="flex text-[#292B2C] text-[16px]">
                            <div
                                className={`px-[24px] py-[12px] rounded-[43px] cursor-pointer ${allCourses ? "bg-[#17AB57] text-white" : ""}`}
                                onClick={() => setAllCourses(true)}>
                                Назначенные ({courses.data.length})
                            </div>
                            <div
                                className={`px-[24px] py-[12px] rounded-[43px] cursor-pointer ${!allCourses ? "bg-[#17AB57] text-white" : ""}`}
                                onClick={() => setAllCourses(false)}>
                                Завершенные ({courses.data.filter(course => course.isPassed).length})
                            </div>
                        </div>
                        <div className="relative max-w-[430px] w-full">
                            <SearchIcon
                                className={`absolute top-[8px] left-[10px] ` + (blurSearch ? "fill-[#292B2C]" : "")}/>
                            <input
                                onFocus={() => {
                                    setBlurSearch(true)
                                }}
                                onBlur={() => {
                                    setBlurSearch(false)
                                }}
                                onChange={handleSearch}
                                type="text"
                                placeholder="Поиск"
                                className="py-[8px] pl-[36px] pr-[12px] border-solid border-[1px] border-[#BDBDBD] rounded-[5px] bg-white w-full focus:border-[#17AB57] focus:outline-none"/>
                        </div>
                    </div>
                    {courses.data
                        .filter(course => course.course.name.toLowerCase().includes(searchTerm.toLowerCase()))
                        .filter(course => !allCourses ? course.isPassed : course)
                        .map((course, index) => (
                            <StudentCoursesItem course={course} key={index}/>
                        ))}
                </div>
            </div>
        </div>
    )
}

export default StudentCourses