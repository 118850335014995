import {IUser, IUserGroup, IUserGroupOption} from "../../../../../../../models/users";
import {useMutation, useQuery, useQueryClient} from "react-query";
import {ReactComponent as ActionIcon} from "../../../../../../../assets/images/global/action-icon-horizontal.svg";
import React, {useContext, useEffect, useRef, useState} from "react";
import {ReactComponent as AppointIcon} from "../../../../../../../assets/images/users/appoint-supervisor-group.svg";
import {ReactComponent as ExcludeIcon} from "../../../../../../../assets/images/users/exclude-group.svg";
import {UsersService} from "../../../../../../../services/users.service";
import {AxiosError} from "axios";
import ErrorMessage from "../../../../../../ui/error/ErrorMessage";
import {Controller, SubmitHandler, useForm} from "react-hook-form";
import Select from "react-select";
import {GroupsService} from "../../../../../../../services/groups.service";
import {LoaderContext} from "../../../../../../../context/LoaderContext";
import Loader from "../../../../../../ui/loader/Loader";
import {Constants} from "../../../../../../../constans/constants";
import Cookies from "universal-cookie";
import {AuthContext} from "../../../../../../../context/AuthContext";

interface IUserGroups {
    user: IUser
}

const UserGroups = (
    {
        user
    }: IUserGroups
) => {

    const cookies = new Cookies()
    const queryClient = useQueryClient()
    const {show, setShow} = useContext(LoaderContext)
    const [showMenu, setShowMenu] = useState<boolean>(false)
    const [error, setError] = useState<number | null>(null)
    const ref = useRef<HTMLDivElement>(null)
    const [optionsGroup, setOptionsGroup] = useState<IUserGroupOption[]>([])
    const {currentRoleId} = useContext(AuthContext)

    const handleClick = () => {
        setShowMenu(!showMenu)
    }

    useEffect(() => {
        if (!showMenu) return
        handleClickRef(ref, handleClick)
    }, [showMenu])

    const handleClickRef = (ref: React.RefObject<HTMLDivElement>, handleSet: () => void) => {
        const handleClick = (e: MouseEvent) => {
            let element = e.target as HTMLDivElement
            if (!ref.current) return
            if (!ref.current.contains(element)) {
                handleSet()
            }
        }

        document.addEventListener('click', handleClick)
        return () => {
            document.removeEventListener('click', handleClick)
        }
    }

    const updateSupervisor = useMutation(
        () => UsersService.updateSupervisor(user.id),
        {
            onSuccess: () => {
                queryClient.invalidateQueries(["user", String(user.id)])
                setShow(false)
                handleClick()
            },
            onError: (errorUpdate) => {
                handleClick()
                setShow(false)
                const e = errorUpdate as AxiosError
                setError(e.response ? e.response.status : 400)
            }
        }
    )

    const handleUpdateSupervisor = () => {
        setShow(true)
        updateSupervisor.mutate()
    }

    const excludeUser = useMutation(
        () => UsersService.excludeGroup(user.id),
        {
            onSuccess: () => {
                queryClient.invalidateQueries(["user", String(user.id)])
                setShow(false)
                handleClick()
            },
            onError: (errorUpdate) => {
                setShow(false)
                handleClick()
                const e = errorUpdate as AxiosError
                setError(e.response ? e.response.status : 400)
            }
        }
    )

    const handleExclude = () => {
        let deleteSuccess = window.confirm("Вы действительно хотите исключить из группы? Это действие необратимо")
        if (deleteSuccess) {
            setShow(true)
            excludeUser.mutate()
        }
    }

    const listGroups = useQuery(
        ["groups_list_user"],
        () => GroupsService.getAll(),
        {
            enabled: !(!!user.group) && Constants.LIST_ADMIN_ROLE.indexOf(Number(currentRoleId)) !== -1,
            onSuccess: (response) => {
                setOptionsGroup([])
                response.forEach(element => {
                    setOptionsGroup(prev => [...prev, {
                        id: element.id,
                        name: element.name
                    }])
                })
            }
        }
    )

    const {
        control,
        setValue,
        register,
        handleSubmit,
        formState: {errors}
    } = useForm<IUserGroup>()

    const updateGroup = useMutation(
        (data: IUserGroup) => UsersService.updateGroup(data),
        {
            onSuccess: () => {
                queryClient.invalidateQueries(["user", String(user.id)])
                setShow(false)
            },
            onError: (errorUpdate) => {
                handleClick()
                setShow(false)
                const e = errorUpdate as AxiosError
                setError(e.response ? e.response.status : 400)
            }
        }
    )

    const onSubmit: SubmitHandler<IUserGroup> = (data) => {
        setShow(true)
        updateGroup.mutate(data)
    }

    useEffect(() => {
        setValue("userId", user.id)
    }, [user])

    // if (!listGroups.data) return <div className="text-center">Нет данных</div>

    return (
        <div className="flex flex-col gap-[24px] w-[50%] max-2xl:w-[75%] max-lg:w-full">
            {show && <Loader/>}
            <div className="text-[20px] text-[#292B2C] font-medium leading-[120%]">Группы в которых состоит
                пользователь
            </div>
            <div className="flex gap-[48px]">
                <div className="text-[16px] text-[#292B2C] font-medium leading-[120%] whitespace-nowrap">Участие в
                    группах
                </div>
                {user.group ? (
                    <div
                        className="flex justify-between w-full border-[#17AB57] border-[1px] rounded-[5px] px-[12px] py-[8px] items-center">
                        {user.group.name}
                        <div ref={ref} className="flex justify-end relative">
                            <ActionIcon className="flex-shrink-0 cursor-pointer" onClick={handleClick}/>
                            <div
                                className={`absolute right-[10px] bg-white rounded-[6px] overflow-hidden shadow-[0_5px_10px_0_rgba(0,0,0,0.37)] z-[90] top-[15px]` + (showMenu ? " block" : " hidden")}>
                                <div
                                    onClick={handleUpdateSupervisor}
                                    className="px-[12px] py-[10px] flex gap-[12px] items-center hover:bg-[#17AB571a] cursor-pointer whitespace-nowrap">
                                    <AppointIcon className="flex-shrink-0"/>
                                    Назначить руководителем группы
                                </div>
                                <div
                                    onClick={handleExclude}
                                    className="px-[12px] py-[10px] flex gap-[12px] items-center hover:bg-[#17AB571a] cursor-pointer whitespace-nowrap">
                                    <ExcludeIcon className="flex-shrink-0"/>
                                    Исключить из группы
                                </div>
                            </div>
                        </div>
                    </div>
                ) : (
                    <form onSubmit={handleSubmit(onSubmit)} className="w-full flex flex-col gap-[24px]">
                        <Controller
                            control={control}
                            name={"group"}
                            rules={{
                                required: true
                            }}
                            render={({field: {onChange, value}, fieldState: {error}}) =>
                                <>
                                    <Select
                                        theme={(theme) => ({
                                            ...theme,
                                            borderRadius: 5,
                                            colors: {
                                                ...theme.colors,
                                                primary: "#17AB57",
                                                primary25: "#17AB571a"
                                            }
                                        })}
                                        placeholder={"Выберите группу"}
                                        onChange={onChange}
                                        value={value}
                                        isSearchable={true}
                                        options={optionsGroup}
                                        // getOptionLabel={(group: IGroup) => group.name}
                                        getOptionLabel={(group: IUserGroupOption) => group.name}
                                        getOptionValue={(group: IUserGroupOption) => String(group.id)}
                                    />
                                    {error && (<p className="text-red-600">Выберите группу</p>)}
                                </>
                            }
                        />
                        <input type="hidden" {...register("userId", {required: true})}/>
                        {errors.userId && errors.userId.type == "required" && (
                            <p className="text-red-600">Пользователь обязателен</p>
                        )}
                        <div className="flex gap-[12px] justify-end">
                            <button
                                type="submit"
                                className="py-[8px] px-[16px] bg-[#17AB57] rounded-[5px] text-white text-[16px] font-medium">
                                Сохранить
                            </button>
                        </div>
                    </form>
                )}
            </div>
            {error && (<ErrorMessage numberError={error}/>)}
        </div>
    )
}

export default UserGroups