import axios from "axios";
import {
    ILessonByCourseForAttach,
    ILessons, ILessonsAttachInUser,
    ILessonsEdit,
    ILessonsUpdateHours, IListLessonsByCourse,
    IOrderLesson, ISearchLessonLeft, ISearchLessonPost
} from "../models/lessons";
import AuthHeader from "./auth-header";
import {Constants} from "../constans/constants";

export const LessonsService = {
    async getById(id: number | undefined) {
        const {data} = await axios.get<ILessonsEdit>(Constants.LOCATION + `/api/Lesson/${id}`, {headers: AuthHeader()})
        return data
    },

    async getByCourse(id: number) {
        const {data} = await axios.get<IListLessonsByCourse>(Constants.LOCATION + `/api/Lesson/course/${id}`, {headers: AuthHeader()})
        return data
    },

    async create(data: any) {
        const response = await axios.post<ILessonsEdit>(Constants.LOCATION + '/api/Lesson/add', data, {headers: AuthHeader()})
        return response.data.id
    },

    async update(id: number, data: any) {
        const response = await axios.post<ILessonsEdit>(Constants.LOCATION + `/api/Lesson/update/${id}`, data, {headers: AuthHeader()})
        return response.data.id
    },

    async updateOrder(id: number, data: any) {
        return await axios.post<ILessons>(Constants.LOCATION + `/api/Lesson/updateOrder/${id}`, data, {headers: AuthHeader()})
    },

    async updateOrderList(id: number, dataSubmit: IOrderLesson[]) {
        const {data} = await axios.post<ILessons[]>(Constants.LOCATION + `/api/Lesson/updateOrderList/${id}`, dataSubmit, {headers: AuthHeader()})
        return data
    },

    async delete(id: number) {
        return await axios.post<boolean>(Constants.LOCATION + `/api/Lesson/delete/${id}`, [], {headers: AuthHeader()})
    },

    async downloadFile(id: number) {
        const {data} = await axios.post<string>(Constants.LOCATION + `/api/Lesson/downloadObject/${id}`, [], {headers: AuthHeader()})
        return data
    },

    async updateHours(id: number, dataSubmit: ILessonsUpdateHours[]) {
        const {data} = await axios.post<string>(Constants.LOCATION + `/api/Lesson/updateHours/${id}`, dataSubmit, {headers: AuthHeader()})
        return data
    },

    async getListByCourseForAttach(courseId: number, userId: number) {
        const {data} = await axios.get<ILessonsAttachInUser>(Constants.LOCATION + `/api/Lesson/getListByCourseForAttach/${courseId}/${userId}`, {headers: AuthHeader()})
        return data
    },

    async getListByCourseForAddInFedkurs(courseId: number) {
        const {data} = await axios.get<ILessonsAttachInUser>(Constants.LOCATION + `/api/Lesson/getListByCourseForAddInFedkurs/${courseId}`, {headers: AuthHeader()})
        return data
    },

    async searchLessons(dataSubmit: ISearchLessonPost){
        const {data} = await axios.post<ISearchLessonLeft>(Constants.LOCATION + `/api/Lesson/searchLessons/`, dataSubmit, {headers: AuthHeader()})
        return data
    }
}