import React, {useContext, useEffect, useRef, useState} from "react";
import Cookies from "universal-cookie";
import {
    IActionMessage,
    IDeleteMessage,
    IMessage
} from "../../../../../models/chat";
import {ReactComponent as ReadMessage} from "../../../../../assets/images/chat/readMessage.svg";
import {ReactComponent as File} from "../../../../../assets/images/chat/file.svg";
import {ReactComponent as Delete} from "../../../../../assets/images/folders/delete-folder-icon.svg";
import {ReactComponent as Edit} from "../../../../../assets/images/global/edit-icon.svg";
import {ReactComponent as Answer} from "../../../../../assets/images/chat/answer.svg";
import {ReactComponent as CopyIcon} from "../../../../../assets/images/global/copy-icon.svg";
import {Constants} from "../../../../../constans/constants";
import {ReactComponent as ArrowMenu} from "../../../../../assets/images/global/arrow-avatar.svg";
import {useMutation} from "react-query";
import {ChatService} from "../../../../../services/chat.service";
import Loader from "../../../../ui/loader/Loader";
import {AuthContext} from "../../../../../context/AuthContext";

interface IMessageProps {
    message: IMessage
    index: number
    handleDownloadFile: (id: number) => void
    areaChat?: DOMRect
    chatId: number | null
    setActionMessage: (message: IActionMessage) => void
    heightActionMessage: number | null
    setAnswerMessage: (id: number | null) => void
    changeUnreadMessage: number | null
    answerMessage: number | null
    countUnreadMessage: number | null
    messages: IMessage[]
    setLastMessage: (flag: boolean) => void
}

const Message = (
    {
        message,
        index,
        handleDownloadFile,
        areaChat,
        chatId,
        setActionMessage,
        heightActionMessage,
        setAnswerMessage,
        changeUnreadMessage,
        answerMessage,
        messages,
        countUnreadMessage,
        setLastMessage
    }: IMessageProps
) => {

    const cookies = new Cookies()
    const refDownloadFile = useRef<HTMLAnchorElement>(null)
    const answeredMessageRef = useRef<HTMLDivElement>(null)
    const messageRef = useRef<HTMLDivElement>(null)
    const menuRef = useRef<HTMLDivElement>(null)
    const unreadMessage = useRef<HTMLDivElement>(null)

    const [viewUserMenu, setViewUserMenu] = useState<boolean>(false)
    const [bottomMenu, setBottomMenu] = useState<boolean>(true)
    const [showLoading, setShowLoading] = useState<boolean>(false)
    const [lastMessageBySender, setLastMessageBySender] = useState<boolean>(false)
    const [showDate, setShowDate] = useState<boolean>(false)
    const {authenticated, setAuthenticated, currentRoleId, currentUserId} = useContext(AuthContext)

    const handleClickUser = () => {
        let menuHeight = message.files ? 100 : 160
        if (Number(areaChat?.bottom) > (menuHeight + Number(messageRef.current?.getBoundingClientRect()?.bottom) + 24) + Number(heightActionMessage)) {
            setBottomMenu(true)
        } else {
            setBottomMenu(false)
        }
        setViewUserMenu(!viewUserMenu)
    }
    useEffect(() => {
        if (!viewUserMenu) return
        handleClickRef(messageRef, handleClickUser)
    }, [viewUserMenu])
    const handleClickRef = (ref: React.RefObject<HTMLDivElement>, handleSet: () => void) => {
        const handleClick = (e: MouseEvent) => {
            let element = e.target as HTMLDivElement
            if (!ref.current) return
            if (!ref.current.contains(element)) {
                setViewUserMenu(!viewUserMenu)
            }
        }

        document.addEventListener('click', handleClick)
        return () => {
            document.removeEventListener('click', handleClick)
        }
    }

    const deleteMessage = useMutation(
        (data: IDeleteMessage) => ChatService.deleteMessage(data),
        {
            onSuccess: (response) => {
                setShowLoading(false)
            },
            onError: (error) => {
                setShowLoading(false)
            }
        }
    )

    const handleDeleteMessage = () => {
        if (chatId) {
            let deleteSuccess = window.confirm("Вы действительно хотите удалить сообщение? Это действие необратимо")
            if (deleteSuccess) {
                setShowLoading(true)
                let data: IDeleteMessage = {
                    chatId: chatId,
                    currentUserId: Number(currentUserId),
                    messageId: message.id
                }
                deleteMessage.mutate(data)
            }
        }
    }

    const handleActionMessage = (edit: boolean, answer: boolean) => {
        setActionMessage({
            edit: edit,
            answer: answer,
            message: message
        })
    }

    const handleScrollElement = () => {
        if (message.answeredMessage) {
            setAnswerMessage(message.answeredMessage?.id)
        }
    }

    const handleCopyText = () => {
        if(message.text) {
            navigator.clipboard.writeText(message.text.replace(/(<([^>]+)>)/gi, ''))
        }
    }

    useEffect(() => {
        if (answeredMessageRef.current && answerMessage === message.id) {
            answeredMessageRef.current.classList.add("bg-[#17ab571a]")

            setTimeout(() => {
                if (answeredMessageRef.current && answerMessage === message.id) answeredMessageRef.current.classList.remove("bg-[#17ab571a]")
            }, 3000)
        }
    }, [answerMessage]);

    useEffect(() => {
        setLastMessageBySender(false)
        setShowDate(false)
        messages.map(messageItem => {
            if (messageItem === message) {
                if (messages[index + 1] && message.senderId === messages[index + 1].senderId) {
                    setLastMessageBySender(true)
                }
                if (messages[index - 1] && message.date !== messages[index - 1].date || !messages[index - 1]) {
                    setShowDate(true)
                }
            }
            if (messageItem === messages[messages.length - 1]) {
                setLastMessage(true)
            }
        })
    }, [messages]);

    return (
        <>
            {showLoading && <Loader/>}
            <div className="w-full first:pt-[12px] last:pb-[12px]" id={`mes-${String(message.id)}`}>
                {changeUnreadMessage === message.id && message.senderId !== Number(currentUserId) && (
                    <div ref={unreadMessage} id="unreadMessages"
                         className="flex items-center w-full justify-center bg-[#E0E0E0] py-[12px] text-[16px] text-[#292B2C] font-[500] my-[9px]">
                        Непрочитанные сообщения
                        ({countUnreadMessage})
                    </div>
                )}
                {showDate && (
                    <div className="flex justify-center my-[14px]">
                        <div
                            className="px-[12px] py-[8px] bg-[#E0E0E0] rounded-[16px] text-[16px] text-[#828282] ">{message.date}</div>
                    </div>
                )}
                <div
                    className={`flex ${message.senderId === Number(currentUserId) ? "justify-end" : "justify-start"}`}>
                    <div className={`w-full flex flex-col`}>
                        <div
                            ref={answeredMessageRef}
                            key={index}
                            className={` transition-all relative flex gap-[10px] items-end py-[5px] px-[16px] ${message.senderId === Number(currentUserId) ? "flex-row-reverse" : ""} `}>
                            <div
                                className={`flex-shrink-0 w-[36px] h-[36px] max-lg:hidden ${lastMessageBySender ? "invisible" : ""}`}>
                                {message.avatar ? (
                                    <img
                                        className="w-full h-full flex-shrink-0 rounded-[49px]"
                                        src={message.avatar} alt=""/>
                                ) : (
                                    <div
                                        className={`w-full h-full flex-shrink-0 flex justify-center items-center rounded-[49px] ${Constants.LIST_COLORS[Number(message.color)]}`}>
                                <span
                                    className={`text-[16px] font-[500] text-white ${Constants.LIST_COLORS[Number(message.color)]}`}>
                                    {message.lastName[0]}{message.firstName[0]}
                                </span>
                                    </div>
                                )}
                            </div>
                            <div className={`p-[12px] rounded-t-[16px] flex flex-col gap-[10px] max-w-[460px] max-lg:max-w-[300px] relative group
                        ${!lastMessageBySender ? `${message.senderId === Number(currentUserId) ? "rounded-bl-[16px] rounded-br-[4px]" : "rounded-br-[16px] rounded-bl-[4px]"}` : "rounded-b-[16px]"}
                        ${message.senderId === Number(currentUserId) ? "bg-[#17AB57] text-white ml-auto" : "bg-white text-[#292B2C] mr-auto"}`}>
                                {message.answeredMessage && Object.keys(message.answeredMessage).length > 0 && (
                                    <a
                                        href={`#mes-${message.answeredMessage.id}`}
                                        onClick={handleScrollElement}
                                        className={`px-[10px] py-[6px] flex flex-col gap-[2px] rounded-[2px] bg-[#0000001a] cursor-pointer`}>
                                        <div
                                            className="font-bold">{message.answeredMessage.lastName} {message.answeredMessage.firstName}</div>
                                        <div className="truncate"
                                             dangerouslySetInnerHTML={{__html: message.answeredMessage.text}}/>
                                    </a>
                                )}
                                <div className="flex gap-[10px] items-end">
                                    <div className="flex w-full max-w-[386px] max-lg:max-w-[195px]">
                                        {message.files ? (
                                            <div className={`flex flex-col gap-[14px]`}>
                                                {message.files.map((file, index) => (
                                                    <div key={index} className="flex gap-[10px] items-center">
                                                        <div
                                                            className="w-[36px] h-[36px] flex-shrink-0 rounded-[4px] bg-[#2F80ED] flex items-center justify-center">
                                                            <File/>
                                                        </div>
                                                        <div className="flex flex-col gap-[2px]">
                                                            <div className="">{file.name}{file.extention}</div>
                                                            <div
                                                                onClick={() => handleDownloadFile(file.id)}
                                                                className={`${message.senderId === Number(currentUserId) ? "" : "text-[#2F80ED]"} underline text-[12px] cursor-pointer`}>Скачать
                                                            </div>
                                                            <a ref={refDownloadFile}
                                                               href="#"
                                                               className="hidden"></a>
                                                        </div>
                                                    </div>
                                                ))}
                                            </div>
                                        ) : (
                                            <div className="w-full break-words message"
                                                 dangerouslySetInnerHTML={{__html: message.text ? message.text : ""}}/>
                                        )}
                                    </div>
                                    <div
                                        className={`flex-shrink-0 ${message.senderId !== Number(currentUserId) ? "text-[#BDBDBD]" : ""}`}>{message.time}</div>
                                    {message.isViewed && message.senderId === Number(currentUserId) && (
                                        <ReadMessage className="flex-shrink-0"/>
                                    )}
                                    <div ref={messageRef} onClick={handleClickUser}
                                         className="flex-shrink-0 absolute top-[10px] right-[10px] z-[100]  w-[24px] h-[24px] cursor-pointer group-hover:bg-[#58db92d4] rounded-[49px] group-hover:shadow-2xl">
                                        <ArrowMenu className="hidden fill-white group-hover:block "/>
                                        {viewUserMenu && (
                                            <div
                                                ref={menuRef}
                                                className={`z-[100] ${bottomMenu ? "top-[24px]" : "bottom-[24px]"} rounded-[6px] absolute bg-white overflow-hidden shadow-[0_5px_10px_0_rgba(0,0,0,0.37)] text-[#292B2C] ${message.senderId === Number(currentUserId) ? "right-0" : "left-0"}`}>
                                                <div
                                                    onClick={() => handleActionMessage(false, true)}
                                                    className="px-[12px] py-[10px] flex gap-[6px] hover:bg-gradient-to-t hover:from-[#17AB571a] hover:from-0% hover:to-[#17AB571a] hover:to-100% items-center cursor-pointer">
                                                    <div className="flex gap-[6px] items-center">
                                                        <Answer className="flex-shrink-0 w-[20px] h-[20px]"/>
                                                        Ответить
                                                    </div>
                                                </div>
                                                {!message.files && (
                                                    <div
                                                        onClick={() => handleCopyText()}
                                                        className="px-[12px] py-[10px] flex gap-[6px] hover:bg-gradient-to-t hover:from-[#17AB571a] hover:from-0% hover:to-[#17AB571a] hover:to-100% items-center cursor-pointer">
                                                        <div className="flex gap-[6px] items-center">
                                                            <CopyIcon className="flex-shrink-0 w-[20px] h-[20px]"/>
                                                            Скопировать
                                                        </div>
                                                    </div>
                                                )}
                                                {message.senderId === Number(currentUserId) && (
                                                    <>
                                                        {!message.files && (
                                                            <div
                                                                onClick={() => handleActionMessage(true, false)}
                                                                className="px-[12px] py-[10px] flex gap-[6px] hover:bg-gradient-to-t hover:from-[#17AB571a] hover:from-0% hover:to-[#17AB571a] hover:to-100% items-center cursor-pointer">
                                                            <div className="flex gap-[6px] items-center">
                                                                    <Edit className="flex-shrink-0 w-[20px] h-[20px]"/>
                                                                    Редактировать
                                                                </div>
                                                            </div>
                                                        )}
                                                        <div
                                                            onClick={handleDeleteMessage}
                                                            className="px-[12px] py-[10px] flex gap-[6px] hover:bg-gradient-to-t hover:from-[#17AB571a] hover:from-0% hover:to-[#17AB571a] hover:to-100% items-center cursor-pointer">
                                                            <div className="flex gap-[6px] items-center">
                                                                <Delete className="flex-shrink-0 w-[20px] h-[20px]"/>
                                                                Удалить
                                                            </div>
                                                        </div>
                                                    </>
                                                )}
                                            </div>
                                        )}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default Message