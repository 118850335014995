import { handleEnter} from "../helpers/ReactQuillClipboard";

export const Constants = {
    LIST_ADMIN_ROLE: [1, 2],
    ADMIN_ROLE: 1,
    METHODIST_ROLE: 2,
    SUPERVISOR_ROLE: 3,
    STUDENT_ROLE: 4,
    REACT_QUILL_MODULES: {
        toolbar: [
            [{ 'header': '1'}, {'header': '2'}],
            [{size: []}],
            ['bold', 'italic', 'underline', 'strike', 'blockquote'],
            [{'list': 'ordered'}, {'list': 'bullet'}, {'indent': '-1'}, {'indent': '+1'}, {'align': []}],
            ['link', 'image', 'video'],
            ['clean']
        ],
        clipboard: {
            matchVisual: false,
        }
    },
    REACT_QUILL_FORMATS: [
        'header', 'font', 'size',
        'bold', 'italic', 'underline', 'strike', 'blockquote',
        'list', 'bullet', 'indent', 'align',
        'link', 'image', 'video'
    ],
    REACT_QUILL_MODULES_CHAT: {
        toolbar: [
            ['bold', 'italic', 'underline', 'strike'],
            ['link', 'image'],
            ['clean']
        ],
        clipboard: {
            matchVisual: false,
        },
        keyboard:{
            bindings: {
                break: {
                    key: 13,
                    handler: handleEnter
                }
            }
        }
    },
    REACT_QUILL_FORMATS_CHAT: [
        'bold', 'italic', 'underline', 'strike',
        'link', 'image'
    ],
    LIST_APOK_DOMAIN: [
        {value: "gosdo.ru", label: "Главный"},
        {value: "apokdpo.gosdo.ru", label: "АПОК"},
        {value: "ecodpo.gosdo.ru", label: "ЭКО"},
        {value: "fedkurs.gosdo.ru", label: "Федкурс"}
    ],
    LIST_TEST_DOMAIN: [
        {value: "dpo-plus.ru", label: "Главный"},
        {value: "apokdpo.dpo-plus.ru", label: "АПОК"},
        {value: "ecodpo.dpo-plus.ru", label: "ЭКО"},
        {value: "fedkurs.dpo-plus.ru", label: "Федкурс"}
    ],
    LIST_LOCAL_DOMAIN: [
        {value: "localhost:3000", label: "Главный"},
        {value: "apokdpo.localhost:3000", label: "АПОК"},
        {value: "ecodpo.localhost:3000", label: "ЭКО"},
        {value: "fedkurs.localhost:3000", label: "Федкурс"}
    ],
    LIST_COLORS: [
        "bg-gradient-to-tl from-[#1AA3D8] from-[46.56%] to-[#13769C] to-[106.43%]",
        "bg-gradient-to-tl from-[#4369EE] from-[8.72%] to-[#1D3A9D] to-[105.92%]",
        "bg-gradient-to-tl from-[#8A62EC] from-[8.05%] to-[#623EBB] to-[69.87%]",
        "bg-gradient-to-tl from-[#DC366E] from-[0%] to-[#AB1648] to-[100%]",
        "bg-gradient-to-tl from-[#FC865D] from-[0%] to-[#E16C45] to-[97.91%]",
        "bg-gradient-to-tl from-[#AB6A64] from-[56.09%] to-[#853A33] to-[117.59%]",
        "bg-gradient-to-tl from-[#635359] from-[8.68%] to-[#332D2F] to-[104.99%]",
        "bg-gradient-to-tl from-[#455F50] from-[37.28%] to-[#2E3732] to-[117.4%]",
        "bg-gradient-to-tl from-[#99BA53] from-[36.99%] to-[#7C9744] to-[91.96%]",
        "bg-gradient-to-tl from-[#D85449] from-[46.56%] to-[#AE291D] to-[100.62%]"
    ],
    LOCATION: window.location.origin.indexOf("localhost:3000") != -1 ? String(process.env.REACT_APP_API_URL) : window.location.origin,
    COUNT_SYMBOL_MESSAGE: 2500,
    LIST_WITHOUT_SCROLL_IN_LAYOUT: [
        'attach-lessons',
        'add-course-in-fedkurs',
        'attach-course-in-group',
    ]
}