import {ReactComponent as LinkIcon} from "../../../../../../../assets/images/folders/lesson-link-file.svg";
import {ReactComponent as FileIcon} from "../../../../../../../assets/images/folders/lesson-file-icon.svg";
import {Link} from "react-router-dom";
import React, {useRef} from "react";
import {IFile} from "../../../../../../../models/file";
import {useMutation} from "react-query";
import {LessonsService} from "../../../../../../../services/lessons.service";

interface IPreviewLessonAdditionalFilesItem {
    file: IFile
}

const PreviewLessonAdditionalFilesItem = (
    {
        file
    }: IPreviewLessonAdditionalFilesItem
) => {

    const refDownloadFile = useRef<HTMLAnchorElement>(null)

    const downloadFile = useMutation(
        (id: number) => LessonsService.downloadFile(id),
        {
            onSuccess: (response) => {
                console.log(response)
                if(refDownloadFile.current) {
                    refDownloadFile.current.href = response
                    refDownloadFile.current.click()
                }
            }
        }
    )

    const handleDownloadFile = () => {
        downloadFile.mutate(file.id)
    }

    return (
        <div className="flex justify-between items-center gap-[50px] py-[12px] last:pb-0 first:pt-0 border-b-[2px] border-solid border-[#BDBDBD] last:border-none">
            <div className="flex gap-[6px] items-center overflow-hidden">
                {file.extention === ".youtu" || file.extention === ".link" ? (
                    <LinkIcon className="flex-shrink-0"/>
                ) : (
                    <FileIcon className="flex-shrink-0"/>
                )}
                <div className="text-ellipsis overflow-hidden text-[16px] text-[#292B2C] font-medium whitespace-nowrap leading-[120%]">{file.originalName + file.extention}</div>
            </div>
            {(file.extention === ".youtu" || file.extention === ".link") ? (
                <Link
                    className="px-[16px] py-[8px] text-white text-[16px] font-medium bg-[#17AB57] rounded-[5px]"
                    to={file.pathName}>
                    Перейти
                </Link>
            ) : (
                <>
                    <div
                        onClick={handleDownloadFile}
                        className="cursor-pointer max-lg:w-full max-lg:text-center py-[8px] px-[16px] bg-[#17AB57] rounded-[5px] text-[16px] text-[#FFFFFF] font-bold">
                        Скачать
                    </div>
                    <a ref={refDownloadFile} href="#" className="hidden"></a>
                </>
            )}
        </div>
    )
}

export default PreviewLessonAdditionalFilesItem