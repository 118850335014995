import React, {useState} from "react";
import {ReactComponent as LessonIcon} from "../../../../../../assets/images/folders/lesson-icon.svg";
import {ReactComponent as TestIcon} from "../../../../../../assets/images/folders/test-icon.svg";
import {ILessonsAttachInUser} from "../../../../../../models/lessons";
import {FieldArrayWithId, useFormContext} from "react-hook-form";
import {ReactComponent as CopyIcon} from "../../../../../../assets/images/global/copy-icon.svg";
import {Link} from "react-router-dom";

interface IUserAttachLessonsItemActive {
    index: number
    toggleLessonActive: (lesson: number, e: React.ChangeEvent<HTMLInputElement>, index: number) => void
    item: FieldArrayWithId<ILessonsAttachInUser, "lessons">
}

const UserAttachLessonsItemActive = (
    {
        item,
        toggleLessonActive,
        index
    }: IUserAttachLessonsItemActive
) => {

    const {
        register,
        formState: {errors},
        setValue
    } = useFormContext<ILessonsAttachInUser>()

    const [activeCopy, setActiveCopy] = useState<boolean>(false)

    const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        toggleLessonActive(Number(event.target.value), event, index)
    }

    const handleChangeHour = (e: React.ChangeEvent<HTMLInputElement>) => {
        let name = e.target.name.split('.')

        if(Number(e.target.value) > 0) {
            item.hours = Number(e.target.value)
        } else {
            setValue(`lessons.${Number(name[1])}.hours`, Math.abs(Number(e.target.value)))
            item.hours = Math.abs(Number(e.target.value))
        }

        if(Number(e.target.value) > 9999) {
            item.hours = 9999
            setValue(`lessons.${Number(name[1])}.hours`, 9999)
        }
    }

    const handeClick = () => {
        navigator.clipboard.writeText(item.name)
        setActiveCopy(true)
        setTimeout(() => {
            setActiveCopy(false)
        }, 5000)
    }

    return (
        <div
            className="flex justify-between items-center px-[18px] py-[20px] max-lg:py-[12px] max-lg:px-0 max-xl:flex-col max-xl:gap-[10px]">
            <input type="hidden" {...register(`lessons.${index}.lessonId` as const)}/>
            <input type="hidden" {...register(`lessons.${index}.order` as const)}/>
            <div className="w-[50%] flex gap-[16px] items-center max-xl:w-full">
                {item.testId ?
                    <TestIcon className="flex-shrink-0"/> :
                    <LessonIcon className="flex-shrink-0"/>
                }
                <div
                    className="flex flex-col gap-[6px] text-[16px] font-medium leading-[120%] text-[#BDBDBD]">
                    <div className="flex items-center gap-[5px]">
                        <Link target="_blank" className="hover:underline text-[#292B2C]" to={item.testId ?
                            `/folders/preview-test/${item.lessonId}/${item.testId}?attach-lesson=true` : `/folders/preview-lesson/${item.lessonId}?attach-lesson=true` }>
                            {item.name}
                        </Link>
                        <CopyIcon onClick={handeClick} className={`flex-shrink-0 cursor-pointer ` + (activeCopy ? 'fill-[#219653]' : 'fill-[#BDBDBD]' )}/>
                    </div>
                    <div
                        className="text-[#BDBDBD]">{item.testId ? "Тест" : "Лекция"}</div>
                </div>
            </div>
            <div className="flex w-[50%] max-xl:w-full max-xl:gap-[16px]">
                <div className="w-[50%] max-xl:w-full">
                    <input
                        type="number"
                        placeholder="Часы"
                        {...register(`lessons.${index}.hours` as const, { required: true })}
                        min={0}
                        max={9999}
                        onChange={e => handleChangeHour(e)}
                        className="w-[180px] max-xl:w-full py-[8px] pr-[12px] pl-[10px] rounded-[5px] border-solid border-[1px] border-[#BDBDBD]"/>
                    {errors?.lessons?.[index]?.hours && (
                        <p className="text-red-600">Укажите часы</p>
                    )}
                </div>
                <div className="w-[50%] max-xl:w-auto flex justify-end">
                    <label
                        className="inline-flex items-center cursor-pointer gap-[12px]">
                        <input
                            id={`checkbox-${item.lessonId}`}
                            name={`checkbox-${item.lessonId}`}
                            type="checkbox"
                            value={item.lessonId}
                            className="sr-only peer"
                            checked={true}
                            onChange={(event) => handleChange(event)}/>
                        <span
                            className="text-[16px] font-medium leading-[120%] text-[#292B2C] peer-checked:text-[#BDBDBD] max-xl:hidden">Неактивно</span>
                        <div
                            className="relative
                            w-[44px]
                            h-[26px]
                            bg-[#F2F2F2]
                            rounded-[50px]
                            peer
                            p-[4px]
                            peer-focus:outline-none
                            peer-checked:after:translate-x-full
                            rtl:peer-checked:after:-translate-x-full
                            after:content-['']
                            after:absolute
                            after:top-[4px]
                            after:start-[4px]
                            after:bg-[#BDBDBD]
                            peer-checked:after:bg-[#17AB57]
                            after:rounded-full
                            after:h-[18px]
                            after:w-[18px]
                            after:transition-all"></div>
                        <span
                            className="text-[16px] font-medium leading-[120%] text-[#BDBDBD] peer-checked:text-[#292B2C] max-xl:hidden">Активно</span>
                    </label>
                </div>
            </div>
        </div>
    )
}

export default UserAttachLessonsItemActive
