import {useQuery} from "react-query";
import {UsersService} from "../../../../services/users.service";
import CourseUserSearchItem
    from "../../../screens/admin/courses/course-detail/course-tabs/course-user-list/course-user-search-item/CourseUserSearchItem";
import React from "react";
import ScrollContainer from "../../scroll-container/ScrollContainer";

interface ISearchCourseStudent {
    searchTerm: string
    setShowSearchResult: (show: boolean) => void
    courseId: number
    setSearchTerm: (term: string) => void
    onClearInput: () => void
}

const SearchCourseStudent = (
    {
        searchTerm,
        setShowSearchResult,
        courseId,
        setSearchTerm,
        onClearInput
    }: ISearchCourseStudent
) => {

    const users = useQuery(
        ["search_users", searchTerm],
        () => UsersService.searchCourse(searchTerm, courseId),
        {
            enabled: !!searchTerm,
        }
    )

    return (
        <>
            {users.isLoading && (<div className="text-center">Загрузка</div>)}
            {users.data && (
                <>
                    {users.data.length > 0 ? (
                        <ScrollContainer chat={false} heightModal={"220px"}>
                            <div className="">
                                {users.data.map((user, index) => (
                                    <CourseUserSearchItem
                                        onClearInput={onClearInput}
                                        setSearchTerm={setSearchTerm}
                                        setShowSearchResult={setShowSearchResult}
                                        courseId={Number(courseId)}
                                        user={user}
                                        key={index}/>
                                ))}
                            </div>
                        </ScrollContainer>
                    ) : (
                        <div className="p-[16px] text-center">Ничего не найдено по запросу "{searchTerm}"</div>
                    )}
                </>
            )}
        </>
    )
}

export default SearchCourseStudent