import {Link, useNavigate, useParams, useSearchParams} from "react-router-dom";
import {useQuery} from "react-query";
import {UsersService} from "../../../../../services/users.service";
import React, {useRef, useState} from "react";
import Loader from "../../../../ui/loader/Loader";
import ErrorMessage from "../../../../ui/error/ErrorMessage";
import {ReactComponent as CheckboxUnchecked} from "../../../../../assets/images/tests/checkbox-unchecked.svg";
import {ReactComponent as CheckboxChecked} from "../../../../../assets/images/tests/checkbox-checked.svg";
import {ReactComponent as RadioUnchecked} from "../../../../../assets/images/tests/radio-unchecked.svg";
import {ReactComponent as RadioChecked} from "../../../../../assets/images/tests/radio-checked.svg";
import {AxiosError} from "axios";
import {ReactComponent as ArrowBackIcon} from "../../../../../assets/images/global/arrow-back-icon.svg";
import {LessonsService} from "../../../../../services/lessons.service";
import {IFile} from "../../../../../models/file";

const PreviewTest = () => {

    const {testId, lessonId} = useParams()
    const navigate = useNavigate()
    const ref = useRef<HTMLDivElement>(null)

    const [searchParams, setSearchParams] = useSearchParams();

    const [activeIndexQuestion, setActiveIndexQuestion] = useState<number>(1)
    const [passingTest, setPassingTest] = useState<number[]>([])
    const [answerPassingTest, setAnswerPassingTest] = useState<number[]>([])

    const lesson = useQuery(
        ["lesson_test", lessonId],
        () => LessonsService.getById(Number(lessonId)),
        {
            enabled: !!lessonId
        }
    )

    const test = useQuery(
        ["test_passing", testId, lessonId],
        () => UsersService.getQuestionsForPassingTest(Number(testId), Number(lessonId)),
        {
            enabled: !!testId && !!lessonId
        }
    )

    if (test.isLoading) return <Loader/>
    if (lesson.isLoading) return <Loader/>

    if (test.isError) {
        const e = test.error as AxiosError
        return <ErrorMessage numberError={e.response ? e.response.status : 400}/>
    }
    if (lesson.isError) {
        const e = lesson.error as AxiosError
        return <ErrorMessage numberError={e.response ? e.response.status : 400}/>
    }

    if (!test.data) return <div className="text-center">Ошибка данных</div>
    if (!lesson.data) return <div className="text-center">Ошибка данных</div>

    const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        setAnswerPassingTest(prev => [...prev, Number(e.target.name.split(".")[1])])
    }

    const updateActiveQuestion = (nextIndex: number, prevIndex: number) => {
        setActiveIndexQuestion(nextIndex)
        if(answerPassingTest.indexOf(test.data.questions[prevIndex - 1].id) !== -1) {
            setPassingTest(prev => [...prev, prevIndex])
        }
    }

    return (
        <>
            <div className="flex h-full gap-[24px] flex-col">
                <div className="flex justify-between items-center">
                    <div
                        className="text-[#292B2C] text-[36px] font-bold leading-[120%]">
                        <div className="">Предосмотр теста {test.data.name}</div>
                        <div className="">Вопрос {activeIndexQuestion} из {test.data.questions.length}</div>
                    </div>

                    {!searchParams.get("attach-lesson") && (
                        <Link
                            to={`/folders/courses/${lesson.data.courseId}`}
                            className="flex gap-[6px] py-[8px] px-[12px] max-lg:hidden border-solid border-[1px] border-[#17AB57] rounded-[5px] cursor-pointer items-center">
                            <ArrowBackIcon/>
                            Назад
                        </Link>
                    )}
                </div>

                <div className="flex gap-[12px] lg:h-full min-h-0 max-2xl:flex-col">
                    <div ref={ref} className="lg:h-full flex flex-col gap-[24px] w-full">
                        {test.data.questions.map((question, index) => (
                            <div
                                key={index}
                                className={`w-full p-[32px] max-lg:p-[14px] rounded-[6px] bg-white flex-col gap-[18px] hidden ` + (activeIndexQuestion == (index + 1) ? " !flex" : "")}>
                                <div
                                    className="leading-[120%] text-[#292B2C] text-[24px] font-medium max-lg:text-[20px]">
                                    {question.name}
                                </div>
                                <div className="flex flex-col gap-[12px]">
                                    {question.options.map((option, indexOption) => (
                                        <div
                                            key={indexOption}
                                            className="rounded-[4px] bg-[#F2F2F2] px-[10px] py-[12px] flex items-center justify-between">
                                            {question.countCorrectAnswer > 1 ? (
                                                <label className="flex items-center gap-[6px] cursor-pointer w-full">
                                                    <input
                                                        onChange={(e) => handleChange(e)}
                                                        name={`answer.${question.id}`}
                                                        value={option.id}
                                                        className="hidden peer"
                                                        type="checkbox"/>
                                                    <CheckboxUnchecked className="block peer-checked:hidden flex-shrink-0"/>
                                                    <CheckboxChecked className="hidden peer-checked:block flex-shrink-0"/>
                                                    {option.name}
                                                </label>
                                            ) : (
                                                <label className="flex items-center gap-[6px] cursor-pointer w-full">
                                                    <input
                                                        onChange={(e) => handleChange(e)}
                                                        name={`answer.${question.id}`}
                                                        value={option.id}
                                                        className="hidden peer"
                                                        type="radio"/>
                                                    <RadioUnchecked className="block peer-checked:hidden flex-shrink-0"/>
                                                    <RadioChecked className="hidden peer-checked:block flex-shrink-0"/>
                                                    {option.name}
                                                </label>
                                            )}
                                        </div>
                                    ))}
                                </div>
                                <div className="flex gap-[16px] justify-end max-lg:flex-col">
                                    {index != 0 && (
                                        <div
                                            onClick={() => updateActiveQuestion(activeIndexQuestion - 1, activeIndexQuestion)}
                                            className="rounded-[5px] px-[16px] py-[8px] text-center w-[240px] text-[#292B2C] text-[16px] leading-[120%] font-medium cursor-pointer border-solid border-[1px] border-[#17AB57] max-lg:w-full">
                                            Предыдущий вопрос
                                        </div>
                                    )}
                                    {index != (test.data.questions.length - 1) && (
                                        <div
                                            onClick={() => updateActiveQuestion(activeIndexQuestion + 1, activeIndexQuestion)}
                                            className="rounded-[5px] px-[16px] py-[8px] text-center w-[240px] text-white text-[16px] leading-[120%] font-medium cursor-pointer bg-[#17AB57] max-lg:w-full">
                                            Следующий вопрос
                                        </div>
                                    )}
                                </div>
                            </div>
                        ))}
                    </div>
                    <div
                        className="w-[470px] flex flex-col gap-[10px] flex-shrink-0 max-2xl:w-full max-lg:pb-[24px]">
                        <div className="rounded-[5px] bg-white p-[32px] flex flex-col gap-[24px]">
                            <div className="text-[#292B2C] text-[24px] leading-[120%] font-bold">Вопросы</div>
                            <div
                                className="flex gap-x-[14px] gap-y-[14px] flex-wrap text-[#292B2C] text-[16px] leading-[120%] font-medium">
                                {test.data.questions.map((question, index) => (
                                    <div
                                        key={index}
                                        onClick={() => updateActiveQuestion(index + 1, activeIndexQuestion)}
                                        className={`cursor-pointer flex justify-center items-center h-[32px] w-[32px] flex-shrink-0 rounded-[5px] ` +
                                            (activeIndexQuestion == (index + 1) ? "text-white bg-[#17AB57]" : (passingTest.includes(index + 1) ? "bg-[#E0E0E0]" : ""))}>
                                        {index + 1}
                                    </div>
                                ))}
                            </div>
                        </div>
                        <Link
                            to={`/folders/courses/${lesson.data.courseId}`}
                            className="w-full text-center px-[16px] py-[12px] border-solid border-[1px] border-[#17AB57] rounded-[5px] cursor-pointer text-[#292B2C] text-[20px] leading-[120%] font-medium">
                            Завершить тестирование
                        </Link>
                    </div>
                </div>
            </div>
        </>
    )
}

export default PreviewTest