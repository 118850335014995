import {useMutation, useQuery, useQueryClient} from "react-query";
import {ChatService} from "../../../services/chat.service";
import {ReactComponent as SearchIcon} from "../../../assets/images/global/searcg-icon.svg";
import React, {useContext, useState} from "react";
import Cookies from "universal-cookie";
import Loader from "../../ui/loader/Loader";
import {AxiosError} from "axios";
import ErrorMessage from "../../ui/error/ErrorMessage";
import ChatsItem from "./chat-item/ChatsItem";
import ScrollContainer from "../../ui/scroll-container/ScrollContainer";
import ChatDetail from "./chat-detail/ChatDetail";
import {HttpTransportType, HubConnectionBuilder} from "@microsoft/signalr";
import {Constants} from "../../../constans/constants";
import {AuthContext} from "../../../context/AuthContext";

const Chats = () => {

    const hubConnection = new HubConnectionBuilder()
        .withUrl(Constants.LOCATION + "/chat")
        .withAutomaticReconnect()
        .build();

    const cookies = new Cookies()
    const queryClient = useQueryClient();

    const [blurSearch, setBlurSearch] = useState<boolean>(false)
    const [activeChatId, setActiveChatId] = useState<number | null>(null)
    const [search, setSearch] = useState<string>("")
    const {authenticated, setAuthenticated, currentRoleId, currentUserId} = useContext(AuthContext)

    const listChats = useQuery(
        ["list_methodist", Number(currentUserId)],
        () => ChatService.getListByMethodist(Number(currentUserId)),
        {
            // refetchOnWindowFocus: true,
            onSuccess: async (response) => {
                await hubConnection.start()
                    .then((connection) => {
                    })
                    .catch((err) => {
                        console.error('Connection failed: ', err);
                    });
            }
        }
    )

    hubConnection.on("UpdateListMethodist", function (response: number) {
        if (response) {
            queryClient.invalidateQueries(["list_methodist", Number(response)]);
        }
    });

    if (listChats.isLoading) return <Loader/>

    if (listChats.isError) {
        const e = listChats.error as AxiosError
        return <ErrorMessage numberError={e.response ? e.response.status : 400}/>
    }

    if (!listChats.data) return <ErrorMessage numberError={400}/>

    return (
        <div className="flex w-full h-full max-lg:border-solid max-lg:border-b-[2px] max-lg:border-[#BDBDBD]">
            <div
                className={`w-[400px] bg-white h-full border-solid border-r-[2px] border-[#BDBDBD] flex flex-col flex-shrink-0 max-lg:w-full max-lg:border-none ${activeChatId !== null ? "max-lg:hidden" : ""}`}>
                <div className="border-b-[2px] border-[#BDBDBD] py-[12px] px-[16px] flex flex-col gap-[10px]">
                    <span className="text-[24px] font-bold leading-[120%]">Сообщения</span>
                    <div className="relative w-full">
                        <SearchIcon
                            className={`absolute top-[8px] left-[10px] ` + (blurSearch ? "fill-[#292B2C]" : "")}/>
                        <input
                            onFocus={() => {
                                setBlurSearch(true)
                            }}
                            onBlur={() => {
                                setBlurSearch(false)
                            }}
                            onChange={(e) => setSearch(e.target.value)}
                            type="text"
                            placeholder="Поиск"
                            className="py-[8px] pl-[36px] pr-[12px] border-solid border-[1px] border-[#BDBDBD] rounded-[5px] w-full focus:border-[#17AB57] focus:outline-none"/>
                    </div>
                </div>
                <ScrollContainer chat={false}>
                    <div className="flex-col flex">
                        {listChats.data
                            .filter(item => item.lastName.toLowerCase().includes(search.toLowerCase()) || item.lastName.toLowerCase().includes(search.toLowerCase()))
                            .map((item, i) => (
                                <ChatsItem chat={item} setActiveChat={setActiveChatId} activeChatId={activeChatId}
                                           key={i}/>
                            ))}
                    </div>
                </ScrollContainer>
            </div>
            <div className={`bg-[#F2F2F2] h-full w-full ${activeChatId !== null ? "max-lg:block" : "max-lg:hidden"}`}>
                {activeChatId !== null && (
                    <ChatDetail setActiveChatId={setActiveChatId} activeChat={activeChatId}/>
                )}
            </div>
        </div>

    )
}

export default Chats