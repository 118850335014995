import {NavLink} from "react-router-dom";
import React, {useContext, useEffect, useState} from "react";
import {AuthContext} from "../../../context/AuthContext";
import Cookies from "universal-cookie"
import {ReactComponent as LogoPicture} from "../../../assets/images/global/logo.svg"
import {ReactComponent as CoursesIcon} from "../../../assets/images/menu/courses-icon.svg"
import {ReactComponent as TestsIcon} from "../../../assets/images/menu/tests-icon.svg"
import {ReactComponent as UsersIcon} from "../../../assets/images/menu/users-icon.svg"
import {ReactComponent as GroupsIcon} from "../../../assets/images/menu/groups-icon.svg"
import {ReactComponent as LessonIcon} from "../../../assets/images/folders/lesson-icon.svg";
import {ReactComponent as Chat} from "../../../assets/images/menu/chat.svg"
import {ReactComponent as Logout} from "../../../assets/images/menu/logout.svg"
import {Constants} from "../../../constans/constants";
import {useQuery} from "react-query";
import {EventService} from "../../../services/event.service";
import {AxiosError} from "axios";
import ErrorMessage from "../error/ErrorMessage";
import Select from "react-select";
import {ChatService} from "../../../services/chat.service";

const Navigation = () => {

    const {authenticated, setAuthenticated, currentRoleId, currentUserId} = useContext(AuthContext)
    const cookies = new Cookies()
    const [countEvent, setCountEvent] = useState<number>(0)
    const [showSelectDomain, setShowSelectDomain] = useState<boolean>(false)

    const role = currentRoleId

    const handleLogout = () => {
        cookies.remove("token", {path: "/"})
        cookies.remove("role", {path: "/"})
        cookies.remove("user", {path: "/"})
        cookies.remove("refreshToken", {path: "/"})
        cookies.remove("refreshTokenExpiry", {path: "/"})
        setAuthenticated(false)
    }

    const classNameLink = "group pt-[12px] pl-[12px] pb-[12px] pr-[14px] flex gap-[8px] items-center rounded-[5px] text-[#BDBDBD] hover:text-[#000] max-lg:flex-col max-lg:p-[8px] max-lg:gap-0 max-sm:text-[12px]"
    const activeClassNameLink = " bg-[#17AB57] text-[#FFFFFF] hover:text-[#FFFFFF] max-lg:bg-inherit max-lg:text-[#17AB57] max-lg:hover:text-[#17AB57]"
    const classSvg = "fill-[#BDBDBD] group-hover:fill-[#000000]"
    const activeClassSvg = "fill-[#FFFFFF] group-hover:fill-[#FFFFFF] max-lg:fill-[#17AB57] max-lg:group-hover:fill-[#17AB57]"

    const activeLink = ({isActive}: { isActive: boolean }): string => {
        return isActive ? (classNameLink + activeClassNameLink) : classNameLink
    }

    useEffect(() => {
        Constants.LIST_APOK_DOMAIN.map((element) => {
            if(element.value.indexOf(window.location.host) !== -1) {
                setShowSelectDomain(true)
            }
        })
        Constants.LIST_TEST_DOMAIN.map((element) => {
            if(element.value.indexOf(window.location.host) !== -1) {
                setShowSelectDomain(true)
            }
        })
        Constants.LIST_LOCAL_DOMAIN.map((element) => {
            if(element.value.indexOf(window.location.host) !== -1) {
                setShowSelectDomain(true)
            }
        })
    }, [])

    const onChange = (data: any) => {
        window.open("https://" + data.value, '_blank')
    }

    const event = useQuery(
        ["count_event", currentUserId],
        () => EventService.getUnreadEvent(Number(currentUserId)),
        {
            enabled: !!cookies.get("user") && Constants.LIST_ADMIN_ROLE.indexOf(Number(currentRoleId)) !== -1,
            onSuccess: (response) => {
                setCountEvent(response)
            }
        }
    )

    const countMessage = useQuery(
        ["count_unread_message", currentUserId],
        () => ChatService.getCountUnreadMessage(Number(currentUserId)),
        {
            refetchOnWindowFocus: true,
            enabled: !!cookies.get("user"),
            onSuccess: (response) => {

            }
        }
    )

    if (event.isError) {
        const e = event.error as AxiosError
        return <ErrorMessage numberError={e.response ? e.response.status : 400}/>
    }

    if (countMessage.isError) {
        const e = countMessage.error as AxiosError
        return <ErrorMessage numberError={e.response ? e.response.status : 400}/>
    }

    return (
        <>
            {authenticated && (
                <div
                    className="flex justify-between flex-col p-[36px] bg-[#FFF] border-r-[2px] border-[#BDBDBD] max-lg:p-0 max-lg:border-r-0 max-lg:sticky max-lg:bottom-0 max-lg:z-[50]">
                    <div className="flex flex-col gap-[36px]">
                        <LogoPicture className="max-lg:hidden"/>
                        <div
                            className="flex justify-around flex-col gap-[8px] max-lg:flex-row max-lg:gap-0 max-lg:overflow-x-scroll">
                            {Number(role) === Constants.STUDENT_ROLE && (
                                <>
                                    <NavLink className={activeLink} to="/student-courses">
                                        {
                                            ({isActive}: { isActive: boolean }) => (
                                                <>
                                                    <CoursesIcon className={isActive ? activeClassSvg : classSvg}/>
                                                    Курсы
                                                </>
                                            )
                                        }
                                    </NavLink>
                                    {countMessage.data && countMessage.data.chatId && (
                                        <NavLink className={activeLink} to={`/messages/${countMessage.data?.chatId}`}>
                                            {
                                                ({isActive}: { isActive: boolean }) => (
                                                    <>
                                                        <Chat className={isActive ? activeClassSvg : classSvg}/>
                                                        Сообщения {countMessage.data && countMessage.data.count > 0 && (
                                                        <div className={`px-[4px] py-[2px] rounded-[4px] h-[24px] flex items-center justify-center font-bold text-[14px]  ${isActive ? "bg-white text-[#17AB57]" : "bg-[#17AB57] text-white"}`}>
                                                            {countMessage.data.count}
                                                        </div>
                                                    )}
                                                    </>
                                                )
                                            }
                                        </NavLink>
                                    )}
                                </>
                            )}
                            {Number(role) === Constants.SUPERVISOR_ROLE && (
                                <>
                                    <NavLink className={activeLink} to="/student-courses">
                                        {
                                            ({isActive}: { isActive: boolean }) => (
                                                <>
                                                    <CoursesIcon className={isActive ? activeClassSvg : classSvg}/>
                                                    Курсы
                                                </>
                                            )
                                        }
                                    </NavLink>
                                    <NavLink className={activeLink} to="/supervisor-group">
                                        {
                                            ({isActive}: { isActive: boolean }) => (
                                                <>
                                                    <GroupsIcon className={isActive ? activeClassSvg : classSvg}/>
                                                    Моя команда
                                                </>
                                            )
                                        }
                                    </NavLink>
                                    {countMessage.data && countMessage.data.chatId && (
                                        <NavLink className={activeLink} to={`/messages/${countMessage.data.chatId}`}>
                                            {
                                                ({isActive}: { isActive: boolean }) => (
                                                    <>
                                                        <Chat className={isActive ? activeClassSvg : classSvg}/>
                                                        Сообщения {countMessage.data && countMessage.data.count > 0 && (
                                                        <div className={`px-[4px] py-[2px] rounded-[4px] h-[24px] flex items-center justify-center font-bold text-[14px]  ${isActive ? "bg-white text-[#17AB57]" : "bg-[#17AB57] text-white"}`}>
                                                            {countMessage.data.count}
                                                        </div>
                                                    )}
                                                    </>
                                                )
                                            }
                                        </NavLink>
                                    )}

                                </>
                            )}
                            {Constants.LIST_ADMIN_ROLE.indexOf(Number(role)) !== -1 && (
                                <>
                                    <NavLink className={activeLink} to="/folders">
                                        {
                                            ({isActive}: { isActive: boolean }) => (
                                                <>
                                                    <CoursesIcon className={isActive ? activeClassSvg : classSvg}/>
                                                    Курсы
                                                </>
                                            )
                                        }
                                    </NavLink>
                                    <NavLink className={activeLink} to="/lessons">
                                        {
                                            ({isActive}: { isActive: boolean }) => (
                                                <>
                                                    <LessonIcon className={isActive ? activeClassSvg : classSvg}/>
                                                    Уроки
                                                </>
                                            )
                                        }
                                    </NavLink>
                                    <NavLink className={activeLink} to="/tests">
                                        {
                                            ({isActive}: { isActive: boolean }) => (
                                                <>
                                                    <TestsIcon className={isActive ? activeClassSvg : classSvg}/>
                                                    Тесты
                                                </>
                                            )
                                        }
                                    </NavLink>
                                    <NavLink className={activeLink} to="/users">
                                        {
                                            ({isActive}: { isActive: boolean }) => (
                                                <>
                                                    <UsersIcon className={isActive ? activeClassSvg : classSvg}/>
                                                    Пользователи
                                                </>
                                            )
                                        }
                                    </NavLink>
                                    <NavLink className={activeLink} to="/groups">
                                        {
                                            ({isActive}: { isActive: boolean }) => (
                                                <>
                                                    <GroupsIcon className={isActive ? activeClassSvg : classSvg}/>
                                                    Группы
                                                </>
                                            )
                                        }
                                    </NavLink>
                                    <NavLink className={activeLink} to="/events">
                                        {
                                            ({isActive}: { isActive: boolean }) => (
                                                <>
                                                    <GroupsIcon className={isActive ? activeClassSvg : classSvg}/>
                                                    События {countEvent != 0 && (<>({countEvent})</>)}
                                                </>
                                            )
                                        }
                                    </NavLink>
                                    <NavLink className={activeLink} to="/messages">
                                        {
                                            ({isActive}: { isActive: boolean }) => (
                                                <>
                                                    <Chat className={isActive ? activeClassSvg : classSvg}/>
                                                    Сообщения {countMessage.data && countMessage.data.count > 0 && (
                                                        <div className={`px-[4px] py-[2px] rounded-[4px] h-[24px] flex items-center justify-center font-bold text-[14px]  ${isActive ? "bg-white text-[#17AB57]" : "bg-[#17AB57] text-white"}`}>
                                                            {countMessage.data.count}
                                                        </div>
                                                        )}
                                                </>
                                            )
                                        }
                                    </NavLink>
                                </>
                            )}
                            {Number(role) === Constants.ADMIN_ROLE && (
                                <>
                                    <NavLink className={activeLink} to="/company">
                                        {
                                            ({isActive}: { isActive: boolean }) => (
                                                <>
                                                    <CoursesIcon className={isActive ? activeClassSvg : classSvg}/>
                                                    Компании
                                                </>
                                            )
                                        }
                                    </NavLink>
                                </>
                            )}
                            {/*<NavLink className={activeLink} to="/feedback">*/}
                            {/*    {*/}
                            {/*        ({isActive}: { isActive: boolean }) => (*/}
                            {/*            <>*/}
                            {/*                <HelpIcon*/}
                            {/*                    className={isActive ? "fill-[#FFFFFF] group-hover:fill-[#FFFFFF]" : "fill-[#BDBDBD] group-hover:fill-[#000000]"}/>*/}
                            {/*                Помощь*/}
                            {/*            </>*/}
                            {/*        )*/}
                            {/*    }*/}
                            {/*</NavLink>*/}
                        </div>
                    </div>
                    <div className="">
                        {showSelectDomain && Constants.LIST_ADMIN_ROLE.indexOf(Number(role)) !== -1 && (
                            <div className="max-lg:hidden text-[#BDBDBD]">
                                <label className="py-[12px] inline-block">Сменить домен</label>
                                <Select
                                    theme={(theme) => ({
                                        ...theme,
                                        borderRadius: 5,
                                        colors: {
                                            ...theme.colors,
                                            primary: "#17AB57",
                                            primary25: "#17AB571a"
                                        }
                                    })}
                                    className=""
                                    value={
                                        Constants.LIST_APOK_DOMAIN.filter(option =>
                                            option.value === window.location.host)
                                    }
                                    onChange={onChange}
                                    menuPlacement="top"
                                    options={Constants.LIST_APOK_DOMAIN}/>
                            </div>
                        )}
                        <div onClick={handleLogout} className={classNameLink + " cursor-pointer max-lg:hidden"}>
                            <Logout className="group-hover:fill-[#000000]"/>
                            Выйти
                        </div>
                    </div>
                </div>
            )}
            {!authenticated && (
                <div
                    className="flex justify-between flex-col p-[36px] bg-[#FFF] border-r-[2px] border-[#BDBDBD] max-lg:p-0 max-lg:border-r-0 max-lg:sticky max-lg:bottom-0 max-lg:z-[50]">
                    <div className="flex flex-col gap-[36px]">
                        <LogoPicture className="max-lg:hidden"/>
                        <div
                            className="flex justify-around flex-col gap-[8px] max-lg:flex-row max-lg:gap-0">
                            <NavLink className={activeLink} to="/login">
                                {
                                    ({isActive}: { isActive: boolean }) => (
                                        <>
                                            <UsersIcon
                                                className={isActive ? activeClassSvg : classSvg}/>
                                            Авторизация
                                        </>
                                    )
                                }
                            </NavLink>
                            <NavLink className={activeLink} to="/document">
                                {
                                    ({isActive}: { isActive: boolean }) => (
                                        <>
                                            <TestsIcon
                                                className={isActive ? activeClassSvg : classSvg}/>
                                            Документы
                                        </>
                                    )
                                }
                            </NavLink>
                        </div>
                    </div>
                </div>
            )}
        </>
    )
}

export default Navigation