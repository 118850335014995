import {useCallback, useState} from "react";

type Toggle = [boolean, () => void, () => void, () => void];

export default function useToggle() : Toggle {
    const [isToggled, setToggled] = useState<boolean>(false);
    const onToggleOn = useCallback(() => setToggled(true), [setToggled]);
    const onToggleOff = useCallback(() => setToggled(false), [setToggled]);
    const onToggle = useCallback(() => setToggled(!isToggled), [
        isToggled,
        setToggled
    ]);

    return [isToggled, onToggleOn, onToggleOff, onToggle];
}