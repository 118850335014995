import Cookies from "universal-cookie"

export default function AuthHeader() {

    const cookies = new Cookies()

    let domain = ""
    let location = window.location
    let hostname = location.hostname
    let subdomain = hostname.split(".")
    if(subdomain[subdomain.length - 1] === "ru" && subdomain.length === 3) {
        domain = subdomain[0]
    } else if(subdomain[subdomain.length - 1] !== "ru" && subdomain.length === 2) {
        domain = subdomain[0]
    }

    const token = cookies.get("token")
    if (token) {
        return {Authorization: "Bearer " + token, Domain: domain}
    } else {
        return {Authorization: "", Domain: domain}
    }
}