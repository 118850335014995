import React from "react";
import TestMainInformation from "./test-main-information/TestMainInformation";
import {ITest} from "../../../../../../models/tests";
import TestsQuestions from "./tests-questions/TestsQuestions";
import ScrollContainer from "../../../../../ui/scroll-container/ScrollContainer";

interface ITestsTabs {
    activeTab: number
    test: ITest
}

const TestsTabs = (
    {
        activeTab,
        test
    }: ITestsTabs
) => {
    return (
        <>
            <div
                className={`bg-white p-[32px] rounded-[6px] flex flex-col text-[16px] font-medium max-lg:p-[14px] max-lg:gap-0 overflow-hidden h-full` + (activeTab == 1 ? " block" : " hidden")}>
                {/*<ScrollContainer>*/}
                    <div className="h-full">
                        <TestMainInformation test={test}/>
                    </div>
                {/*</ScrollContainer>*/}
            </div>
            <div
                className={`bg-white p-[32px] rounded-[6px] flex flex-col text-[16px] font-medium max-lg:p-[14px] max-lg:gap-0 overflow-hidden h-full` + (activeTab == 2 ? " block" : " hidden")}>
                <TestsQuestions test={test}/>
            </div>
        </>
    )
}

export default TestsTabs