import {IGroup, IGroupDetailId} from "../../../../../models/groups";
import {ReactComponent as UsersIcon} from "../../../../../assets/images/menu/users-icon.svg";
import React, {useContext, useEffect, useRef, useState} from "react";
import {ReactComponent as ActionIcon} from "../../../../../assets/images/global/action-icon-horizontal.svg";
import {ReactComponent as EditIcon} from "../../../../../assets/images/global/edit-icon.svg";
import {ReactComponent as DeleteIcon} from "../../../../../assets/images/folders/delete-folder-icon.svg";
import useToggle from "../../../../../hooks/useToggle";
import ModalRenameGroup from "../../../../ui/modal/modal-rename-group/ModalRenameGroup";
import {useMutation} from "react-query";
import {GroupsService} from "../../../../../services/groups.service";
import {Link} from "react-router-dom";
import {LoaderContext} from "../../../../../context/LoaderContext";
import Loader from "../../../../ui/loader/Loader";
import {AxiosError} from "axios";

interface IGroupsItem {
    group: IGroup
    parentParameter: DOMRect
    onDelete: (id: number) => void
    setError: (error: number|null) => void
}

const GroupsItem = (
    {
        group,
        parentParameter,
        onDelete,
        setError
    }: IGroupsItem
) => {

    const {show, setShow} = useContext(LoaderContext)
    const [showMenu, setShowMenu] = useState<boolean>(false)
    const [bottomMenu, setBottomMenu] = useState<boolean>(true)
    const ref = useRef<HTMLDivElement>(null)
    const [isModalUpdateGroup, openIsModalUpdateGroup, closeIsModalUpdateGroup] = useToggle()
    const [groupItem, setGroupItem] = useState<IGroup>({} as IGroup)

    useEffect(() => {
        setGroupItem(group)
    }, [group])

    useEffect(() => {
        if (ref.current) {
            const hParent = parentParameter.height + parentParameter.y
            const hMenu = ref.current.getBoundingClientRect().y + 15 + 80
            if (hMenu > hParent)
                setBottomMenu(false)
        }
    }, [parentParameter])

    const handleClick = () => {
        setShowMenu(!showMenu)
    }

    useEffect(() => {
        if (!showMenu) return
        handleClickRef(ref, handleClick)
    }, [showMenu])

    const handleClickRef = (ref: React.RefObject<HTMLDivElement>, handleSet: () => void) => {
        const handleClick = (e: MouseEvent) => {
            let element = e.target as HTMLDivElement
            if (!ref.current) return
            if (!ref.current.contains(element)) {
                handleSet()
            }
        }

        document.addEventListener('click', handleClick)
        return () => {
            document.removeEventListener('click', handleClick)
        }
    }

    const handleRename = () => {
        handleClick()
        openIsModalUpdateGroup()
    }

    const deleteGroup = useMutation(
        (data: IGroupDetailId) => GroupsService.delete(data),
        {
            onSuccess: () => {
                setShow(false)
                onDelete(groupItem.id)
                setError(null)
            },
            onError: (error) => {
                setShow(false)
                const e = error as AxiosError
                setError(e.response ? e.response.status : 400)
            }
        }
    )

    const handleDelete = () => {
        let deleteSuccess = window.confirm("Вы действительно хотите удалить папку? Это действие необратимо")
        if(deleteSuccess) {
            setShow(true)
            const data: IGroupDetailId = {
                "id": groupItem.id
            }
            deleteGroup.mutate(data)
            handleClick()
        } else {
            handleClick()
        }
    }

    return (
        <div
            className="text-[#292B2C] items-center flex justify-between max-lg:gap-[10px] text-[16px] font-medium leading-[120%] border-solid border-b-[2px] border-[#BDBDBD] last:border-none py-[16px] first:pt-0 last:pb-0">
            {show && <Loader/>}
            <div className="flex items-center gap-[8px]">
                <UsersIcon className="flex-shrink-0" fill="#17AB57"/>
                <Link to={`/groups/${groupItem.id}`} className="max-lg:flex-col max-lg:flex">
                    {groupItem.name} <span className="text-[#BDBDBD]">({groupItem.countUsers} пользователей)</span>
                </Link>
            </div>
            <div ref={ref} className="w-[6%] flex justify-end relative">
                <ActionIcon className="flex-shrink-0 cursor-pointer" onClick={handleClick}/>
                <div
                    className={`absolute right-[10px] bg-white rounded-[6px] shadow-[0_5px_10px_0_rgba(0,0,0,0.37)] z-[90]` + (showMenu ? " block" : " hidden") + (bottomMenu ? " top-[15px]" : " bottom-[20px]")}>
                    <div
                        onClick={handleRename}
                        className="px-[12px] py-[10px] flex gap-[12px] items-center hover:bg-[#17AB571a] cursor-pointer">
                        <EditIcon/>
                        Переименовать
                    </div>
                    <div
                        onClick={handleDelete}
                        className="px-[12px] py-[10px] flex gap-[12px] items-center hover:bg-[#17AB571a] cursor-pointer">
                        <DeleteIcon/>
                        Удалить
                    </div>
                </div>
            </div>
            {isModalUpdateGroup &&
                <ModalRenameGroup detail={false} setGroup={setGroupItem} onClose={closeIsModalUpdateGroup} group={group}/>}
        </div>
    )
}

export default GroupsItem