import {IQuestionDetail, IQuestionDetailId, ITest, ITestDetail, ITestLeft} from "../models/tests";
import axios from "axios";
import AuthHeader from "./auth-header";
import {Constants} from "../constans/constants";

export const TestsService = {
    async getAll() {
        const { data } = await axios.get<ITest[]>(Constants.LOCATION + `/api/Test`, {headers: AuthHeader()})
        return data
    },

    async getByPage(page: number, search?: string) {
        const { data } = await axios.get<ITestLeft>(Constants.LOCATION + `/api/Test/page/${page}/${search}`, {headers: AuthHeader()})
        return data
    },

    async getById(id: number | undefined) {
        const response = await axios.get<ITest>(Constants.LOCATION + `/api/Test/${id}`, {headers: AuthHeader()})
        return response.data
    },

    async create(data: ITestDetail) {
        const response = await axios.post<ITest>(Constants.LOCATION + `/api/Test/add`, data, {headers: AuthHeader()})
        return response.data
    },

    async update(data: ITestDetail, id: number) {
        const response = await axios.post<ITest>(Constants.LOCATION + `/api/Test/update/${id}`, data, {headers: AuthHeader()})
        return response.data
    },

    async createQuestions(id: number, data: IQuestionDetail[]) {
        const response = await axios.post<ITest>(Constants.LOCATION + `/api/Test/questions/${id}`, data, {headers: AuthHeader()})
        return response.data
    },

    async delete(data: IQuestionDetailId) {
        return await axios.post<boolean>(Constants.LOCATION + `/api/Test/delete/`, data, {headers: AuthHeader()})
    }
}