import {SubmitHandler, useForm} from "react-hook-form";
import {ITest, ITestDetail} from "../../../../../../../models/tests";
import {useContext, useEffect, useState} from "react";
import {useParams} from "react-router-dom";
import {useMutation, useQueryClient} from "react-query";
import {TestsService} from "../../../../../../../services/tests.service";
import {LoaderContext} from "../../../../../../../context/LoaderContext";
import Loader from "../../../../../../ui/loader/Loader";

interface ITestMainInformation {
    test: ITest
}

const TestMainInformation = (
    {
        test
    }: ITestMainInformation
) => {

    const {show, setShow} = useContext(LoaderContext)
    const [save, setSave] = useState<boolean>(false)
    const {id} = useParams()
    const queryClient = useQueryClient()

    const {
        register,
        setValue,
        handleSubmit,
        formState: {errors}
    } = useForm<ITest>()

    useEffect(() => {
        if(test) {
            setValue("name", test.name)
        }
    }, [test])

    useEffect(() => {
        const timer = setTimeout(() => {
            setSave(false)
        }, 10000)
        return () => clearTimeout(timer)
    }, [save])

    const updateTest = useMutation(
        (data: ITestDetail) => TestsService.update(data, Number(id)),
        {
            onSuccess: () => {
                queryClient.invalidateQueries(["test", Number(id)])
                queryClient.invalidateQueries("tests")
                setShow(false)
            }
        }
    )

    const onSubmit: SubmitHandler<ITestDetail> = async (data) => {
        setShow(true)
        updateTest.mutate(data)
    }

    const classNameFieldForm = "flex gap-[18px] py-[20px] border-b-[2px] border-solid border-[#BDBDBD] first:pt-0 last:pb-0 last:border-none max-lg:flex-col"

    return (
        <form className="flex flex-col gap-[18px] w-[50%] max-2xl:w-[75%] max-lg:w-full" onSubmit={handleSubmit(onSubmit)}>
            {show && <Loader/>}
            <h2 className="leading-[120%] text-[24px] font-bold">Основная информация</h2>
            <div>
                <div className={classNameFieldForm}>
                    <div className="w-[170px] flex-shrink-0">Название курса</div>
                    <input
                        className="py-[8px] pl-[12px] pr-[10px] border-[1px] border-solid border-[#BDBDBD] w-full rounded-[5px]"
                        placeholder="Введите название курса"
                        {...register("name", {required: true})}/>
                    {errors.name && errors.name.type === "required" && (
                        <p className="text-red-600">Название курса обязательно</p>
                    )}
                </div>
                <div className={classNameFieldForm}>
                    <div className="w-[170px] flex-shrink-0">Количество вопросов</div>
                    <input
                        className="py-[8px] pl-[12px] pr-[10px] border-[1px] border-solid border-[#BDBDBD] w-full rounded-[5px]"
                        placeholder="Введите название курса"
                        disabled
                        value={test.testQuestions?.length}
                        />
                </div>
            </div>
            <div className="flex gap-[18px] justify-end">
                <div className={`py-[8px] px-[16px] bg-green-500 rounded-[5px] text-white text-[16px] font-medium` + (save ? " block" : " hidden")}>Сохранено</div>
                <button
                    type="submit"
                    className="py-[8px] px-[16px] bg-[#17AB57] rounded-[5px] text-white text-[16px] font-medium">
                    Сохранить
                </button>
            </div>
        </form>
    )
}

export default TestMainInformation