import {ReactComponent as DeleteUser} from "../../../../../../../assets/images/global/close-modal-icon.svg";
import React, {useRef, useState} from "react";
import {useMutation, useQuery, useQueryClient} from "react-query";
import {useParams} from "react-router-dom";
import {CoursesService} from "../../../../../../../services/courses.service";
import Loader from "../../../../../../ui/loader/Loader";
import CourseUserListItem from "./course-user-list-item/CourseUserListItem";
import {ICoursesDetailId} from "../../../../../../../models/courses";
import SearchInput from "../../../../../../ui/search-input/SearchInput";
import SearchCourseStudent from "../../../../../../ui/search-input/search-course-student/SearchCourseStudent";
import {AxiosError} from "axios";
import ErrorMessage from "../../../../../../ui/error/ErrorMessage";
import ScrollContainer from "../../../../../../ui/scroll-container/ScrollContainer";

const CourseUserList = () => {

    const [searchTerm, setSearchTerm] = useState<string>("")
    const [showSearchResult, setShowSearchResult] = useState<boolean>(false)
    const refSearch = useRef<HTMLInputElement>(null)
    const [error, setError] = useState<number>(400)

    const queryClient = useQueryClient()

    const onClearInput = () => {
        if (refSearch.current)
            refSearch.current.value = ""
    }

    const {courseId} = useParams()

    const users = useQuery(
        ["users_course", courseId],
        () => CoursesService.getUsers(Number(courseId)),
        {
            enabled: !!courseId
        }
    )

    const onDeleteAllUser = () => {
        let deleteSuccess = window.confirm("Вы действительно хотите удалить всех пользователей? Это действие необратимо")
        if (deleteSuccess) {
            const data: ICoursesDetailId = {
                "id": Number(courseId)
            }
            deleteAllUser.mutate(data)
        }
    }

    const deleteAllUser = useMutation(
        (data: ICoursesDetailId) => CoursesService.deleteAllUser(data),
        {
            onSuccess: () => {
                queryClient.invalidateQueries(["users_course"])
                queryClient.invalidateQueries(['list_lessons_course', Number(courseId)])
            },
            onError: (errorDelete) => {
                const e = errorDelete as AxiosError
                setError(e.response ? e.response.status : error)
            }
        }
    )

    if (users.isLoading) return <div className="text-center">Загрузка...</div>

    if (users.isError) {
        const e = users.error as AxiosError
        setError(e.response ? e.response.status : error)
    }

    return (
        <>
            {users.isError && (<ErrorMessage numberError={error}/>)}
            <div className="flex flex-col gap-[24px] h-full">
                <div className="flex w-full justify-between max-lg:flex-col max-lg:gap-[16px]">
                    <div className="leading-[120%] text-[20px] text-[#292B2C] font-medium">
                        Пользователи {users.data && users.data.length > 0 && (
                        <span className="text-[#BDBDBD]">(выбрано: {users.data.length})</span>)}
                    </div>
                    {users.data && users.data.length > 0 && (
                        <div className="flex gap-[6px] cursor-pointer" onClick={onDeleteAllUser}>
                            <DeleteUser className="flex-shrink-0"/>
                            Удалить всех
                        </div>
                    )}
                </div>
                <SearchInput
                    refSearch={refSearch}
                    setShowSearchResult={setShowSearchResult}
                    showSearchResult={showSearchResult}
                    searchTerm={searchTerm}
                    setSearchTerm={setSearchTerm}
                >
                    <SearchCourseStudent
                        onClearInput={onClearInput}
                        setSearchTerm={setSearchTerm}
                        courseId={Number(courseId)}
                        setShowSearchResult={setShowSearchResult}
                        searchTerm={searchTerm}/>
                </SearchInput>
                <div
                    className="h-full flex flex-col">
                    {users.isLoading && (<Loader/>)}
                    {users.data && (
                        <>
                            {users.data.length > 0 ? (
                                <>
                                    {users.data.map((user, index) => <CourseUserListItem courseId={Number(courseId)}
                                                                                         user={user} key={index}/>)}
                                </>
                            ) : (
                                <div className="text-center">Привязанные пользователи отсутсвуют</div>
                            )}
                        </>
                    )}
                </div>
            </div>
        </>
    )
}

export default CourseUserList