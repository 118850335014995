import CourseMainInformation from "./course-main-information/CourseMainInformation";
import CourseLessonList from "./course-lesson-list/CourseLessonList";
import React from "react";
import CourseUserList from "./course-user-list/CourseUserList";
import {ICourses} from "../../../../../../models/courses";
import ScrollContainer from "../../../../../ui/scroll-container/ScrollContainer";
import {IFolder} from "../../../../../../models/folders";

interface ICoursesTabs {
    activeTab: number
    course: ICourses
}

const CoursesTabs = (
    {
        activeTab,
        course,
    }: ICoursesTabs
) => {

    return (
        <>
            <div
                className={`bg-white p-[32px] rounded-[6px] flex flex-col text-[16px] font-medium max-lg:p-[14px] max-lg:gap-0 overflow-hidden h-full` + (activeTab == 1 ? " block" : " hidden")}>
                {/*<ScrollContainer>*/}
                    <div
                        className="h-full">
                        <CourseMainInformation course={course}/>
                    </div>
                {/*</ScrollContainer>*/}
            </div>
            <CourseLessonList course={course} activeTab={activeTab}/>
            <div
                className={`bg-white p-[32px] rounded-[6px] flex flex-col text-[16px] font-medium max-lg:p-[14px] max-lg:gap-0 overflow-hidden h-full` + (activeTab == 3 ? " block" : " hidden")}>
                <CourseUserList/>
            </div>
        </>
    )
}

export default CoursesTabs


