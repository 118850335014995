import {useContext, useEffect} from "react";
import {AuthContext} from "../../../context/AuthContext";
import {useNavigate} from "react-router-dom";
import Cookies from "universal-cookie";
import {Constants} from "../../../constans/constants";

const Home = () => {

    const navigate = useNavigate();
    const cookies = new Cookies()
    const {authenticated, setAuthenticated, currentRoleId, currentUserId} = useContext(AuthContext)

    useEffect(() => {
        if(!authenticated) {
            navigate("/login")
        } else {
            if (Constants.LIST_ADMIN_ROLE.indexOf(Number(currentRoleId)) !== -1) {
                navigate("/folders")
            } else {
                navigate("/student-courses")
            }
        }
    }, [authenticated])

    return (
        <div className="flex flex-col gap-[12px] leading-[120%]">
            <div className="">
                СДО "АПОК СТАДИ" представляет собой систему дистанционного образования, которая позволяет:
                <div className="flex flex-col gap-[5px] ml-[15px] mt-[5px]">
                    <div className="">• просматривать все приобретенные курсы;</div>
                    <div className="">• изучать учебные материалы в различных форматах (видео, текст, картинки, схемы, таблицы и т.д.);</div>
                    <div className="">• проходить тестирования;</div>
                    <div className="">• отслеживать прогресс по обучению;</div>
                    <div className="">• обучать сотрудников компании при участии представителя компании – куратора.</div>
                </div>
            </div>
            <div className="">
                Обучающийся имеет доступ к обязательному для освоения контенту, содержащемуся в курсах (учебные материалы в текстовом и видео формате, тестовые задания и т.п), а также к иным функциональным возможностям СДО.
                Обучающийся может видеть все приобретенные им курсы, свободно перемещаться между учебными планами доступных к освоению курсов, переходить в открытые для прохождения курсы. Внутри курсов обучающийся имеет возможность просматривать учебные материалы, выполнять тестирования, получать обратную связь внутри СДО от куратора.
            </div>
            <div className="">
                Обучающийся в любой момент может ознакомиться с прогрессом в освоении образовательных программ. Также по ходу обучения обучающемуся будет предоставлена возможность оставить обратную связь по образовательному процессу в целом, пройденному курсу и т.д.
            </div>
        </div>
    )
}

export default Home