import React from 'react';
import ReactDOM from 'react-dom/client';
import './assets/css/global.css';
import App from './App';
import {BrowserRouter} from "react-router-dom";
import {AuthProvider} from "./context/AuthContext";
import {QueryClient, QueryClientProvider} from "react-query";
import {LoaderProvider} from "./context/LoaderContext";

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);

const queryClient = new QueryClient({
    defaultOptions: {
        queries: {
            refetchOnWindowFocus: false,
            retry: false
        }
    }
})

root.render(
    <BrowserRouter>
        <QueryClientProvider client={queryClient}>
            <LoaderProvider>
                <AuthProvider>
                    <App />
                </AuthProvider>
            </LoaderProvider>
        </QueryClientProvider>
    </BrowserRouter>
);
