import {useParams} from "react-router-dom";
import React, {useContext, useEffect, useRef, useState} from "react";
import Cookies from "universal-cookie";
import {HubConnectionBuilder, HubConnectionState} from "@microsoft/signalr";
import {useMutation, useQuery, useQueryClient} from "react-query";
import {ChatService} from "../../../../services/chat.service";
import {IActionMessage, IChat, IMessage, IUpdateViewMessage} from "../../../../models/chat";
import Loader from "../../../ui/loader/Loader";
import {AxiosError} from "axios";
import ErrorMessage from "../../../ui/error/ErrorMessage";
import {ReactComponent as CloseFiles} from "../../../../assets/images/global/close-modal-icon.svg";
import {ReactComponent as File} from "../../../../assets/images/chat/file.svg";
import ScrollContainer from "../../../ui/scroll-container/ScrollContainer";
import {LessonsService} from "../../../../services/lessons.service";
import ChatSendMessage from "./chat-send-message/ChatSendMessage";
import Companion from "./companion/Companion";
import {Constants} from "../../../../constans/constants";
import Message from "./message/Message";
import Scrollbars, {positionValues} from "react-custom-scrollbars";
import {ReactComponent as ArrowScroll} from "../../../../assets/images/global/arrow-avatar.svg";
import {AuthContext} from "../../../../context/AuthContext";

interface IChatDetail {
    activeChat?: number
    setActiveChatId?: (id: number | null) => void
}

const ChatDetail = (
    {
        activeChat,
        setActiveChatId
    }: IChatDetail
) => {

    const queryClient = useQueryClient()
    const cookies = new Cookies()
    const {id} = useParams()

    const {authenticated, setAuthenticated, currentRoleId, currentUserId} = useContext(AuthContext)
    const [chatId, setChatId] = useState<number | null>(null)
    const [chat, setChat] = useState<IChat>({} as IChat)
    const [error, setError] = useState<number | null>(null)
    const [showLoading, setShowLoading] = useState<boolean>(false)
    const [showFiles, setShowFiles] = useState<boolean>(false)
    const [actionMessage, setActionMessage] = useState<IActionMessage>({} as IActionMessage)
    const [heightActionMessage, setHeightActionMessage] = useState<number | null>(null)
    const [answerMessage, setAnswerMessage] = useState<number | null>(null)
    const [messages, setMessages] = useState<IMessage[]>([])
    const [countNewUnreadMessage, setCountNewUnreadMessage] = useState<number | null>(null)
    const [scrollBottom, setScrollBottom] = React.useState<boolean>(false);
    const [positionScroll, setPositionScroll] = React.useState<positionValues | null>(null);
    const [changeUnreadMessage, setChangeUnreadMessage] = useState<number | null>(null)
    const [countUnreadMessage, setCountUnreadMessage] = useState<number | null>(null)
    const [lastMessage, setLastMessage] = useState<boolean>(false)
    const [updateReadMessage, setUpdateReadMessage] = useState<number[]>([])

    const refAreaChat = useRef<HTMLDivElement>(null)
    const refDownloadFile = useRef<HTMLAnchorElement>(null)
    const refChatDetail = useRef<HTMLDivElement>(null)
    const refLink = useRef<HTMLAnchorElement>(null)

    const hubConnection = new HubConnectionBuilder()
        .withUrl(Constants.LOCATION + "/chat")
        .withAutomaticReconnect()
        .build();

    useEffect(() => {
        if (activeChat) {
            setChatId(activeChat)
        } else if (id) {
            setChatId(Number(id))
        }
        setActionMessage({} as IActionMessage)
        setChangeUnreadMessage(null)
        setCountUnreadMessage(null)
        setLastMessage(false)
        setUpdateReadMessage([])
    }, [id, activeChat]);

    useEffect(() => {
        const connectionChat = async () => {
            if (hubConnection.state === HubConnectionState.Connected) {
                hubConnection.off("GetChat");
                hubConnection.off("SendMessage");
                hubConnection.off("UpdateViewMessage");
                hubConnection.off("UpdateMessageText");
                hubConnection.off("DeleteMessage");
                await hubConnection.stop();
            }

            await hubConnection.start()
                .then((connection) => {
                })
                .catch((err) => {
                    console.error('Connection failed: ', err);
                });

            if (hubConnection.state === HubConnectionState.Connected) {
                await hubConnection.invoke("JoinInGroup", chatId);
            }

            // hubConnection.on("GetChat", function (getMessages: IMessage[], id: number, senderId: number) {
            //     console.log(chatId,id, chatId === id, senderId, currentUserId, senderId === Number(currentUserId),getMessages)
            //     if (chatId === id && senderId === Number(currentUserId)) {
            //         setPositionScroll(null)
            //         getMessages.length > 0 ? setMessages(getMessages) : setMessages([])
            //         let unreadMessages = getMessages.filter(mes => !mes.isViewed && mes.senderId !== Number(currentUserId))
            //         if (unreadMessages.length > 0) {
            //             setChangeUnreadMessage(unreadMessages[0].id)
            //             setCountUnreadMessage(unreadMessages.length)
            //         }
            //     }
            // });

            hubConnection.on("SendMessage", function (sendMessages: IMessage[], id: number, senderId: number) {
                if (chatId === id) {
                    queryClient.invalidateQueries(["companion", chatId, Number(currentUserId)]);
                    sendMessages.map(message => {
                        setMessages(prev => [...prev, message]);
                    })

                    if (senderId === Number(currentUserId)) {
                        setScrollBottom(true)
                    } else {
                        setCountNewUnreadMessage(sendMessages.length)
                    }
                }
            });

            hubConnection.on("UpdateViewMessage", function (response: boolean, senderId: number, messageId: number) {
                if (response) {
                    queryClient.invalidateQueries(["count_unread_message", currentUserId])
                    setMessages(prev => prev.map(message => message.id == messageId && !message.isViewed ? {
                        ...message,
                        isViewed: true
                    } : message))
                    if (companion.data) queryClient.invalidateQueries(["count_unread_message", String(companion.data.id)]);
                }
            });

            hubConnection.on("UpdateMessageText", function (text: string, messageId: number, senderId: number) {
                if (text && messageId) {
                    setMessages(prev => prev.map(message => message.id === messageId ? {
                        ...message,
                        text: text
                    } : message))
                }
            });

            hubConnection.on("DeleteMessage", function (messageId: number, senderId: number) {
                if (messageId) {
                    setMessages(prev => prev.filter(message => message.id !== messageId))
                }
            });
        };

        if (chatId) {
            connectionChat();
        }

        return () => {
            if (hubConnection.state === HubConnectionState.Connected) {
                hubConnection.off("GetChat");
                hubConnection.off("SendMessage");
                hubConnection.off("UpdateViewMessage");
                hubConnection.off("UpdateMessageText");
                hubConnection.off("DeleteMessage");
                hubConnection.stop();
            }
        };
    }, [chatId]);

    hubConnection.on("UpdateListMethodist", function (response: number) {
        if (response) {
            queryClient.invalidateQueries(["list_methodist", Number(response)]);
            if (companion.data) queryClient.invalidateQueries(["count_unread_message", String(companion.data.id)]);
        }
    });

    const chatById = useQuery(
        ["chat", chatId],
        () => ChatService.getById(chatId, Number(currentUserId)),
        {
            enabled: !!chatId,
            onSuccess: (response) => {
                if (chatId === response.id && response.senderId === Number(currentUserId)) {
                    setPositionScroll(null)
                    if(response.chat.messages) {
                        response.chat.messages.length > 0 ? setMessages(response.chat.messages) : setMessages([])
                        let unreadMessages = response.chat.messages.filter(mes => !mes.isViewed && mes.senderId !== Number(currentUserId))
                        if (unreadMessages.length > 0) {
                            setChangeUnreadMessage(unreadMessages[0].id)
                            setCountUnreadMessage(unreadMessages.length)
                        }
                    } else {
                        setMessages([])
                    }
                }
            }
        }
    )

    const companion = useQuery(
        ["companion", chatId, Number(currentUserId)],
        () => ChatService.getCompanionInChat(chatId, Number(currentUserId)),
        {
            // refetchOnWindowFocus: true,
            enabled: !!chatId,
            onSuccess: (response) => {
            },
            onError: (err) => {
            }
        }
    )

    const updateViewMessage = useMutation(
        (data: IUpdateViewMessage) => ChatService.updateView(data),
        {
            onSuccess: (response) => {
            }
        }
    )

    const handleCloseChat = () => {
        if (setActiveChatId) {
            setActiveChatId(null)
        }
    }

    const downloadFile = useMutation(
        (id: number) => LessonsService.downloadFile(id),
        {
            onSuccess: (response) => {
                if (refDownloadFile.current) {
                    refDownloadFile.current.href = response
                    refDownloadFile.current.click()
                }
                setShowLoading(false)
            },
            onError: (response) => {
                const e = response as AxiosError
                setError(e.response ? e.response.status : 400)
                setShowLoading(false)
            }
        }
    )
    const handleDownloadFile = (id: number) => {
        setShowLoading(true)
        downloadFile.mutate(id)
    }

    const changeScroll = (e: positionValues) => {
        findMessageForUpdateView()
        // if (messages) {
        //     messages.filter(mes => !mes.isViewed && mes.senderId !== Number(currentUserId) && !updateReadMessage.includes(mes.id))
        //         .map(mes => {
        //             let scrollbar = document.querySelector(`.scrollbar`)
        //             let unreadMessage = document.querySelector(`#mes-${mes.id}`)
        //             if (unreadMessage && scrollbar && chatId) {
        //                 let posElement = unreadMessage.getBoundingClientRect()
        //                 let posScrollbar = scrollbar.getBoundingClientRect()
        //                 if ((posElement.top + posElement.height) < posScrollbar.bottom && (posElement.top + posElement.height) > posScrollbar.top) {
        //                     const data: IUpdateViewMessage = {
        //                         chatId: chatId,
        //                         senderId: Number(currentUserId),
        //                         messageId: mes.id
        //                     }
        //                     updateViewMessage.mutate(data)
        //                     setUpdateReadMessage(prev => [...prev, mes.id])
        //                     if (countNewUnreadMessage) setCountNewUnreadMessage(countNewUnreadMessage - 1)
        //                 }
        //             }
        //         })
        // }
        setPositionScroll(e)
        if (e.top === 1) setScrollBottom(false)
    }

    const findMessageForUpdateView = () => {
        if (messages) {
            messages.filter(mes => !mes.isViewed && mes.senderId !== Number(currentUserId) && !updateReadMessage.includes(mes.id))
                .map(mes => {
                    let scrollbar = document.querySelector(`.scrollbar`)
                    let unreadMessage = document.querySelector(`#mes-${mes.id}`)
                    if (unreadMessage && scrollbar && chatId) {
                        let posElement = unreadMessage.getBoundingClientRect()
                        let posScrollbar = scrollbar.getBoundingClientRect()
                        if ((posElement.top + posElement.height) < posScrollbar.bottom && (posElement.top + posElement.height) > posScrollbar.top) {
                            const data: IUpdateViewMessage = {
                                chatId: chatId,
                                senderId: Number(currentUserId),
                                messageId: mes.id
                            }
                            updateViewMessage.mutate(data)
                            setUpdateReadMessage(prev => [...prev, mes.id])
                            if (countNewUnreadMessage) setCountNewUnreadMessage(countNewUnreadMessage - 1)
                        }
                    }
                })
        }
    }

    const handleScrollBottomButton = () => {
        setScrollBottom(true)
    }

    useEffect(() => {
        if (lastMessage) {
            if (refLink.current) {
                if (changeUnreadMessage) {
                    refLink.current.href = `#mes-${changeUnreadMessage}`
                    refLink.current.click()
                } else {
                    refLink.current.href = `#scroll-bottom`
                    refLink.current.click()
                }
            }
        }
    }, [lastMessage]);

    if (chatById.isLoading) return <Loader/>
    if (chatById.isFetching) return <Loader/>
    if (companion.isLoading) return <Loader/>

    if (chatById.isError) {
        const e = chatById.error as AxiosError
        return <ErrorMessage numberError={e.response ? e.response.status : 400}/>
    }
    if (companion.isError) {
        const e = companion.error as AxiosError
        return <ErrorMessage numberError={e.response ? e.response.status : 400}/>
    }

    if (!chatById.data) return <ErrorMessage numberError={400}/>
    if (!companion.data) return <ErrorMessage numberError={400}/>

    return (
        <>
            {showLoading && <Loader/>}
            <div className="flex h-full w-full" ref={refChatDetail}>
                <div
                    className={`h-full flex flex-col chat-detail ${showFiles ? "w-[calc(100%-400px)] max-lg:hidden" : "w-full"}`}
                >
                    <Companion
                        companion={companion.data}
                        handleCloseChat={handleCloseChat}
                        showFiles={showFiles}
                        setShowFiles={setShowFiles}/>
                    <div className="w-full h-full bg-[#F2F2F2] text-[16px] leading-[120%] relative">
                        {positionScroll && positionScroll.top < 1 && (
                            <div
                                onClick={handleScrollBottomButton}
                                className="absolute w-[30px] h-[30px] rounded-[49px] bg-white right-[20px] bottom-[20px] z-[50] cursor-pointer">
                                <div className="w-full h-full relative flex items-center justify-center">
                                    {countNewUnreadMessage !== null && countNewUnreadMessage > 0 && (
                                        <div
                                            className="absolute w-[20px] h-[20px] top-[-10px] right-[-10px] rounded-[49px] bg-[#17AB57] text-white flex items-center justify-center text-[12px]">1</div>
                                    )}
                                    <ArrowScroll className={`fill-black`}/>
                                </div>
                            </div>
                        )}
                        <Scrollbars
                            onScrollFrame={e => changeScroll(e)}
                            ref={(el: Scrollbars) => {
                                if (el) {
                                    if(el.getScrollHeight() <= el.getClientHeight()) {
                                        // console.log(el, el.getScrollHeight(), el.getClientHeight())
                                        findMessageForUpdateView()
                                    }

                                    if (scrollBottom) return el.scrollToBottom()
                                }
                            }}
                            style={{height: '100%', width: "100%"}}
                            className="scrollbar"
                        >
                            <div ref={refAreaChat} className=" flex flex-col justify-end items-start w-full min-h-full pb-[17px]">
                                {messages && messages.length > 0 && messages.map((message, index) => (
                                    <Message
                                        setLastMessage={setLastMessage}
                                        countUnreadMessage={countUnreadMessage}
                                        changeUnreadMessage={changeUnreadMessage}
                                        messages={messages}
                                        answerMessage={answerMessage}
                                        setAnswerMessage={setAnswerMessage}
                                        heightActionMessage={heightActionMessage}
                                        chatId={chatId}
                                        areaChat={refAreaChat.current?.getBoundingClientRect()}
                                        handleDownloadFile={handleDownloadFile}
                                        key={index}
                                        message={message}
                                        index={index}
                                        setActionMessage={setActionMessage}/>
                                ))}
                                {error && (
                                    <div className="flex justify-center w-full">
                                        <ErrorMessage numberError={error}/>
                                    </div>
                                )}
                            </div>
                            <div className="" id="scroll-bottom">
                                <a ref={refLink} href="#" className="hidden"></a>
                            </div>
                        </Scrollbars>
                    </div>
                    <ChatSendMessage
                        refChatDetail={refChatDetail}
                        setChatScroll={setScrollBottom}
                        companionId={companion.data.id}
                        setActionMessage={setActionMessage}
                        chatId={chatId}
                        setHeightActionMessage={setHeightActionMessage}
                        actionMessage={actionMessage}
                        setError={setError}/>
                </div>
                <div
                    className={`bg-white w-[400px] max-lg:w-full flex-col border-l-[2px] border-[#BDBDBD] max-lg:border-none ${showFiles ? "flex" : "hidden"}`}>
                    <div className="py-[12px] px-[16px] border-b-[2px] border-white">
                        <div
                            className="flex h-[48px] items-center justify-between text-[24px] text-[#292B2C] font-bold max-lg:justify-center">
                            <div className="max-lg:ml-auto max-lg:pl-[24px]">Вложения</div>
                            <CloseFiles onClick={() => setShowFiles(!showFiles)}
                                        className="w-[24px] h-[24px] cursor-pointer max-lg:ml-auto"/>
                        </div>
                    </div>
                    <ScrollContainer chat={false}>
                        {chatById.data.chat.files?.map((file, index) => (
                            <div key={index} className="flex gap-[10px] items-center py-[12px] px-[16px]">
                                <div
                                    className="w-[36px] h-[36px] flex-shrink-0 rounded-[4px] bg-[#2F80ED] flex items-center justify-center">
                                    <File/>
                                </div>
                                <div className="flex gap-[10px] w-full items-end">
                                    <div className="flex w-full flex-col gap-[2px]">
                                        <div className="w-full">{file.name}{file.extention}</div>
                                        <div
                                            onClick={() => handleDownloadFile(file.id)}
                                            className={`text-[#2F80ED] underline text-[12px] cursor-pointer`}>Скачать
                                        </div>
                                        <a ref={refDownloadFile}
                                           href="#"
                                           className="hidden"></a>
                                    </div>
                                    <div
                                        className="whitespace-nowrap text-[12px] text-[#BDBDBD]">{file.date}</div>
                                </div>
                            </div>
                        ))}
                    </ScrollContainer>
                </div>
            </div>
        </>
    )
}

export default ChatDetail
