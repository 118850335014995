import axios from "axios";
import AuthHeader from "./auth-header";
import {
    IChat, IChatMessages,
    ICompanion, ICountUnreadMessage, ICreateChat,
    IDeleteMessage,
    IListChatByMethodist,
    IUpdateViewMessage
} from "../models/chat";
import {Constants} from "../constans/constants";

const contentType = {'Content-Type': 'multipart/form-data'}

export const ChatService = {

    async getListByMethodist(id: number) {
        const {data} = await axios.get<IListChatByMethodist[]>(Constants.LOCATION + `/api/Chat/getListChatByMethodist/${id}`, {headers: AuthHeader()})
        return data
    },

    async getById(id: number | null, currentUser: number) {
        const {data} = await axios.get<IChatMessages>(Constants.LOCATION + `/api/Chat/getChatById/${id}/${currentUser}`, {headers: AuthHeader()})
        return data
    },

    async getCompanionInChat(id: number | null, currentUser: number) {
        const {data} = await axios.get<ICompanion>(Constants.LOCATION + `/api/Chat/getCompanionInChat/${id}/${currentUser}`, {headers: AuthHeader()})
        return data
    },

    async sendMessage(dataSubmit: FormData) {
        const {data} = await axios.post<IChat>(Constants.LOCATION + `/api/Chat/SendMessage`, dataSubmit, {
            headers: Object.assign(contentType, AuthHeader())
        })
        return data
    },

    async updateView(dataSubmit: IUpdateViewMessage) {
        const {data} = await axios.post<boolean>(Constants.LOCATION + `/api/Chat/updateViewMessage/`, dataSubmit, {headers: AuthHeader()})
        return data
    },

    async deleteMessage(dataSubmit: IDeleteMessage) {
        const {data} = await axios.post(Constants.LOCATION + `/api/Chat/deleteMessage/`, dataSubmit, {headers: AuthHeader()})
        return data
    },

    async updateMessageText(dataSubmit: FormData) {
        const {data} = await axios.post(Constants.LOCATION + `/api/Chat/updateMessageText/`, dataSubmit, {
            headers: Object.assign(contentType, AuthHeader())
        })
        return data
    },

    async getCountUnreadMessage(id: number) {
        const {data} = await axios.get<ICountUnreadMessage>(Constants.LOCATION + `/api/Chat/getCountUnreadMessage/${id}`, {headers: AuthHeader()})
        return data
    },

    async clearChat(id: number | undefined) {
        const {data} = await axios.post<boolean>(Constants.LOCATION + `/api/Chat/clearChat/${id}/`, {}, {headers: AuthHeader()})
        return data
    },

    async createChat(dataSubmit: ICreateChat) {
        const {data} = await axios.post<number>(Constants.LOCATION + `/api/Chat/createChat/`, dataSubmit, {headers: AuthHeader()})
        return data
    },

    async checkExistChat(id: number) {
        const {data} = await axios.get<number>(Constants.LOCATION + `/api/Chat/checkExistChat/${id}`, {headers: AuthHeader()})
        return data
    }
}