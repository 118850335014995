import {ReactComponent as SearchIcon} from "../../../../assets/images/global/searcg-icon.svg";
import {ReactComponent as CreateCoursesIcon} from "../../../../assets/images/folders/create-courses-icon.svg";
import {ReactComponent as ArrowSortIcon} from "../../../../assets/images/global/arrow-sort-icon.svg";
import React, {useState} from "react";
import {useQuery} from "react-query";
import {UsersService} from "../../../../services/users.service";
import {AxiosError} from "axios";
import ErrorMessage from "../../../ui/error/ErrorMessage";
import {IUser} from "../../../../models/users";
import UsersItem from "./users-item/UsersItem";
import {Link} from "react-router-dom";
import ScrollContainer from "../../../ui/scroll-container/ScrollContainer";

const Users = () => {

    const [text, setText] = useState("")
    const [blurSearch, setBlurSearch] = useState<boolean>(false)
    const [activeSort, setActiveSort] = useState<boolean>(false)
    const [filteredUsers, setFilteredUsers] = useState<IUser[]>([])
    const [page, setPage] = useState<number>(1)
    const [showLoadMore, setShowLoadMore] = useState<boolean>(true)
    const [searchTerm, setSearchTerm] = useState<string>("")

    const users = useQuery(
        ["users", page, searchTerm],
        () => UsersService.getByPage(page, searchTerm ? searchTerm : ""),
        {
            keepPreviousData: true,
            onSuccess: (response) => {
                if(page == 1) {
                    setFilteredUsers(response.users)
                } else {
                    response.users.map(user => {
                        setFilteredUsers(prev => [...prev, user])
                    })
                }
                setShowLoadMore(true)
                if(response.left <= 0) {
                    setShowLoadMore(false)
                }

            }
        }
    )

    const handleSort = () => {
        const arrSort = [
            {method: (a: IUser, b: IUser) => (a.lastName + " " + a.firstName + " " + a.fatherName) > (b.lastName + " " + b.firstName + " " + b.fatherName) ? 1 : -1},
            {method: (a: IUser, b: IUser) => (a.lastName + " " + a.firstName + " " + a.fatherName) < (b.lastName + " " + b.firstName + " " + b.fatherName) ? 1 : -1}
        ]
        const sorted = filteredUsers.sort(!activeSort ? arrSort[0].method : arrSort[1].method)
        setFilteredUsers([...sorted])
        setActiveSort(!activeSort)
    }

    const handleSearch = (e: React.ChangeEvent<HTMLInputElement>) => {
        setPage(1)
        setSearchTerm(e.target.value)
    }

    if (users.isLoading) return <div className="text-center">Загрузка...</div>

    if (users.isError) {
        const e = users.error as AxiosError
        return <ErrorMessage numberError={e.response ? e.response.status : 400}/>
    }

    if(!users.data) return <div className="text-center">Данных нет</div>

    return (
        <>
            <div className="h-full">
                <div className="flex flex-col gap-[34px] h-full max-lg:gap-[24px]">
                    <div className="w-full flex gap-[12px] items-center text-[16px] max-lg:gap-[12px]">
                        <div className="relative w-full">
                            <SearchIcon className={`absolute top-[8px] left-[10px] ` + (blurSearch ? "fill-[#292B2C]" : "")}/>
                            <input
                                onFocus={() => {
                                    setBlurSearch(true)
                                }}
                                onBlur={() => {
                                    setBlurSearch(false)
                                }}
                                onChange={handleSearch}
                                type="text"
                                placeholder="Поиск"
                                className="py-[8px] pl-[36px] pr-[12px] border-solid border-[1px] border-[#BDBDBD] rounded-[5px] bg-[#F2F2F2] w-full focus:border-[#17AB57] focus:outline-none"/>
                        </div>
                        <Link
                            to={"/users/add"}
                            className="bg-[#17AB57] text-white rounded-[5px] items-center flex gap-[6px] py-[8px] pl-[12px] pr-[16px] font-medium whitespace-nowrap cursor-pointer justify-center">
                            <CreateCoursesIcon className="flex-shrink-0"/>
                            Добавить
                        </Link>
                    </div>
                    <div
                        className="bg-white p-[32px] rounded-[6px] flex flex-col gap-[18px] text-[16px] font-medium max-lg:px-[14px] max-lg:py-[14px] max-lg:gap-0 overflow-hidden h-full">
                        <div
                            className="flex justify-between text-[#828282] text-[16px] font-medium max-lg:hidden">
                            <div className="flex w-[70%] justify-between">
                                <div className="cursor-pointer flex gap-[4px]"
                                     onClick={handleSort}
                                >
                                    Имя
                                    <ArrowSortIcon className={!activeSort ? "rotate-180 transition-all" : "rotate-0 transition-all"}/>
                                </div>
                                <div className="mr-[-8px]">Роль</div>
                            </div>
                            <div className="w-[30%] flex justify-end">Действия</div>
                        </div>
                        {/*<ScrollContainer>*/}
                            <div
                                className="h-full">
                                {filteredUsers.map((user, index) => (
                                    <UsersItem user={user} key={index}/>
                                ))}
                            </div>
                        {/*</ScrollContainer>*/}
                    </div>
                    {showLoadMore && (
                        <div
                            onClick={() => setPage(prev => prev + 1)}
                            className="flex text-center w-full px-[16px] py-[8px] border-[1px] border-solid border-[#17AB57] justify-center cursor-pointer rounded-[5px] text-[16px] font-medium text-[#292B2C] leading-[120%]">
                            Показать ещё
                        </div>
                    )}
                </div>
            </div>
        </>
    )
}

export default Users