import axios from "axios";
import AuthHeader from "./auth-header";
import {ICompany, ICompanyDetail, ICompanyDetailId} from "../models/company";
import {Constants} from "../constans/constants";

export const CompanyService = {
    async getAll() {
        const {data} = await axios.get<ICompany[]>(Constants.LOCATION + `/api/Company/`, {headers: AuthHeader()})
        return data
    },

    async getById(id: number) {
        const response = await axios.get<ICompany>(Constants.LOCATION + `/api/Company/${id}`, {headers: AuthHeader()})
        return response.data
    },

    async create(dataSubmit: ICompanyDetail) {
        const {data} =  await axios.post<ICompany>(Constants.LOCATION + '/api/Company/add', dataSubmit, {headers: AuthHeader()})
        return data
    },

    async update(id: number, data: ICompanyDetail) {
        return await axios.post<ICompany>(Constants.LOCATION + `/api/Company/update/${id}`, data, {headers: AuthHeader()})
    },

    async delete(data: ICompanyDetailId) {
        return await axios.post<boolean>(Constants.LOCATION + '/api/Company/delete/', data, {headers: AuthHeader()})
    }
}