import {SubmitHandler, useForm} from "react-hook-form";
import {IFolder} from "../../../../models/folders";
import {AxiosError} from "axios";
import React, {useEffect, useState} from "react";
import Portal from "../portal/Portal";
import {FolderService} from "../../../../services/folder.service";
import {ReactComponent as CloseIcon} from "../../../../assets/images/global/close-modal-icon.svg";
import Modal from "../Modal";
import {useMutation} from "react-query";
import ErrorMessage from "../../error/ErrorMessage";
import Loader from "../../loader/Loader";

interface AddFolder {
    onClose: () => void
    folderId?: string
    setModalFolder: (folder: IFolder) => void
}

const ModalAddFolder = (
    {
        onClose,
        folderId,
        setModalFolder
    }: AddFolder
) => {

    const [error, setError] = useState<number | null>(null)

    const {
        setValue,
        register,
        handleSubmit,
        formState: {errors}
    } = useForm<IFolder>()

    useEffect(() => {
        setValue("folderId", Number(folderId))
    }, [])

    const addFolder = useMutation(
        (data: IFolder) => FolderService.create(data),
        {
            onSuccess: (response) => {
                setModalFolder(response.data)
                onClose()
            },

            onError: (error) => {
                const e = error as AxiosError
                setError(e.response ? e.response.status : 400)
            }
        }
    )

    const onSubmitName: SubmitHandler<IFolder> = async (data) => {
        addFolder.mutate(data)
    }

    return (
        <Portal>
            <Modal>
                {addFolder.isLoading && <Loader/>}
                {error && <ErrorMessage numberError={error}/>}
                <form className="flex flex-col gap-[24px] text-[16px]" onSubmit={handleSubmit(onSubmitName)}>
                    <div className="flex justify-between items-center font-bold text-[16px]">
                        Создание новой папки
                        <CloseIcon className="cursor-pointer" onClick={onClose}/>
                    </div>
                    <div>
                        <input
                            className="border-[1px] border-solid border-[#BDBDBD] font-medium py-[8px] pl-[10px] pr-[12px] focus:outline-none focus:border-[#17AB57] w-full rounded-[5px]"
                            type="text"
                            placeholder="Введите название папки"
                            {...register("name", {required: true})}/>
                        {errors.name && errors.name.type === "required" && (
                            <p className="text-red-600">Название обязательно</p>
                        )}
                        <input type="hidden" {...register("folderId")}/>
                    </div>
                    <div className="flex gap-[12px] justify-end">
                        <button
                            type="submit"
                            className="text-white font-medium py-[8px] px-[16px] rounded-[5px] bg-[#17AB57]"
                        >
                            Создать
                        </button>
                        <button
                            className="font-medium py-2 px-4 rounded-[5px] border-[1px] border-solid border-[#17AB57]"
                            onClick={onClose}>Отмена
                        </button>
                    </div>
                </form>
            </Modal>
        </Portal>
    )
}

export default ModalAddFolder