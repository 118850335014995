import {
    IUser,
    IUserDetail,
    IUserDetailId,
    IUserMethodist
} from "../../../../../../../models/users";
import React, {useContext, useEffect, useState} from "react";
import {Controller, SubmitHandler, useForm} from "react-hook-form";
import {useMutation, useQuery, useQueryClient} from "react-query";
import {UsersService} from "../../../../../../../services/users.service";
import {AxiosError} from "axios";
import ErrorMessage from "../../../../../../ui/error/ErrorMessage";
import Select, {SingleValue} from "react-select";
import {IRole} from "../../../../../../../models/role";
import {Constants} from "../../../../../../../constans/constants";
import generator from 'generate-password-ts'
import {useNavigate} from "react-router-dom";
import useToggle from "../../../../../../../hooks/useToggle";
import ModalPassword from "../../../../../../ui/modal/modal-password/ModalPassword";
import Cookies from "universal-cookie"
import {LoaderContext} from "../../../../../../../context/LoaderContext";
import Loader from "../../../../../../ui/loader/Loader";
import {ChatService} from "../../../../../../../services/chat.service";
import {AuthContext} from "../../../../../../../context/AuthContext";
import {ICreateChat} from "../../../../../../../models/chat";

interface IUserMainInformation {
    user?: IUser
    activeTab: number
}

const UserMainInformation = (
    {
        user,
        activeTab
    }: IUserMainInformation
) => {

    const {
        register,
        handleSubmit,
        setValue,
        formState: {errors},
        control
    } = useForm<IUserDetail>()

    const {show, setShow} = useContext(LoaderContext)
    const [save, setSave] = useState<boolean>(false)
    const [error, setError] = useState<number | null>(null)
    const [isModalPassword, setOnModalOpenPassword, setOnModalClosePassword] = useToggle()
    const [rolesShow, setRolesShow] = useState<IRole[]>([])
    const {authenticated, setAuthenticated, currentRoleId, currentUserId} = useContext(AuthContext)
    const [showBx24Id, setShowBx24Id] = useState<boolean>(false)
    const [activeRole, setActiveRole] = useState<string>("")
    const [showCreateChat, setShowCreateChat] = useState<boolean>(false)
    let userId: number

    const navigate = useNavigate()
    const queryClient = useQueryClient()
    const cookies = new Cookies()

    const roles = useQuery(
        ["roles", user?.id],
        () => UsersService.getRoles(),
        {
            enabled: Constants.LIST_ADMIN_ROLE.indexOf(Number(currentRoleId)) !== -1,
            onSuccess: (response) => {
                if (Number(currentRoleId) === Constants.METHODIST_ROLE) {
                    response.map(role => {
                        if (Constants.LIST_ADMIN_ROLE.indexOf(role.id) < 0) {
                            setRolesShow(prev => [...prev, role])
                        }
                    })
                } else {
                    setRolesShow(response)
                }
            }
        }
    )

    useEffect(() => {
        Constants.LIST_APOK_DOMAIN.map((element) => {
            if (element.value.indexOf(window.location.host) !== -1) {
                setShowBx24Id(true)
            }
        })
        Constants.LIST_TEST_DOMAIN.map((element) => {
            if (element.value.indexOf(window.location.host) !== -1) {
                setShowBx24Id(true)
            }
        })
        Constants.LIST_LOCAL_DOMAIN.map((element) => {
            if (element.value.indexOf(window.location.host) !== -1) {
                setShowBx24Id(true)
            }
        })
        if (user) {
            setValue("lastName", user.lastName)
            setValue("fatherName", user.fatherName)
            setValue("firstName", user.firstName)
            setValue("email", user.email)
            setValue("password", user.password)
            setValue("role", user.role, {shouldValidate: false})
            setActiveRole(user.role.slugName)
            setValue("dealId", user.dealId)
            setValue("bx24Id", user.bx24Id)
            userId = user.id
            setValue("methodist", user.methodist)
        }
    }, [user])

    const createUser = useMutation(
        (data: IUserDetail) => UsersService.create(data),
        {
            onSuccess: (response) => {
                queryClient.invalidateQueries(["user", response.id])
                navigate(`/users/${response.id}`)
                setShow(false)
            },
            onError: (errorCreate) => {
                const e = errorCreate as AxiosError
                setError(e.response ? e.response.status : 400)
                setShow(false)
            }
        }
    )

    const updateUser = useMutation(
        (data: IUserDetail) => UsersService.update(user ? user.id : 0, data),
        {
            onSuccess: (response) => {
                queryClient.invalidateQueries(["user", response.id])
                setShow(false)
            },
            onError: (errorUpdate) => {
                const e = errorUpdate as AxiosError
                setError(e.response ? e.response.status : 400)
                setShow(false)
            }
        }
    )

    const onSubmit: SubmitHandler<IUserDetail> = async (data) => {
        setShow(true)
        let dealId = Number(data.dealId)
        if (dealId === 0) {
            delete data.dealId
        }

        let bx24Id = Number(data.bx24Id)
        if (bx24Id === 0) {
            delete data.bx24Id
        } else {
            data.bx24Id = bx24Id
        }

        if (user) {
            updateUser.mutate(data)
        } else {
            createUser.mutate(data)
        }
    }

    useEffect(() => {
        const timer = setTimeout(() => {
            setSave(false)
        }, 10000)
        return () => clearTimeout(timer)
    }, [save])

    const generatePassword = () => {
        var password = generator.generate({
            length: 12,
            numbers: true
        })
        setValue("password", password, {shouldValidate: true})
    }

    const handleCancel = () => {
        navigate("/users")
    }

    const classNameFieldForm = "flex gap-[18px] items-center max-lg:items-start py-[20px] border-b-[2px] border-solid border-[#BDBDBD] first:pt-0 last:pb-0 last:border-none max-lg:flex-col"

    const deleteUser = useMutation(
        (data: IUserDetailId) => UsersService.delete(data),
        {
            onSuccess: () => {
                setShow(false)
                queryClient.invalidateQueries(["users"])
                navigate("/users")
            },
            onError: (errorDelete) => {
                setShow(false)
                const e = errorDelete as AxiosError
                setError(e.response ? e.response.status : 400)
            }
        }
    )

    const onDelete = () => {
        if (user) {
            let deleteSuccess = window.confirm("Вы действительно хотите удалить пользователя? Это действие необратимо")
            if (deleteSuccess) {
                setShow(true)
                const data = {
                    "id": user.id
                }
                deleteUser.mutate(data)
            }
        }
    }

    const onClearChat = () => {
        if (user) {
            let deleteSuccess = window.confirm("Вы действительно хотите очистить чат с пользователем? Это действие необратимо")
            if (deleteSuccess) {
                setShow(true)
                clearChat.mutate()
            }
        }
    }

    const clearChat = useMutation(
        () => ChatService.clearChat(user?.id),
        {
            onSuccess: (response) => {
                setShow(false)
            },
            onError: (error) => {
                setShow(false)
            }
        }
    )

    const onCreateChat = () => {
        if (user) {
            setShow(true)
            const data: ICreateChat = {
                studentId: user.id,
                methodistId: Number(currentUserId)
            }
            createChat.mutate(data)
        }
    }

    const createChat = useMutation(
        (data: ICreateChat) => ChatService.createChat(data),
        {
            onSuccess: (response) => {
                setShow(false)
                setShowCreateChat(false)
            },
            onError: (error) => {
                setShow(false)
                const e = error as AxiosError
                setError(e.response ? e.response.status : 400)
            }
        }
    )

    const checkExistChat = useQuery(
        ["exist_chat", user?.id],
        () => ChatService.checkExistChat(userId),
        {
            enabled: !!user,
            onSuccess: (response) => {
                if (response === 0) setShowCreateChat(true)
                setShow(false)
            },
            onError: (error) => {
                setShow(false)
                const e = error as AxiosError
                setError(e.response ? e.response.status : 400)
            }
        }
    )

    const [optionsMethodist, setOptionsMethodist] = useState<IUserMethodist[]>([])

    const listMethodistByCompany = useQuery(
        ["list_methodist", user?.id],
        () => UsersService.getListMethodistByCompany(),
        {
            enabled: Constants.LIST_ADMIN_ROLE.indexOf(Number(currentRoleId)) !== -1,
            onSuccess: (response) => {
                setOptionsMethodist([])
                response.forEach(element => {
                    setOptionsMethodist(prev => [...prev, {
                        id: element.id,
                        name: element.name
                    }])
                })
            },
            onError: (error) => {
                setShow(false)
                const e = error as AxiosError
                setError(e.response ? e.response.status : 400)
            }
        }
    )

    const handleChangeDeal = (e: React.ChangeEvent<HTMLInputElement>) => {
        if (Number(e.target.value) < 1) {
            setValue(`dealId`, Math.abs(Number(e.target.value)))
        }
    }

    return (
        <>
            {show && <Loader/>}
            <div
                className={`bg-white p-[32px] rounded-[6px] flex text-[16px] font-medium max-lg:p-[14px] max-lg:gap-0 overflow-hidden h-full w-full` + (activeTab == 1 ? " block" : " hidden")}>
                <div className="flex flex-col gap-[24px] w-full h-full">
                    {error && <ErrorMessage numberError={error}/>}
                    <div className="flex justify-between text-[20px] text-#292B2C font-medium items-center">
                        Общая информация о пользователе
                    </div>
                    <form className="flex flex-col gap-[18px] w-[50%] max-2xl:w-[75%] max-lg:w-full"
                          onSubmit={handleSubmit(onSubmit)}>
                        <div className="">
                            <div className={classNameFieldForm}>
                                <div className="w-[170px] flex-shrink-0">Фамилия</div>
                                <div className="flex flex-col w-full">
                                    <input
                                        className="py-[8px] pl-[12px] pr-[10px] border-[1px] border-solid border-[#BDBDBD] w-full rounded-[5px]"
                                        placeholder="Введите фамилию"
                                        disabled={Constants.LIST_ADMIN_ROLE.indexOf(Number(currentRoleId)) === -1}
                                        {...register("lastName", {required: true})}/>
                                    {errors.lastName && errors.lastName.type === "required" && (
                                        <p className="text-red-600">ФИО обязательно</p>
                                    )}
                                </div>
                            </div>
                            <div className={classNameFieldForm}>
                                <div className="w-[170px] flex-shrink-0">Имя</div>
                                <div className="flex flex-col w-full">
                                    <input
                                        className="py-[8px] pl-[12px] pr-[10px] border-[1px] border-solid border-[#BDBDBD] w-full rounded-[5px]"
                                        placeholder="Введите имя"
                                        disabled={Constants.LIST_ADMIN_ROLE.indexOf(Number(currentRoleId)) === -1}
                                        {...register("firstName", {required: true})}/>
                                    {errors.firstName && errors.firstName.type === "required" && (
                                        <p className="text-red-600">ФИО обязательно</p>
                                    )}
                                </div>
                            </div>
                            <div className={classNameFieldForm}>
                                <div className="w-[170px] flex-shrink-0">Отчество</div>
                                <div className="flex flex-col w-full">
                                    <input
                                        className="py-[8px] pl-[12px] pr-[10px] border-[1px] border-solid border-[#BDBDBD] w-full rounded-[5px]"
                                        placeholder="Введите отчество"
                                        disabled={Constants.LIST_ADMIN_ROLE.indexOf(Number(currentRoleId)) === -1}
                                        {...register("fatherName")}/>
                                </div>
                            </div>
                            <div className={classNameFieldForm}>
                                <div className="w-[170px] flex-shrink-0">Почта</div>
                                <div className="flex flex-col w-full">
                                    <input
                                        type="email"
                                        className="py-[8px] pl-[12px] pr-[10px] border-[1px] border-solid border-[#BDBDBD] w-full rounded-[5px]"
                                        placeholder="Введите почту"
                                        disabled={Constants.LIST_ADMIN_ROLE.indexOf(Number(currentRoleId)) === -1}
                                        {...register("email", {required: true})}/>
                                    {errors.email && errors.email.type === "required" && (
                                        <p className="text-red-600">Почта обязательна</p>
                                    )}
                                </div>
                            </div>
                            {Constants.LIST_ADMIN_ROLE.indexOf(Number(currentRoleId)) !== -1 && (
                                <>
                                    <div className={classNameFieldForm}>
                                        <div className="w-[170px] flex-shrink-0">Пароль</div>
                                        {user ? (
                                            <div onClick={setOnModalOpenPassword}
                                                 className="cursor-pointer underline underline-offset-4 decoration-dashed">Сменить
                                                пароль</div>
                                        ) : (
                                            <div className="flex w-full gap-[18px]">
                                                <div className="flex w-full flex-col">
                                                    <input
                                                        type="text"
                                                        className="py-[8px] pl-[12px] pr-[10px] border-[1px] border-solid border-[#BDBDBD] w-full rounded-[5px]"
                                                        placeholder="Введите пароль"
                                                        {...register("password", {required: true})}/>
                                                    {errors.password && errors.password.type === "required" && (
                                                        <p className="text-red-600">Пароль обязателен</p>
                                                    )}
                                                </div>
                                                <div className="flex items-start">
                                                    <div
                                                        className=" rounded-[5px] cursor-pointer bg-[#17AB57] px-[12px] py-[8px] text-white text-[16px] font-medium"
                                                        onClick={generatePassword}>Сгенерировать
                                                    </div>
                                                </div>
                                            </div>
                                        )}
                                    </div>
                                    {roles.data && (
                                        <>
                                            <div className={classNameFieldForm}>
                                                <div className="w-[170px] flex-shrink-0">Роль</div>
                                                <div className="flex flex-col w-full">
                                                    <Controller
                                                        control={control}
                                                        name={"role"}
                                                        rules={{
                                                            required: true
                                                        }}
                                                        defaultValue={rolesShow.find(r => r.id === Constants.STUDENT_ROLE)}
                                                        render={({field: {onChange, value}, fieldState: {error}}) =>
                                                            <>
                                                                <Select
                                                                    theme={(theme) => ({
                                                                        ...theme,
                                                                        borderRadius: 5,
                                                                        colors: {
                                                                            ...theme.colors,
                                                                            primary: "#17AB57",
                                                                            primary25: "#17AB571a"
                                                                        }
                                                                    })}
                                                                    placeholder={"Выберите роль"}
                                                                    onChange={(e) => {
                                                                        onChange(e)
                                                                        if (e) setActiveRole(e.slugName)
                                                                    }}
                                                                    value={value}
                                                                    menuPlacement="top"
                                                                    options={rolesShow}
                                                                    getOptionLabel={(role: IRole) => role.name}
                                                                    getOptionValue={(role: IRole) => String(role.id)}
                                                                />
                                                                {error && (
                                                                    <p className="text-red-600">Выберите роль</p>)}
                                                            </>
                                                        }
                                                    />
                                                </div>
                                            </div>
                                            {optionsMethodist.length > 0 && (
                                                <div className={classNameFieldForm}>
                                                    <div className="w-[170px] flex-shrink-0">Методист</div>
                                                    <div className="flex flex-col w-full">
                                                        <Controller
                                                            control={control}
                                                            name={"methodist"}
                                                            rules={{
                                                                required: true
                                                            }}
                                                            defaultValue={optionsMethodist.find(el => el.id === Number(currentUserId))}
                                                            render={({field: {onChange, value}, fieldState: {error}}) =>
                                                                <>
                                                                    <Select
                                                                        theme={(theme) => ({
                                                                            ...theme,
                                                                            borderRadius: 5,
                                                                            colors: {
                                                                                ...theme.colors,
                                                                                primary: "#17AB57",
                                                                                primary25: "#17AB571a"
                                                                            }
                                                                        })}
                                                                        menuPlacement="top"
                                                                        placeholder={"Выберите методиста"}
                                                                        onChange={(e) => {
                                                                            onChange(e)
                                                                        }}
                                                                        value={value}
                                                                        isSearchable={true}
                                                                        options={optionsMethodist}
                                                                        getOptionLabel={(methodist: IUserMethodist) => methodist.name}
                                                                        getOptionValue={(methodist: IUserMethodist) => String(methodist.id)}
                                                                    />
                                                                    {error && (<p className="text-red-600">Выберите методиста</p>)}
                                                                </>
                                                            }
                                                        />
                                                    </div>
                                                </div>
                                            )}
                                            {showBx24Id && (activeRole === "methodist" || activeRole === "administrator") && (
                                                <div className={classNameFieldForm}>
                                                    <div className="w-[170px] flex-shrink-0">Bx24 ID</div>
                                                    <div className="flex flex-col w-full">
                                                        <input
                                                            type="text"
                                                            className="py-[8px] pl-[12px] pr-[10px] border-[1px] border-solid border-[#BDBDBD] w-full rounded-[5px]"
                                                            placeholder="Введите id пользователя из Bx24"
                                                            {...register("bx24Id")}/>
                                                    </div>
                                                </div>
                                            )}
                                        </>
                                    )}
                                    <div className={classNameFieldForm}>
                                        <div className="w-[170px] flex-shrink-0">Сделка</div>
                                        <div className="flex w-full gap-[18px]">
                                            <div className="flex w-full flex-col">
                                                <input
                                                    type="number"
                                                    className="py-[8px] pl-[12px] pr-[10px] border-[1px] border-solid border-[#BDBDBD] w-full rounded-[5px]"
                                                    placeholder="Введите номер сделки"
                                                    {...register("dealId")}
                                                    onChange={e => handleChangeDeal(e)}/>
                                            </div>
                                        </div>
                                    </div>
                                </>
                            )}
                        </div>
                        {Constants.LIST_ADMIN_ROLE.indexOf(Number(currentRoleId)) !== -1 && (
                            <div className="flex gap-[12px] justify-end">
                                <div
                                    className={`py-[8px] px-[16px] bg-green-500 rounded-[5px] text-white text-[16px] font-medium` + (save ? " block" : " hidden")}>Сохранено
                                </div>
                                <button
                                    type="button"
                                    onClick={handleCancel}
                                    className="py-[8px] px-[16px] border-[#17AB57] border-solid border-[1px] rounded-[5px] text-[16px] font-medium">
                                    Отмена
                                </button>
                                <button
                                    type="submit"
                                    className="py-[8px] px-[16px] bg-[#17AB57] rounded-[5px] text-white text-[16px] font-medium">
                                    Сохранить
                                </button>
                            </div>
                        )}
                    </form>
                    {isModalPassword && user &&
                        <ModalPassword
                            user={user}
                            onClose={setOnModalClosePassword}/>}
                </div>
                {user && (Constants.LIST_ADMIN_ROLE.indexOf(Number(currentRoleId)) !== -1) && (
                    <div className="flex gap-[10px] flex-col text-center whitespace-nowrap">
                        {showCreateChat && (
                            <div onClick={onCreateChat}
                                 className="cursor-pointer px-[12px] py-[8px] border-solid border-[1px] rounded-[5px] border-[#17AB57] text-[16px] max-lg:hidden">
                                Создать чат
                            </div>
                        )}
                        <div onClick={onClearChat}
                             className="cursor-pointer px-[12px] py-[8px] border-solid border-[1px] rounded-[5px] border-[#17AB57] text-[16px] max-lg:hidden">
                            Очистить чат
                        </div>
                        <div onClick={onDelete}
                             className="cursor-pointer px-[12px] py-[8px] border-solid border-[1px] rounded-[5px] border-[#17AB57] text-[16px] max-lg:hidden">
                            Удалить пользователя
                        </div>
                    </div>
                )}
            </div>
            {
                user && (Constants.LIST_ADMIN_ROLE.indexOf(Number(currentRoleId)) !== -1) && (activeTab == 1) && (
                    <div className="flex gap-[10px] flex-col">
                        {showCreateChat && (
                            <div onClick={onCreateChat}
                                 className="hidden max-lg:block px-[12px] py-[8px] text-center rounded-[5px] text-[16px] font-medium text-[#292B2C] border-[#17AB57] border-solid border-[1px]">
                                Создать чат
                            </div>
                        )}
                        <div
                            onClick={onClearChat}
                            className="hidden max-lg:block px-[12px] py-[8px] text-center rounded-[5px] text-[16px] font-medium text-[#292B2C] border-[#17AB57] border-solid border-[1px]">
                            Очистить чат
                        </div>
                        <div
                            onClick={onDelete}
                            className="hidden max-lg:block px-[12px] py-[8px] text-center rounded-[5px] text-[16px] font-medium text-[#292B2C] border-[#17AB57] border-solid border-[1px]">
                            Удалить пользователя
                        </div>
                    </div>
                )}
        </>
    )
}

export default UserMainInformation