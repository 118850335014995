import {IFile} from "../../../../../../models/file";
import React from "react";
import PreviewLessonAdditionalFilesItem from "./lesson-additional-files-item/PreviewLessonAdditionalFilesItem";

interface IPreviewLessonAdditionalFiles {
    files: IFile[]
}

const PreviewLessonAdditionalFiles = (
    {
        files
    }: IPreviewLessonAdditionalFiles
) => {
    return (
        <div
            className="p-[32px] bg-white rounded-[6px] flex flex-col gap-[18px]">
            <div
                className="text-[24px] text-[#292B2C] font-bold whitespace-nowrap max-2xl:whitespace-normal leading-[120%]">Дополнительные
                материалы
            </div>
            <div className="flex flex-col w-full">
                {files.map((file, index) => (
                    <PreviewLessonAdditionalFilesItem file={file} key={index}/>
                ))}
            </div>
        </div>
    )
}

export default PreviewLessonAdditionalFiles