import React, {ReactNode} from "react";

interface Modal {
    children: ReactNode
    widthModal?: number
}

const Modal = (
    {
        children,
        widthModal
    }: Modal
) => {

    return (
        <div className="fixed top-0 left-0 flex items-center justify-center bg-[#00000040] w-screen h-screen">
            <div
                className={`p-[18px] bg-white rounded-[6px] max-w-[100%] max-lg:mx-[24px] ` + (widthModal ? `w-[${widthModal}px] ` : "w-[640px] ") }
                 onClick={e => {e.stopPropagation();}}>
                {children}
            </div>
        </div>
    )
}

export default Modal