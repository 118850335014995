import Modal from "../Modal";
import ErrorMessage from "../../error/ErrorMessage";
import {ReactComponent as CloseIcon} from "../../../../assets/images/global/close-modal-icon.svg";
import Portal from "../portal/Portal";
import React, {useEffect, useState} from "react";
import {useMutation, useQueryClient} from "react-query";
import {AxiosError} from "axios";
import {SubmitHandler, useForm} from "react-hook-form";
import {IUser, IUserPasswordRepeat} from "../../../../models/users";
import {UsersService} from "../../../../services/users.service";
import generator from "generate-password-ts";

interface IModalPassword {
    onClose: () => void
    user: IUser
}

const ModalPassword = (
    {
        onClose,
        user
    }: IModalPassword
) => {

    const generatePassword = () => {
        var password = generator.generate({
            length: 12,
            numbers: true
        })
        setValue("newPassword", password, {shouldValidate: true})
    }

    const {
        setValue,
        register,
        handleSubmit,
        getValues,
        formState: {errors}
    } = useForm<IUserPasswordRepeat>()

    const queryClient = useQueryClient()
    const [error, setError] = useState<number | null>(null)

    useEffect(() => {
        setValue("email", user.email)
    }, [user])

    const updatePassword = useMutation(
        (data: IUserPasswordRepeat) => UsersService.updatePassword(data),
        {
            onSuccess: (response) => {
                queryClient.invalidateQueries(["user", response.id])
                onClose()
            },
            onError: (error) => {
                const e = error as AxiosError
                setError(e.response ? e.response.status : 400)
            }
        }
    )

    const onSubmit: SubmitHandler<IUserPasswordRepeat> = async (data) => {
        updatePassword.mutate(data)
    }

    return (
        <Portal>
            <Modal>
                {error && <ErrorMessage numberError={error}/>}
                <form className="flex flex-col gap-[24px] text-[16px]">
                    <div className="flex justify-between items-center font-bold text-[16px]">
                        Смена пароля
                        <CloseIcon className="cursor-pointer" onClick={onClose}/>
                    </div>
                    <div className="flex flex-col gap-[18px]">
                        <input type="hidden" {...register("email", {required: true})}/>
                        {/*<div>*/}
                        {/*    <div className="text-[16px] font-medium">Старый пароль</div>*/}
                        {/*    <input*/}
                        {/*        className="border-[1px] border-solid border-[#BDBDBD] font-medium py-[8px] pl-[10px] pr-[12px] focus:outline-none focus:border-[#17AB57] w-full rounded-[5px]"*/}
                        {/*        type="password"*/}
                        {/*        placeholder="Введите старый пароль"*/}
                        {/*        {...register("oldPassword", {required: true})}/>*/}
                        {/*    {errors.oldPassword && errors.oldPassword.type === "required" && (*/}
                        {/*        <p className="text-red-600">Пароль обязателен</p>*/}
                        {/*    )}*/}
                        {/*</div>*/}
                        <div className="flex gap-[18px] w-full items-end max-sm:flex-col">
                            <div className="w-full">
                                <div className="text-[16px] font-medium">Новый пароль</div>
                                <input
                                    className="border-[1px] border-solid border-[#BDBDBD] font-medium py-[8px] pl-[10px] pr-[12px] focus:outline-none focus:border-[#17AB57] w-full rounded-[5px]"
                                    type="text"
                                    placeholder="Введите новый пароль"
                                    {...register("newPassword", {required: true})}/>
                                {errors.newPassword && errors.newPassword.type === "required" && (
                                    <p className="text-red-600">Пароль обязателен</p>
                                )}
                            </div>
                            <div className="flex items-start max-sm:w-full">
                                <div
                                    className="max-sm:w-full max-sm:text-center rounded-[5px] cursor-pointer bg-[#17AB57] px-[12px] py-[8px] text-white text-[16px] font-medium"
                                    onClick={generatePassword}>Сгенерировать
                                </div>
                            </div>
                        </div>

                        {/*<div>*/}
                        {/*    <div className="text-[16px] font-medium">Новый пароль ещё раз</div>*/}
                        {/*    <input*/}
                        {/*        className="border-[1px] border-solid border-[#BDBDBD] font-medium py-[8px] pl-[10px] pr-[12px] focus:outline-none focus:border-[#17AB57] w-full rounded-[5px]"*/}
                        {/*        type="password"*/}
                        {/*        placeholder="Введите новый пароль"*/}
                        {/*        {...register("repeatPassword", {validate: (value) => {*/}
                        {/*                const {newPassword} = getValues()*/}
                        {/*                return newPassword === value || "Пароль неверный"*/}
                        {/*            }})}/>*/}
                        {/*    {errors.repeatPassword && (*/}
                        {/*        <p className="text-red-600">{errors.repeatPassword.message}</p>*/}
                        {/*    )}*/}
                        {/*</div>*/}
                    </div>
                    <div className="flex gap-[12px] justify-end">
                        <button
                            type="submit"
                            onClick={handleSubmit(onSubmit)}
                            className="text-white font-medium py-[8px] px-[16px] rounded-[5px] bg-[#17AB57]"
                        >
                            Сохранить
                        </button>
                        <button
                            className="font-medium py-2 px-4 rounded-[5px] border-[1px] border-solid border-[#17AB57]"
                                onClick={onClose}>Отмена
                        </button>
                    </div>
                </form>
            </Modal>
        </Portal>
    )
}

export default ModalPassword