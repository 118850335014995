import {Link, useLocation, useParams, useSearchParams} from "react-router-dom";
import React, {useContext, useEffect, useRef, useState} from "react";
import {UsersService} from "../../../../../services/users.service";
import {AxiosError} from "axios";
import {useMutation, useQuery, useQueryClient} from "react-query";
import ErrorMessage from "../../../../ui/error/ErrorMessage";
import avatarDefault from "../../../../../assets/images/global/avatar.jpg";
import UsersTabs from "./users-tabs/UsersTabs";
import Cookies from "universal-cookie"
import {LoaderContext} from "../../../../../context/LoaderContext";
import Loader from "../../../../ui/loader/Loader";
import {Constants} from "../../../../../constans/constants";
import {ReactComponent as ArrowBackIcon} from "../../../../../assets/images/global/arrow-back-icon.svg";
import {AuthContext} from "../../../../../context/AuthContext";

interface ITab {
    id: number
    label: string
}

const UsersDetail = () => {

    const {show, setShow} = useContext(LoaderContext)
    // setShow(false)
    // {show && <Loader/>}
    //
    // if (users.isFetching) {
    //     setShow(true)
    // }

    const {id} = useParams()
    const [searchParams, setSearchParams] = useSearchParams();
    const cookies = new Cookies()
    const refAvatar = useRef<HTMLInputElement>(null)
    const queryClient = useQueryClient()

    const [avatarUser, setAvatarUser] = useState<string>("")
    const [activeTab, setActiveTab] = useState<number>(searchParams.get("event") ? 3 : 1)
    const [error, setError] = useState<number | null>(null)
    const [showBackGroup, setShowBackGroup] = useState<boolean>(false)
    const {currentRoleId, currentUserId} = useContext(AuthContext)

    const {state} = useLocation()

    useEffect(() => {
        if(state && state.group) {
            setShowBackGroup(state.group)
        }
    }, [state])

    const user = useQuery(
        ["user", id],
        () => UsersService.getById(id),
        {
            enabled: !!id,
            onSuccess: (response) => {
                if (response.avatar)
                    setAvatarUser(response.avatar)
            }
        }
    )

    let tabs: ITab[] = []
    tabs.push({id: 1, label: "Информация"})

    if (user.data && Constants.LIST_ADMIN_ROLE.indexOf(Number(currentRoleId)) !== -1) {
        tabs.push({id: 2, label: "Группы"})
        tabs.push({id: 3, label: "Назначенные курсы"})
        tabs.push({id: 4, label: "Статистика"})
    }

    const classTab = "px-[36px] py-[12px] bg-[#F2F2F2] border-b-[4px] border-solid border-[#BDBDBD] cursor-pointer text-[20px] text-[#292B2C] font-medium leading-[120%] flex items-center " +
        "max-lg:rounded-[43px] max-lg:px-[12px] max-lg:py-[4px] max-lg:bg-[#E0E0E0] max-lg:text-[14px] max-lg:text-[14px] max-lg:border-none whitespace-nowrap"

    const uploadAvatar = () => {
        if (refAvatar.current) {
            refAvatar.current.click()
        }
    }

    const changeAvatar = useMutation(
        (data: any) => UsersService.uploadAvatar(data),
        {
            onSuccess: (response) => {
                setShow(false)
                if (response.avatar) {
                    setAvatarUser(response.avatar)
                    queryClient.invalidateQueries(["current_user", currentUserId])
                }
            },
            onError: (errorChange) => {
                const e = errorChange as AxiosError
                setError(e.response ? e.response.status : 400)
                setShow(false)
            }
        }
    )

    const onChangeAvatar = async (e: React.ChangeEvent<HTMLInputElement>) => {
        if (e.target.files) {
            setShow(true)
            let obj = {
                "file": e.target.files[0],
                "userId": user.data?.id
            }
            changeAvatar.mutate(obj)
        }
    }


    if (user.isLoading) return <Loader/>

    if (user.isError) {
        const e = user.error as AxiosError
        return <ErrorMessage numberError={e.response ? e.response.status : 400}/>
    }

    // if(!user.data) return <ErrorMessage numberError={400}/>

    return (
        <div className="flex flex-col gap-[34px] h-full max-lg:gap-[12px]">
            {show && <Loader/>}
            {user.data && (
                <>
                    {error && <ErrorMessage numberError={error}/>}
                    <div className="w-full rounded-[6px] bg-white p-[32px] max-lg:p-[14px] flex items-center justify-between max-md:flex-col max-md:items-start max-md:gap-[10px]">
                        <div className="flex gap-[24px] items-center">
                            <div
                                className="w-[81px] h-[81px] rounded-[49px] overflow-hidden cursor-pointer group relative flex-shrink-0">
                                {avatarUser ? (
                                    <img src={avatarUser} className="w-full h-full object-cover" alt=""/>
                                ) : (
                                    <div className={`w-full h-full flex justify-center items-center text-white font-[500] text-[24px] ${Constants.LIST_COLORS[Number(user.data.color)]}`}>
                                        {user.data.lastName.trim()[0]}{user.data.firstName.trim()[0]}
                                    </div>
                                )}
                                <div onClick={uploadAvatar}
                                     className="absolute top-0 left-0 bg-[#ffffffe6] z-[10] w-full h-full text-[10px] flex group-hover:opacity-100 opacity-0 transition-all items-center justify-center">
                                    Загрузить фото
                                </div>
                                <input accept="image/*" type="file" className="hidden" ref={refAvatar} onChange={onChangeAvatar}/>
                            </div>
                            <div className="flex flex-col gap-[4px]">
                                <div
                                    className="text-[24px] text-[#292B2C] font-bold">{user.data.lastName} {user.data.firstName} {user.data.fatherName}</div>
                                <div className="text-[16px] text-[#BDBDBD] font-medium">{user.data.role.name}</div>
                            </div>
                        </div>
                        {showBackGroup && (
                            <Link
                                to={state.pathname}
                                className="flex gap-[6px] py-[8px] px-[12px] border-solid border-[1px] border-[#17AB57] rounded-[5px] cursor-pointer">
                                <ArrowBackIcon/>
                                Вернуться к группе
                            </Link>
                        )}
                    </div>
                </>
            )}
            <div className="flex flex-col gap-[34px] h-full min-h-0 max-lg:gap-[12px]">
                <div className="flex mb-[8px] max-lg:gap-[8px] overflow-auto">
                    {tabs && tabs.map((tab, index) => (
                        <div onClick={() => setActiveTab(tab.id)} key={index}
                             className={classTab + ((activeTab == tab.id) ? " !border-[#17AB57] focus:outline-none max-lg:text-white max-lg:text-[14px] max-lg:!border-none max-lg:!bg-[#17AB57] " : "")}>
                            {tab.label}
                        </div>
                    ))}
                </div>
                <UsersTabs activeTab={activeTab} user={user.data}/>
            </div>
        </div>
    )
}

export default UsersDetail