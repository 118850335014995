import Modal from "../Modal";
import ErrorMessage from "../../error/ErrorMessage";
import {ReactComponent as CloseIcon} from "../../../../assets/images/global/close-modal-icon.svg";
import Portal from "../portal/Portal";
import React, {useContext, useState} from "react";
import {useMutation, useQuery, useQueryClient} from "react-query";
import {Controller, SubmitHandler, useForm} from "react-hook-form";
import {AxiosError} from "axios";
import {ICoursesGroup, IGroupDetail} from "../../../../models/groups";
import {GroupsService} from "../../../../services/groups.service";
import Select from "react-select";
import {LoaderContext} from "../../../../context/LoaderContext";
import Loader from "../../loader/Loader";
import {ReactComponent as CheckboxUnchecked} from "../../../../assets/images/tests/checkbox-unchecked.svg";
import {ReactComponent as CheckboxChecked} from "../../../../assets/images/tests/checkbox-checked.svg";

interface IModalAddGroup {
    onClose: () => void,
}

const ModalAddGroup = (
    {
        onClose,
    }: IModalAddGroup
) => {

    const {show, setShow} = useContext(LoaderContext)
    const queryClient = useQueryClient()
    const [error, setError] = useState<number | null>(null)

    const {
        register,
        handleSubmit,
        formState: {errors}
    } = useForm<IGroupDetail>()

    const listCourses = useQuery(
        ["courses_attach_group"],
        () => GroupsService.getCourses(),
        {
            onSuccess: () => {}
        }
    )

    const addGroup = useMutation(
        (data: IGroupDetail) => GroupsService.create(data),
        {
            onSuccess: () => {
                queryClient.invalidateQueries(["groups"])
                setShow(false)
                onClose()
            },
            onError: (error) => {
                setShow(false)
                const e = error as AxiosError
                setError(e.response ? e.response.status : 400)
            }
        }
    )

    const onSubmit: SubmitHandler<IGroupDetail> = async (data) => {
        setShow(true)
        addGroup.mutate(data)
    }

    if (listCourses.isLoading) return <div className="text-center">Загрузка...</div>

    if (listCourses.isError) {
        const e = listCourses.error as AxiosError
        return <ErrorMessage numberError={e.response ? e.response.status : 400}/>
    }

    if (!listCourses.data) return <div className="text-center">Ошибка данных</div>

    return (
        <Portal>
            <Modal>
                {show && <Loader/>}
                {error && <ErrorMessage numberError={error}/>}
                <form className="flex flex-col gap-[24px] text-[16px]" onSubmit={handleSubmit(onSubmit)}>
                    <div className="flex justify-between items-center font-bold text-[16px]">
                        Создание новой группы
                        <CloseIcon className="cursor-pointer" onClick={onClose}/>
                    </div>
                    <div className="flex flex-col gap-[12px]">
                        <div>
                            <input
                                className="border-[1px] border-solid border-[#BDBDBD] font-medium py-[8px] pl-[10px] pr-[12px] focus:outline-none focus:border-[#17AB57] w-full rounded-[5px]"
                                type="text"
                                placeholder="Введите название группы"
                                {...register("name", {required: true})}/>
                            {errors.name && errors.name.type === "required" && (
                                <p className="text-red-600">Название обязательно</p>
                            )}
                        </div>
                    </div>
                    <div className="flex gap-[12px] justify-end">
                        <button
                            type="submit"
                            className="text-white font-medium py-[8px] px-[16px] rounded-[5px] bg-[#17AB57]"
                        >
                            Создать
                        </button>
                        <button
                            className="font-medium py-2 px-4 rounded-[5px] border-[1px] border-solid border-[#17AB57]"
                            onClick={onClose}>Отмена
                        </button>
                    </div>
                </form>
            </Modal>
        </Portal>
    )
}

export default ModalAddGroup