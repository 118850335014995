import {ReactComponent as SearchIcon} from "../../../../assets/images/global/searcg-icon.svg";
import {ReactComponent as CreateCoursesIcon} from "../../../../assets/images/folders/create-courses-icon.svg";
import {ReactComponent as ArrowSortIcon} from "../../../../assets/images/global/arrow-sort-icon.svg";
import React, {useEffect, useRef, useState} from "react";
import {useQuery} from "react-query";
import {GroupsService} from "../../../../services/groups.service";
import {IGroup} from "../../../../models/groups";
import {AxiosError} from "axios";
import ErrorMessage from "../../../ui/error/ErrorMessage";
import GroupsItem from "./groups-item/GroupsItem";
import useToggle from "../../../../hooks/useToggle";
import ModalAddGroup from "../../../ui/modal/modal-add-group/ModalAddGroup";
import ScrollContainer from "../../../ui/scroll-container/ScrollContainer";

const Groups = () => {

    const [error, setError] = useState<number|null>(null)
    const [blurSearch, setBlurSearch] = useState<boolean>(false)
    const [activeSort, setActiveSort] = useState<boolean>(false)
    const [filteredGroups, setFilteredGroups] = useState<IGroup[]>([])
    const [isModalAddGroup, openIsModalAddGroup, closeIsModalAddGroup] = useToggle()
    const [rect, setRect] = useState<DOMRect>({} as DOMRect)
    const refListGroups = useRef<HTMLDivElement>(null)
    const [searchTerm, setSearchTerm] = useState<string>("")
    const [page, setPage] = useState<number>(1)
    const [showLoadMore, setShowLoadMore] = useState<boolean>(true)

    const groups = useQuery(
        ["groups", page, searchTerm],
        () => GroupsService.getByPage(page, searchTerm ? searchTerm : ""),
        {
            keepPreviousData: true,
            onSuccess: (response) => {
                if(page == 1) {
                    setFilteredGroups(response.groups)
                } else {
                    response.groups.map(group => {
                        setFilteredGroups(prev => [...prev, group])
                    })
                }
                setShowLoadMore(true)
                if(response.left <= 0) {
                    setShowLoadMore(false)
                }
            }
        }
    )

    useEffect(() => {
        if (filteredGroups.length > 0 && refListGroups.current) {
            setRect(refListGroups.current.getBoundingClientRect())
        }
    }, [filteredGroups])

    const handleSearch = (e: React.ChangeEvent<HTMLInputElement>) => {
        setPage(1)
        setSearchTerm(e.target.value)
    }

    const handleSort = () => {
        const arrSort = [
            {method: (a: IGroup, b: IGroup) => a.name > b.name ? 1 : -1},
            {method: (a: IGroup, b: IGroup) => a.name < b.name ? 1 : -1}
        ]
        const sorted = filteredGroups.sort(!activeSort ? arrSort[0].method : arrSort[1].method)
        setFilteredGroups([...sorted])
        setActiveSort(!activeSort)
    }

    const onDelete = (id: number) => {
        setFilteredGroups(filteredGroups.filter(g => g.id != id))
    }

    const onScroll = () => {
        if (refListGroups.current) {
            setRect(refListGroups.current.getBoundingClientRect())
        }
    }

    if (groups.isLoading) return <div className="text-center">Загрузка...</div>

    if (groups.isError) {
        const e = groups.error as AxiosError
        return <ErrorMessage numberError={e.response ? e.response.status : 400}/>
    }

    if (!groups.data) return <div className="text-center">Данных нет</div>

    return (
        <div className="h-full">
            <div className="flex flex-col gap-[34px] h-full max-lg:gap-[24px]">
                <div className="text-[36px] font-bold text-[#292B2C] leading-[120%]">Группы</div>
                <div className="w-full flex gap-[12px] items-center text-[16px] max-lg:gap-[12px]">
                    <div className="relative w-full">
                        <SearchIcon
                            className={`absolute top-[8px] left-[10px] ` + (blurSearch ? "fill-[#292B2C]" : "")}/>
                        <input
                            onFocus={() => {
                                setBlurSearch(true)
                            }}
                            onBlur={() => {
                                setBlurSearch(false)
                            }}
                            onChange={handleSearch}
                            type="text"
                            placeholder="Поиск"
                            className="py-[8px] pl-[36px] pr-[12px] border-solid border-[1px] border-[#BDBDBD] rounded-[5px] bg-[#F2F2F2] w-full focus:border-[#17AB57] focus:outline-none"/>
                    </div>
                    <div
                        onClick={openIsModalAddGroup}
                        className="bg-[#17AB57] text-white rounded-[5px] items-center flex gap-[6px] py-[8px] pl-[12px] pr-[16px] font-medium whitespace-nowrap cursor-pointer justify-center">
                        <CreateCoursesIcon className="flex-shrink-0"/>
                        Добавить
                    </div>
                </div>
                <div
                    ref={refListGroups}
                    className="bg-white p-[32px] rounded-[6px] flex flex-col gap-[18px] text-[16px] font-medium max-lg:px-[14px] max-lg:py-[14px] max-lg:gap-0 h-full">
                    {error && <ErrorMessage numberError={error}/>}
                    {filteredGroups.length > 0 ? (
                        <>
                            <div
                                className="pl-[18px] flex justify-between text-[#828282] text-[16px] font-medium max-lg:hidden">
                                <div className="flex justify-between">
                                    <div className="cursor-pointer flex gap-[4px]"
                                         onClick={handleSort}
                                    >
                                        Название
                                        <ArrowSortIcon
                                            className={!activeSort ? "rotate-180 transition-all" : "rotate-0 transition-all"}/>
                                    </div>
                                </div>
                                <div className="flex justify-end">Действия</div>
                            </div>
                            {/*<ScrollContainer onScroll={onScroll}>*/}
                                <div
                                    className="h-full">
                                    {filteredGroups.map((group, index) => (
                                        <GroupsItem setError={setError} onDelete={onDelete} group={group} key={index} parentParameter={rect}/>
                                    ))}
                                </div>
                            {/*</ScrollContainer>*/}
                        </>
                    ) : (
                        <div className="flex justify-center items-center h-full">
                            <div className="flex flex-col gap-[18px] text-center">
                                <div className="text-[24px] font-medium text-[#828282]">Групп пока нет</div>
                                <div
                                    onClick={openIsModalAddGroup}
                                    className="text-white py-[8px] px-[16px] w-full bg-[#17AB57] cursor-pointer text-[16px] rounded-[5px]">
                                    Добавить группу
                                </div>
                            </div>
                        </div>
                    )}
                </div>
                {filteredGroups.length > 0 && showLoadMore && (
                    <div
                        onClick={() => setPage(prev => prev + 1)}
                        className="flex text-center w-full px-[16px] py-[8px] border-[1px] border-solid border-[#17AB57] justify-center cursor-pointer rounded-[5px] text-[16px] font-medium text-[#292B2C] leading-[120%]">
                        Показать ещё
                    </div>
                )}
            </div>
            {isModalAddGroup && <ModalAddGroup onClose={closeIsModalAddGroup}/>}
        </div>
    )
}

export default Groups