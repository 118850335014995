import {IFolder, IFolderDetailId} from "../../../../../models/folders";
import {ReactComponent as FolderIcon} from "../../../../../assets/images/folders/folder-icon.svg";
import {ReactComponent as ActionIcon} from "../../../../../assets/images/global/action-icon-horizontal.svg";
import {ReactComponent as DeleteIcon} from "../../../../../assets/images/folders/delete-folder-icon.svg";
import {Link} from "react-router-dom";
import React, {useContext, useEffect, useRef, useState} from "react";
import {AxiosError} from "axios";
import {FolderService} from "../../../../../services/folder.service";
import ModalRenameFolder from "../../../../ui/modal/modal-rename-folder/ModalRenameFolder";
import useToggle from "../../../../../hooks/useToggle";
import {ReactComponent as EditIcon} from "../../../../../assets/images/global/edit-icon.svg";
import dayjs from "dayjs";
import {useMutation} from "react-query";
import {LoaderContext} from "../../../../../context/LoaderContext";
import Loader from "../../../../ui/loader/Loader";

interface Item {
    folder: IFolder
    removeFolder: (id: number) => void
    parentParameter: DOMRect
    setError: (error: number) => void
    setShowSearchResult: (show: boolean) => void,
    onClearInput: () => void
    setSearchTerm: (name: string) => void
}

const FoldersItem = (
    {
        folder,
        removeFolder,
        parentParameter,
        setError,
        setShowSearchResult,
        onClearInput,
        setSearchTerm
    }: Item
) => {

    const {show, setShow} = useContext(LoaderContext)
    const [folderItem, setFolderItem] = useState<IFolder>({} as IFolder)
    const [showMenu, setShowMenu] = useState<boolean>(false)
    const [bottomMenu, setBottomMenu] = useState<boolean>(true)
    const [isModalRenameFolder, setOnModalRenameFolder, setOnModalCloseRenameFolder] = useToggle()
    const [folderRename, setFolderRename] = useState<IFolder>({} as IFolder)
    const ref = useRef<HTMLDivElement>(null)

    useEffect(() => {
        setFolderItem(folderRename)
    }, [folderRename])

    useEffect(() => {
        setFolderItem(folder)
    }, [folder])

    useEffect(() => {
        if (ref.current) {
            const hParent = parentParameter.height + parentParameter.y - 32
            const hMenu = ref.current.getBoundingClientRect().y + 15 + 80
            if (hMenu > hParent)
                setBottomMenu(false)
        }
    }, [parentParameter])

    const handleClick = () => {
        setShowMenu(!showMenu)
    }

    useEffect(() => {
        if (!showMenu) return
        handleClickRef(ref, handleClick)
    }, [showMenu])

    const handleClickRef = (ref: React.RefObject<HTMLDivElement>, handleSet: () => void) => {
        const handleClick = (e: MouseEvent) => {
            let element = e.target as HTMLDivElement
            if (!ref.current) return
            if (!ref.current.contains(element)) {
                handleSet()
            }
        }

        document.addEventListener('click', handleClick)
        return () => {
            document.removeEventListener('click', handleClick)
        }
    }

    const deleteFolder = useMutation(
        (data: IFolderDetailId) => FolderService.delete(data),
        {
            onSuccess: () => {
                setShow(false)
                if (folderItem.id)
                    removeFolder(folderItem.id)
            },
            onError: (error) => {
                setShow(false)
                const e = error as AxiosError
                setError(e.response ? e.response.status : 400)
            }
        }
    )

    const onDelete = async () => {
        let deleteSuccess = window.confirm("Вы действительно хотите удалить папку? Это действие необратимо")
        if(deleteSuccess) {
            setShow(true)
            handleClick()
            let data = {
                id: folder.id,
            }
            deleteFolder.mutate(data)
        } else {
            handleClick()
        }
    }

    const handleRename = () => {
        handleClick()
        setOnModalRenameFolder()
    }

    const handleClickLink = () => {
        setShowSearchResult(false)
        onClearInput()
        setSearchTerm("")
    }

    return (
        <div
            className="px-[18px] py-[20px] hover:bg-[#17AB571a] flex justify-between items-center max-lg:p-0 border-b-[2px] border-solid border-[#BDBDBD] last:border-0 max-lg:py-[12px]">
            {show && <Loader/>}
            <div className="w-[30%] flex gap-[12px] items-center max-lg:gap-[6px] max-lg:w-[100%]">
                <FolderIcon className="flex-shrink-0"/>
                <Link onClick={handleClickLink} to={`/folders/${folderItem.id}`}>{folderItem.name}</Link>
            </div>
            <div
                className="w-[10%] whitespace-nowrap max-lg:hidden">{dayjs(folderItem.createdDate).format("DD.MM.YYYY HH:mm")}</div>
            <div
                className="w-[10%] whitespace-nowrap max-lg:hidden">{dayjs(folderItem.updatedDate).format("DD.MM.YYYY HH:mm")}</div>
            <div ref={ref} className="w-[6%] flex justify-end relative">
                <ActionIcon className="flex-shrink-0 cursor-pointer" onClick={handleClick}/>
                <div
                    className={`absolute right-[10px] bg-white rounded-[6px] overflow-hidden shadow-[0_5px_10px_0_rgba(0,0,0,0.37)] z-[90]` + (showMenu ? " block" : " hidden") + (bottomMenu ? " top-[15px]" : " bottom-[20px]")}>
                    <div
                        onClick={handleRename}
                        className="px-[12px] py-[10px] flex gap-[12px] items-center hover:bg-[#17AB571a] cursor-pointer">
                        <EditIcon/>
                        Переименовать
                    </div>
                    <div onClick={onDelete}
                         className="px-[12px] py-[10px] flex gap-[12px] items-center hover:bg-[#17AB571a] cursor-pointer">
                        <DeleteIcon/>
                        Удалить
                    </div>
                </div>
            </div>
            {isModalRenameFolder && <ModalRenameFolder
                folder={folderItem}
                setFolderRename={setFolderRename}
                onClose={setOnModalCloseRenameFolder}/>}
        </div>
    )
}

export default FoldersItem