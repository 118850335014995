import {IFile, IFileDelete, IFileUpdateName} from "../models/file";
import axios from "axios";
import AuthHeader from "./auth-header";
import {Constants} from "../constans/constants";

const contentType = {'Content-Type': 'multipart/form-data'}

export const FileService = {
    async create(data: any) {
        const response = await axios.post<IFile>(Constants.LOCATION + `/api/File/add/`, data, {
            headers: Object.assign(contentType, AuthHeader())
        })
        return response.data
    },

    async delete(data: IFileDelete) {
        return await axios.post<boolean>(Constants.LOCATION + `/api/File/delete/`, data, {headers: AuthHeader()})
    },

    async update(id: number, data: IFileUpdateName) {
        return await axios.post<IFile>(Constants.LOCATION + `/api/File/update/${id}`, data, {headers: AuthHeader()})
    },

    async getLinkForDownload(link: string) {
        const {request} = await axios.head<any>(link)
        return request
    }
}