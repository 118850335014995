import React, {useEffect} from 'react';
import Router from "./components/Router";
import Navigation from "./components/ui/navigation/Navigation";
import Layout from "./components/ui/layout/Layout";
import Banner from "./components/ui/banner/Banner";
import {ConstantsInvisible} from "./constans/constants-invisible";
import dayjs from "dayjs";

function App() {

    const [showBanner, setShowBanner] = React.useState<boolean>(false);

    useEffect(() => {

        let now = dayjs().format("DD.MM.YYYY")
        if (now >= ConstantsInvisible.DATE_FROM_SHOW_BANNER && now < ConstantsInvisible.DATE_TO_SHOW_BANNER) {
            // setShowBanner(true);
        }
    }, []);

    return (
        <>
            {showBanner ? (
                <div className="h-screen w-screen">
                    <Banner/>
                </div>
            ) : (
                <div className="flex max-lg:flex-col-reverse h-screen w-screen font-roboto leading-[120%] not-italic">
                    <Navigation/>
                    <Layout>
                        <Router/>
                    </Layout>
                </div>
            )}
        </>
    )
}

export default App;
