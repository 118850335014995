import {IListItemLessonByCourse} from "../../../../../../../../models/lessons";
import {ReactComponent as LessonIcon} from "../../../../../../../../assets/images/folders/lesson-icon.svg";
import {ReactComponent as TestIcon} from "../../../../../../../../assets/images/folders/test-icon.svg";
import dayjs from "dayjs";
import {ReactComponent as ActionIcon} from "../../../../../../../../assets/images/global/action-icon-horizontal.svg";
import {ReactComponent as Star} from "../../../../../../../../assets/images/global/yellow-star.svg";
import {ReactComponent as EditIcon} from "../../../../../../../../assets/images/global/edit-icon.svg";
import {ReactComponent as PreviewIcon} from "../../../../../../../../assets/images/folders/preview-icon.svg";
import {ReactComponent as DeleteIcon} from "../../../../../../../../assets/images/folders/delete-folder-icon.svg";
import React, {useContext, useEffect, useRef, useState} from "react";
import {useMutation, useQueryClient} from "react-query";
import {LessonsService} from "../../../../../../../../services/lessons.service";
import {Link} from "react-router-dom";
import {LoaderContext} from "../../../../../../../../context/LoaderContext";
import Loader from "../../../../../../../ui/loader/Loader";

interface ICourseLessonItem {
    lesson: IListItemLessonByCourse
    parentParameter: DOMRect
    setLessonId: (id: number) => void
    setLessonEdit: (flag: boolean) => void
    index: number
    lastIndex: number
    onDelete: (id: number) => void
    courseId: number
    searchTerm?: string
}

const CourseLessonItem = (
    {
        lesson,
        parentParameter,
        setLessonId,
        setLessonEdit,
        onDelete,
        courseId,
        searchTerm,
        index
    }: ICourseLessonItem
) => {

    const {show, setShow} = useContext(LoaderContext)
    const [showMenu, setShowMenu] = useState<boolean>(false)
    const [bottomMenu, setBottomMenu] = useState<boolean>(true)
    const ref = useRef<HTMLDivElement>(null)

    const queryClient = useQueryClient()

    useEffect(() => {
        if (ref.current) {
            const hParent = parentParameter.height + parentParameter.y - 32
            const hMenu = ref.current.getBoundingClientRect().y + 15 + 80
            if (hMenu > hParent) {
                setBottomMenu(false)
            }
        }
    }, [parentParameter, searchTerm])

    const handleClick = () => {
        setShowMenu(!showMenu)
    }

    useEffect(() => {
        if (!showMenu) return
        handleClickRef(ref, handleClick)
    }, [showMenu])

    const handleClickRef = (ref: React.RefObject<HTMLDivElement>, handleSet: () => void) => {
        const handleClick = (e: MouseEvent) => {
            let element = e.target as HTMLDivElement
            if (!ref.current) return
            if (!ref.current.contains(element)) {
                handleSet()
            }
        }

        document.addEventListener('click', handleClick)
        return () => {
            document.removeEventListener('click', handleClick)
        }
    }

    const handleEdit = () => {
        handleClick()
        setLessonId(lesson.id)
        setLessonEdit(true)
    }

    const handleOrder = (up: boolean) => {
        setShow(true)
        const data = {
            "up": up
        }
        updateOrderLesson.mutate(data)
        handleClick()
    }

    const updateOrderLesson = useMutation(
        (data: any) => LessonsService.updateOrder(lesson.id, data),
        {
            onSuccess: () => {
                setShow(false)
                queryClient.invalidateQueries(['list_lessons_course', courseId])
            }
        }
    )

    const handleDelete = () => {
        let deleteSuccess = window.confirm("Вы действительно хотите удалить урок? Это действие необратимо")
        if (deleteSuccess) {
            setShow(true)
            deleteLesson.mutate(lesson.id)
            handleClick()
        } else {
            handleClick()
        }

    }

    const deleteLesson = useMutation(
        (id: number) => LessonsService.delete(id),
        {
            onSuccess: () => {
                queryClient.invalidateQueries(['course_detail', String(courseId)])
                setShow(false)
                onDelete(lesson.id)
            }
        }
    )

    return (
        <div
            className="flex justify-between items-center px-[18px] py-[20px] border-solid border-b-[2px] border-[#BDBDBD] last:border-none max-lg:py-[12px] max-lg:px-0">
            {show && <Loader/>}
            <div className="w-[25%] flex gap-[12px] items-center max-lg:w-full">
                <div className="relative">
                    {lesson.testId ? (<TestIcon className="flex-shrink-0"/>) : (<LessonIcon className="flex-shrink-0"/>)}
                </div>
                {++index}. {lesson.name}
            </div>
            <div className="w-[10%] max-lg:hidden flex gap-[5px] items-center">{lesson.testId ? "Тестирование" : "Урок"}  {lesson.isBase && <Star className="w-[15px] h-[15px]"/>}</div>
            <div
                className="w-[10%] whitespace-nowrap max-lg:hidden">{dayjs(lesson.createdDate).format("DD.MM.YYYY HH:mm")}</div>
            <div
                className="w-[10%] whitespace-nowrap max-lg:hidden">{dayjs(lesson.updatedDate).format("DD.MM.YYYY HH:mm")}</div>
            <div className="w-[6%] flex justify-end">
                <div ref={ref} className="w-[6%] flex justify-end relative">
                    <ActionIcon className="flex-shrink-0 cursor-pointer" onClick={handleClick}/>
                    <div
                        className={`absolute right-[10px] bg-white rounded-[6px] overflow-hidden shadow-[0_5px_10px_0_rgba(0,0,0,0.37)] z-[90]` + (showMenu ? " block" : " hidden") + (bottomMenu ? " top-[15px]" : " bottom-[20px]")}>
                        <Link to={lesson.testId === null ? `/folders/preview-lesson/${lesson.id}` : `/folders/preview-test/${lesson.id}/${lesson.testId}`}
                              className="px-[12px] py-[10px] flex gap-[12px] items-center hover:bg-[#17AB571a] cursor-pointer">
                            <PreviewIcon/>
                            Предпросмотр
                        </Link>
                        <div
                            onClick={handleEdit}
                            className="px-[12px] py-[10px] flex gap-[12px] items-center hover:bg-[#17AB571a] cursor-pointer">
                            <EditIcon/>
                            Редактировать
                        </div>
                        <div
                            onClick={handleDelete}
                            className="px-[12px] py-[10px] flex gap-[12px] items-center hover:bg-[#17AB571a] cursor-pointer border-solid border-[#BDBDBD] border-b-[2px]">
                            <DeleteIcon/>
                            Удалить
                        </div>
                        {/*{index != 0 && (*/}
                        {/*    <div*/}
                        {/*        onClick={() => handleOrder(true)}*/}
                        {/*        className="px-[12px] py-[10px] flex gap-[12px] items-center hover:bg-[#17AB571a] cursor-pointer whitespace-nowrap">*/}
                        {/*        <ArrowUp/>*/}
                        {/*        Перенести выше*/}
                        {/*    </div>*/}
                        {/*)}*/}
                        {/*{index != lastIndex && (*/}
                        {/*    <div*/}
                        {/*        onClick={() => handleOrder(false)}*/}
                        {/*        className="px-[12px] py-[10px] flex gap-[12px] items-center hover:bg-[#17AB571a] cursor-pointer whitespace-nowrap">*/}
                        {/*        <ArrowDown/>*/}
                        {/*        Перенести ниже*/}
                        {/*    </div>*/}
                        {/*)}*/}
                    </div>
                </div>
            </div>
        </div>
    )
}

export default CourseLessonItem