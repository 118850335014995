import {IUser, IUserCourse, IUserCourses, IUserCourseUntie} from "../../../../../../../models/users";
import {useMutation, useQuery, useQueryClient} from "react-query";
import {UsersService} from "../../../../../../../services/users.service";
import {AxiosError} from "axios";
import ErrorMessage from "../../../../../../ui/error/ErrorMessage";
import React, {useRef, useState} from "react";
import {ReactComponent as ArrowSortIcon} from "../../../../../../../assets/images/global/arrow-sort-icon.svg";
import {ReactComponent as CoursesIcon} from "../../../../../../../assets/images/folders/courses-icon.svg";
import {ReactComponent as DeleteIcon} from "../../../../../../../assets/images/users/delete-course.svg";
import {ReactComponent as EditCourseIcon} from "../../../../../../../assets/images/users/edit-course.svg";
import {ReactComponent as DownloadUpIcon} from "../../../../../../../assets/images/users/download-up.svg";
import {ReactComponent as ProgressIcon} from "../../../../../../../assets/images/users/progress-course.svg";
import {ReactComponent as BlockCourse} from "../../../../../../../assets/images/users/block-course.svg";
import {ReactComponent as UnblockCourse} from "../../../../../../../assets/images/users/unblock-course.svg";
import useToggle from "../../../../../../../hooks/useToggle";
import ModalAttachCourseUser from "../../../../../../ui/modal/modal-attach-course-user/ModalAttachCourseUser";
import {Link} from "react-router-dom";
import ScrollContainer from "../../../../../../ui/scroll-container/ScrollContainer";
import Loader from "../../../../../../ui/loader/Loader";

interface IUserCoursesTab {
    user: IUser
}

const UserCourses = (
    {
        user
    }: IUserCoursesTab
) => {

    const queryClient = useQueryClient()

    const [activeSort, setActiveSort] = useState<boolean>(false)
    const [filteredCourses, setFilteredCourses] = useState<IUserCourses[]>([])
    const [isModalAttachCourse, setOnModalOpenAttachCourse, setOnModalCloseAttachCourse] = useToggle()
    const [error, setError] = useState<number | null>(null)
    const [showLoader, setShowLoader] = useState<boolean>(false)
    const refDownloadFile = useRef<HTMLAnchorElement>(null)


    const courses = useQuery(
        ["user_courses", user.id],
        () => UsersService.getCourses(user.id),
        {
            onSuccess: (response) => {
                setFilteredCourses(response)
            }
        }
    )

    const handleSort = () => {
        const arrSort = [
            {method: (a: IUserCourses, b: IUserCourses) => a.course.name > b.course.name ? 1 : -1},
            {method: (a: IUserCourses, b: IUserCourses) => a.course.name < b.course.name ? 1 : -1}
        ]
        const sorted = filteredCourses.sort(!activeSort ? arrSort[0].method : arrSort[1].method)
        setFilteredCourses([...sorted])
        setActiveSort(!activeSort)
    }

    const untieCourse = useMutation(
        (data: IUserCourseUntie) => UsersService.untieCourse(data),
        {
            onSuccess: () => {
                queryClient.invalidateQueries(["user_courses", user.id])
            }
        }
    )

    const untieCourseHandle = (courseId: number) => {
        let untieSuccess = window.confirm("Вы действительно хотите отвязать курс у пользователя? Это действие необратимо")
        if(untieSuccess) {
            const data: IUserCourseUntie = {
                "userId": user.id,
                "courseId": courseId
            }
            untieCourse.mutate(data)
        }
    }

    const downloadUps = useMutation(
        (data: IUserCourse) => UsersService.downloadUps(data),
        {
            onSuccess: (response) => {
                if(refDownloadFile.current) {
                    refDownloadFile.current.href = response
                    refDownloadFile.current.click()
                }
                setShowLoader(false)
            },
            onError: (response) => {
                const e = response as AxiosError
                setError(e.response ? e.response.status : 400)
                setShowLoader(false)
            }
        }
    )

    const handleDownloadUps = (userId: number, courseId: number) => {
        setShowLoader(true)
        const data: IUserCourse = {
            "userId": userId,
            "courseId": courseId,
        }
        downloadUps.mutate(data)
    }

    const handleBlockAccess = (userId: number, courseId: number) => {
        if(courses.data) {
            setShowLoader(true)
            const data: IUserCourse = {
                "userId": userId,
                "courseId": courseId,
            }
            changeBlockAccess.mutate(data)
        }
    }

    const changeBlockAccess = useMutation(
        (data: IUserCourse) => UsersService.changeAccessForCourse(data),
        {
            onSuccess: (response) => {
                setFilteredCourses( prev => prev.map(item => item.course.id === response.course.id ? {...item, isBlocked: response.isBlocked } : item))
                setShowLoader(false)
            },
            onError: (response) => {
                const e = response as AxiosError
                setError(e.response ? e.response.status : 400)
                setShowLoader(false)
            }
        }
    )

    if (courses.isLoading) return <div className="text-center">Загрузка...</div>

    if (courses.isError) {
        const e = courses.error as AxiosError
        return <ErrorMessage numberError={e.response ? e.response.status : 400}/>
    }

    if (!courses.data) return <div className="text-center">Ошибка данныз</div>

    return (
        <>
            {showLoader && (<Loader/>)}
            <a ref={refDownloadFile} href="#" className="hidden"></a>
            <div className="flex flex-col min-h-0 h-full gap-[32px] max-lg:gap-[12px]">
                <div className="flex w-full justify-between items-center max-lg:flex-col-reverse max-lg:gap-[12px]">
                    <div className="text-[#292B2C] text-[20px] font-medium">
                        Информация <span className="max-lg:hidden">о назначенных пользователю курсах и статусе их завершения</span>
                    </div>
                    <div
                        onClick={setOnModalOpenAttachCourse}
                        className="px-[12px] py-[8px] rounded-[5px] cursor-pointer bg-[#17AB57] text-white text-[16px] font-medium whitespace-nowrap">
                        Назначить обучение
                    </div>
                </div>
                {/*<ScrollContainer>*/}
                    <div className="flex flex-col min-h-0 h-full">
                        {filteredCourses.length > 0 ? (
                            <div className="w-full">
                                <div className="w-full flex text-[#828282] text-[16px] font-medium max-lg:hidden">
                                    <div className="w-[50%] pl-[36px] cursor-pointer flex gap-[4px] items-center"
                                         onClick={handleSort}>
                                        Название
                                        <ArrowSortIcon
                                            className={!activeSort ? "rotate-180 transition-all" : "rotate-0 transition-all"}/>
                                    </div>
                                    <div className="w-[50%]">Статус</div>
                                </div>
                                {filteredCourses.map((course, index) => (
                                    <div
                                        className={`w-full text-[16px] font-medium flex items-center py-[20px] 
                                        max-lg:border-y-[2px] max-lg:border-solid max-lg:border-[#BDBDBD] max-lg:flex-col max-lg:gap-[16px] 
                                        max-lg:items-start ${course.isBlocked ? "bg-[#c1bcbc]" : ""}`}
                                        key={index}>
                                        <div className="w-[50%] flex items-center gap-[12px] max-lg:w-full pl-[10px]">
                                            <CoursesIcon className="flex-shrink-0"/>
                                            <Link className="hover:underline" target="_blank" to={`/folders/courses/${course.course.id}`}>{course.course.name} ({course.course.hours})</Link>
                                        </div>
                                        <div
                                            className="w-[25%] max-lg:flex max-lg:flex-col max-lg:gap-[8px] max-lg:w-full">
                                            <div className="hidden max-lg:block text-[#828282]">Статус</div>
                                            {course.isPassed ? (
                                                <div className="text-[#219653]">Завершен</div>
                                            ) : (
                                                <div className="text-[#F21450]">В процессе</div>
                                            )}
                                        </div>
                                        <div
                                            className="w-[25%] flex justify-end max-lg:justify-start gap-[10px] max-lg:w-full pr-[10px]">
                                            {window.location.host.indexOf("fedkurs") === -1 && (
                                                <Link
                                                    to={`/users/${user.id}/course/${course.course.id}/attach-lessons`}
                                                    className="rounded-[2px] cursor-pointer bg-[#2F80ED] w-[24px] h-[24px] flex items-center justify-center flex-shrink-0">
                                                        <EditCourseIcon className="w-[16px] h-[16px]"/>
                                                </Link>
                                            )}
                                            <div
                                                onClick={() => handleDownloadUps(user.id, course.course.id)}
                                                className="rounded-[2px] cursor-pointer bg-[#2F80ED] w-[24px] h-[24px] flex items-center justify-center flex-shrink-0">
                                                    <DownloadUpIcon className="w-[16px] h-[16px]"/>
                                            </div>
                                            <Link
                                                to={`/users/${user.id}/course/${course.course.id}`}
                                                className="px-[6px] py-[4px] rounded-[2px] cursor-pointer bg-[#17AB57] text-white text-[12px] font-medium flex items-center gap-[4px] h-[24px] flex-shrink-0">
                                                    <ProgressIcon className="w-[16px] h-[16px]"/> Прогресс
                                            </Link>
                                            <div
                                                onClick={() => handleBlockAccess(user.id, course.course.id)}
                                                className="rounded-[2px] cursor-pointer bg-[#515151] w-[24px] h-[24px] flex items-center justify-center flex-shrink-0">
                                                {course.isBlocked ? <BlockCourse className="w-[16px] h-[16px]"/> : <UnblockCourse className="w-[16px] h-[16px]"/> }
                                            </div>
                                            <div
                                                onClick={() => untieCourseHandle(course.course.id)}
                                                className="rounded-[2px] cursor-pointer bg-[#EB5757] w-[24px] h-[24px] flex items-center justify-center flex-shrink-0">
                                                    <DeleteIcon className="w-[16px] h-[16px]"/>
                                            </div>
                                        </div>
                                    </div>
                                ))}
                            </div>
                        ) : (
                            <>
                                <div className="flex items-center justify-center">
                                    Нет привязанных курсов
                                </div>
                            </>
                        )}
                    </div>
                {/*</ScrollContainer>*/}
                {isModalAttachCourse && <ModalAttachCourseUser
                    user={user}
                    onClose={setOnModalCloseAttachCourse}/>}
            </div>
        </>

    )
}

export default UserCourses