import {ReactComponent as ArrowSortIcon} from "../../../../assets/images/global/arrow-sort-icon.svg";
import React, {useEffect, useRef, useState} from "react";
import {ReactComponent as SearchIcon} from "../../../../assets/images/global/searcg-icon.svg";
import {ReactComponent as CreateCoursesIcon} from "../../../../assets/images/folders/create-courses-icon.svg";
import {useQuery} from "react-query";
import {TestsService} from "../../../../services/tests.service";
import {ITest} from "../../../../models/tests";
import TestsItem from "./tests-item/TestsItem";
import useToggle from "../../../../hooks/useToggle";
import ModalAddTest from "../../../ui/modal/modal-add-test/ModalAddTest";
import {AxiosError} from "axios";
import ErrorMessage from "../../../ui/error/ErrorMessage";
import ScrollContainer from "../../../ui/scroll-container/ScrollContainer";

const Tests = () => {

    const [blurSearch, setBlurSearch] = useState<boolean>(false)
    const [activeSort, setActiveSort] = useState<boolean>(false)
    const [filteredTests, setFilteredTests] = useState<ITest[]>([])
    const [rect, setRect] = useState<DOMRect>({} as DOMRect)
    const refListTests = useRef<HTMLDivElement>(null)
    const [isModalAddTest, setOnModalOpenAddTest, setOnModalCloseAddTest] = useToggle()
    const [modalTest, setModalTest] = useState<ITest>({} as ITest)
    const [error, setError] = useState<number|null>(null)
    const [page, setPage] = useState<number>(1)
    const [showLoadMore, setShowLoadMore] = useState<boolean>(true)
    const [searchTerm, setSearchTerm] = useState<string>("")

    const tests = useQuery(
        ["tests", page, searchTerm],
        () => TestsService.getByPage(page, searchTerm ? searchTerm : ""),
        {
            onSuccess: (response) => {
                if(page == 1) {
                    setFilteredTests(response.tests)
                } else {
                    response.tests.map(test => {
                        setFilteredTests(prev => [...prev, test])
                    })
                }
                setShowLoadMore(true)
                if(response.left <= 0) {
                    setShowLoadMore(false)
                }
                if (refListTests.current) {
                    setRect(refListTests.current.getBoundingClientRect())
                }
            },
            keepPreviousData: true,
        }
    )

    const handleSearch = (e: React.ChangeEvent<HTMLInputElement>) => {
        setPage(1)
        setSearchTerm(e.target.value)
    }

    const handleSort = () => {
        const arrSort = [
            {method: (a: ITest, b: ITest) => a.name > b.name ? 1 : -1},
            {method: (a: ITest, b: ITest) => a.name < b.name ? 1 : -1}
        ]
        const sorted = filteredTests.sort(!activeSort ? arrSort[0].method : arrSort[1].method)
        setFilteredTests([...sorted])
        setActiveSort(!activeSort)
    }

    const onDelete = (id: number) => {
        setFilteredTests(filteredTests.filter(t => t.id != id))
    }

    useEffect(() => {
        if(Object.keys(modalTest).length > 0) {
            setFilteredTests(prev => [modalTest, ...prev])
            setModalTest({} as ITest)
        }
    }, [modalTest])

    if (tests.isLoading) return <div className="text-center">Загрузка...</div>

    if (tests.isError) {
        const e = tests.error as AxiosError
        return <ErrorMessage numberError={e.response ? e.response.status : 400}/>
    }

    return (
        <div className="h-full">
            <div className="flex flex-col gap-[34px] h-full">
                <div className="w-full flex gap-[12px] max-lg:flex-col items-center text-[16px] max-lg:gap-[16px]">
                    <div className="relative w-full">
                        <SearchIcon
                            className={`absolute top-[8px] left-[10px] ` + (blurSearch ? "fill-[#292B2C]" : "")}/>
                        <input
                            onFocus={() => {
                                setBlurSearch(true)
                            }}
                            onBlur={() => {
                                setBlurSearch(false)
                            }}
                            onChange={handleSearch}
                            type="text"
                            placeholder="Поиск"
                            className="py-[8px] pl-[36px] pr-[12px] border-solid border-[1px] border-[#BDBDBD] rounded-[5px] bg-[#F2F2F2] w-full focus:border-[#17AB57] focus:outline-none"/>
                    </div>
                    <div
                        onClick={() => setOnModalOpenAddTest()}
                        className="bg-[#17AB57] text-white rounded-[5px] items-center flex gap-[6px] py-[8px] pl-[12px] pr-[16px] font-medium whitespace-nowrap cursor-pointer max-lg:w-[50%] justify-center">
                        <CreateCoursesIcon className="flex-shrink-0"/>
                        Создать тест
                    </div>
                </div>
                <div ref={refListTests}
                     className="bg-white p-[32px] rounded-[6px] flex flex-col gap-[18px] text-[16px] font-medium max-lg:px-[14px] max-lg:py-[2px] max-lg:gap-0 h-full">
                    {error && <ErrorMessage numberError={error}/>}
                    <div
                        className="px-[18px] flex justify-between text-[#828282] text-[16px] font-medium whitespace-nowrap max-lg:hidden">
                        <div className="w-[30%] cursor-pointer flex gap-[4px]" onClick={handleSort}>
                            Название
                            <ArrowSortIcon
                                className={!activeSort ? "rotate-180 transition-all" : "rotate-0 transition-all"}/>
                        </div>
                        <div className="w-[10%]">Дата создания</div>
                        <div className="w-[10%]">Дата изменения</div>
                        <div className="w-[6%] flex justify-end">Действия</div>
                    </div>
                    {/*<ScrollContainer>*/}
                        <div className="h-full">
                            {filteredTests && filteredTests.map((test, key) => (
                                <TestsItem setError={setError} onDelete={onDelete} parentParameter={rect} test={test} key={key}/>
                            ))}
                        </div>
                    {/*</ScrollContainer>*/}
                </div>
                {showLoadMore && (
                    <div
                        onClick={() => setPage(prev => prev + 1)}
                        className="flex text-center w-full px-[16px] py-[8px] border-[1px] border-solid border-[#17AB57] justify-center cursor-pointer rounded-[5px] text-[16px] font-medium text-[#292B2C] leading-[120%]">
                        Показать ещё
                    </div>
                )}
                {isModalAddTest && <ModalAddTest
                    setModalTest={setModalTest}
                    onClose={setOnModalCloseAddTest}/>}
            </div>
        </div>
    )
}

export default Tests